import React, { useEffect,useState } from "react";
import { Avatar, AvatarBadge, AvatarGroup,InputGroup,Input, Select,Flex,InputLeftElement,Image,Spinner } from '@chakra-ui/react'
import { ExplorerData } from "../../request/data";
import { useNavigate } from 'react-router-dom'; // Pages
import makeBlockie from 'ethereum-blockies-base64';
import { Helmet } from "react-helmet";
import { getAvatar,getUserName } from "../../utils/utils";

import eth_logo from "../../static/image/eth.jpg"
import "./explorer.css"
import { PhoneIcon, SearchIcon } from "@heroicons/react/outline";
import holder_png from "../../static/image/holder.png"


function Explorer(){
  const [shopList,setShopList] = useState([])
  const [searchValue,setSearchValue] = useState("")

  useEffect(()=>{
    getShopList()
  },[])

  const getShopList = ()=>{
    ExplorerData({search:searchValue}).then(res=>{
        const resData = res.data
        if (resData.code==0){
            setShopList(resData.data.shop_list)
        }
    })
  }

  const navigate = useNavigate();
  const toShop= (shopId,e1)=>{
    navigate('/shop/'+shopId);
    //   console.log(e,e1)
  }

  const searchText = (e)=>{
    getShopList()
  }
  const changeSearch = (e)=>{
      setSearchValue(e.target.value)
  }

    return (
        <div className="min-h-3/4">
              <Helmet>
                <title>CreatorDAO-Booth</title>
            </Helmet>
        <div className="max-w-3xl mx-auto py-6 px-4 lg:max-w-7xl lg:px-8">
            <div className="text-color-222 font-bold md:text-32">
            Booth
            </div>
            <div className="font-size-16 font-color-888 mt-1">
            Discover {"&"} customize all your commission needs from indie creator/ studio
            </div>
            <Flex className="mt-4">
                <Select  width='auto' id='country' placeholder=''>
                    <option >Popular</option>
                </Select>
                <InputGroup >
                    <InputLeftElement className="ml-3" pointerEvents='none'>
                        <SearchIcon size='xs' className="ml-4" color='gray.200' />
                    </InputLeftElement>
                    <Input width='auto'  onChange={changeSearch} onBlur={searchText} className="ml-5" type='tel' placeholder='Search' />
                </InputGroup>
                {/* <Input htmlSize={60} width='auto' className="ml-5" id='first-name' placeholder='Search' /> */}
            </Flex>
        {/* <h1 className=" m-auto mb-6 h-12 text-gray-700  font-sans text-xl lg:text-2xl font-mono font-extrabold ">
        Creators
            </h1> */}

            <div className=" mt-6 grid grid-cols-1 md:grid-cols-2 gap-y-8  gap-x-7 lg:grid-cols-3">
            {shopList.length>0?shopList.map((product) => (
                <a key={product.id} onClick={toShop.bind(this,product.shop_id)}  className="hover:shadow-lg group border-css cursor-pointer m-auto max-w-384">
                <div className="w-full aspect-w-1 m-h-32 aspect-h-1 bg-gray-200 overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
                    <Image
                    src={product.banner_image}
                    alt=""
                    boxSize=''
                    objectFit='cover'
                    className="m-h-32 "
                    />
                </div>
                <div className="">
                    <div className="rounded-full w-12 h-12 bg-gray-200  mt-fu ml-4 over-hidden">
                        <Image  src={product.avatar} className="w-full" fallbackSrc={holder_png} />                                    
                        </div>
                    <div className="mx-4 mt-2 ">
                        <div className="font-medium text-color-222 font-size-18 h-25  grid grid-cols-7 items-center">
                            <div className="overflow-text font-bold overflow-line-1 col-span-6">{product.shop_name }</div>
                            <div className="float-right flex h-6 items-center font-bold justify-end">{product.min_price} <img src={eth_logo} className="h-4 ml-1" /></div>
                        </div>
                        <div className="mt-2 text-xs text-color-888 font-size-14 h-43 overflow-text overflow-line-2">{product.description}</div>
                        <div className="flex items-center">
                            {
                                 product.list_queued.length>0?
                                 <AvatarGroup size='sm'  className="mt-2" color='#ffffff' spacing={-2} max={2}>
                                 {
                                     product.list_queued.map((item,key)=>(
                                        item?<Avatar key={key} name='' color='#ffffff' bg='#666666' src={getAvatar(item.avatar,item.address)} />:''
                                     ))
                                 }
                             </AvatarGroup>
                             :
                             <Avatar size='sm' name='0' bg='#666666' color='#ffffff' className="mt-3 font-size-14" />
                            }
                      
                        <div className="text-color-888 ml-4 mt-2"> in queue...</div>
                        </div>
                       
                        <div className="mt-4 mb-4 height-88 grid gap-x-1 grid-cols-4 overflow-hidden items-center">
                            {
                                product.pictures.map((item,key)=>(
                                    <Image   key={key} src={item} className="object-contain" fallbackSrc={holder_png} />                                    
                                ))
                            }
                            {/* <img src={ca2} className="w-88"/>
                            <img src={ca3} className="w-88"/>
                            <img src={ca4} className="w-88"/> */}
                        </div>
                    </div>
                </div>
                {/* <h3 className="mt-4 text-sm text-gray-700">{product.name}</h3> */}
                </a>
            ))
            :
            <Spinner
            className="lg:col-span-3 m-auto"
            thickness='4px'
            speed='1.5s'
            emptyColor='gray.200'
            color='#ffc400'
            size='lg'
            />
        }
            </div>
        </div>
        </div>
    )
}

export default Explorer;