import axios from "axios";
import request from "./request";
import { SIGN_KEY } from "../config/params";
export function signUp(data) {
     return axios({
          method: "post",
          url: "/v1/user/sign",
          data: data,
          headers: { "Content-Type": "application/json;charset=UTF-8" },
     });
}

export function auth(data) {
     return request({
          method: "post",
          url: "/v1/user/auth",
          data: data,
          headers: { "Content-Type": "application/json;charset=UTF-8" },
     });
}

export function hasShop(data) {
     return axios({
          method: "post",
          url: "/v1/user/has-shop",
          data: data,
          headers: { "Content-Type": "application/json;charset=UTF-8" },
     });
}

export function checkSign(data) {
     const token = localStorage.getItem(SIGN_KEY);
     return request({
          method: "post",
          url: "/v1/user/check-sign",
          data: data,
          headers: {
               "Content-Type": "application/json;charset=UTF-8",
               Authorization: token ? `Bearer ${token}` : null, // 携带token
          },
     });
}

export function getUserInfo(addr) {
     return axios({
          method: "get",
          url: `/v1/user/info?address=${addr}`,
          headers: { 'Content-Type': 'application/json;charset=UTF-8' }
     })
}

export function getUserWorks(addr) {
     return axios({
          method: "get",
          url: `/v1/user/works?address=${addr}`,
          headers: { 'Content-Type': 'application/json;charset=UTF-8' }
     })
}

export function uploadWork(data, token) {
     return axios({
          method: "post",
          url: "/v1/user/upload/work",
          data: data,
          headers: {
               'Content-Type': 'multipart/form-data;charset=UTF-8',
               Authorization: token ? `Bearer ${token}` : null, // 携带token
          }
     })
}

export function removeWork(data, token) {
     return axios({
          method: "post",
          url: "/v1/user/remove/work",
          data: data,
          headers: {
               'Content-Type': 'application/json;charset=UTF-8',
               Authorization: token ? `Bearer ${token}` : null, // 携带token
          }
     })
}

export function updateUserInfo (data, token) {
     return axios({
          method: "post",
          url: "/v1/user/update/info",
          data: data,
          headers: {
               'Content-Type': 'multipart/form-data;charset=UTF-8',
               Authorization: token ? `Bearer ${token}` : null, // 携带token
          }
     })
}

export function checkCode (data) {
     return axios({
          method: "post",
          url: '/v1/user/check/code',
          data: data,
          headers: { 'Content-Type': 'application/json;charset=UTF-8' }
     })
}

export function viewAdd (data) {
     return axios({
          method: "post",
          url: '/v1/user/shows/view/add',
          data: data,
          headers: { 'Content-Type': 'application/json;charset=UTF-8' }
     })
}

