import * as types from './types'

export const store = {
    profile:{
        address:"",
        provider:null,
        library:null,
        metaLibrary:null,
        biconomy:null,
        ethersProvider:null,
        user_name:"",
        avatar:"",
        shop_nums:0,
    },
    carts:{
        count:0
    }
}

const profile = (state,action)=>{
    switch (action.type){
        case types.LOGIN:
            return  {...state,address:action.address,provider:action.provider,library:action.library,metaLibrary:action.metaLibrary,biconomy:action.biconomy,ethersProvider:action.ethersProvider}
        case types.SETADDRESS:
            return {...state,address:action.address}
        case types.SetUserInfo:
            return {...state,user_name:action.user_name,avatar:action.avatar,shop_nums:action.shop_nums}
        case types.LOGOUT:
            return {...state,address:"",provider:null,library:null,avatar:"",user_name:"",shop_nums:0}
        default:
            return {...state}
    }
}

const carts = (state,action)=>{
    switch (action.type){
        case 'increment':
            return {...state,count:state.count+1}
        case 'decrement':
            return {...state,count:state.count-1}
        default:
            return {...state}
    }
}

const combineReducers = (reducers) => {
    return function(state, action) {
      return Object.keys(reducers)
                   .map(k => ({[k]: reducers[k](state[k], action)}))
                   .reduce((prev, cur) =>(Object.assign({}, prev, cur)))
    }
  }


 export const reducers = combineReducers({profile, carts})
  