import moment from 'moment';
import { Radio } from 'antd';
import utc from 'dayjs/plugin/utc';
import { Helmet } from "react-helmet";
import { AppContext } from "@/AppContext";
import { useNavigate } from 'react-router-dom';
import { getBountyList, checkWhitelistRequest } from "@/request/bounty";
import makeBlockie from 'ethereum-blockies-base64';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { truncateAddress,getAvatar,getUserName } from "@/utils/utils";
moment.extend(utc)

import "./index.less"

const BountyIndex = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext)
  const [filterState, setFilterState] = useState('all')
  const [bountyList, setBountyList] = useState([])
  const [canCreate, setCanCreate] = useState(false);

  useEffect(() => {
    if (state.profile.address) {
      checkCreate()
    }
  }, [state.profile.address])

  const checkCreate = async () => {
    const { data } = await checkWhitelistRequest({
      address: state.profile.address
    })
    setCanCreate(data.data.white_list);
  }

  const handleGetBountyList = useCallback(async () => {
    if (state.profile.address) {
      let json = { address: '', state: '', submit_address: state.profile.address }
      if (filterState === 'my_request') {
        json.address = state.profile.address
      } else if (filterState === 'all') {
        json.state = ''
      } else {
        json.state = filterState
      }
      setBountyList([])
      const { data } = await getBountyList(json)
      data.data.map(item => {
        const dateArr = moment(item.deadline).utc().format('YYYY-MM-DD HH:mm').split(' ')
        const datePart = dateArr[0].split('-');
        const timePart = dateArr[1]
        const newDatePart = [datePart[1], datePart[2], datePart[0]].join('/')
        item.deadline = newDatePart + ' ' + timePart
      })
      setBountyList(data.data)
    }
  }, [filterState, state.profile.address])

  useEffect(() => {
    handleGetBountyList()
  }, [filterState, handleGetBountyList])


  const onFilterChange = (e) => {
    setFilterState(e.target.value)
  }

  const goToBountyCreate = () => {
    navigate('/bounty/create')
  }

  const goToBountyDetail = (id) => {
    navigate(`/bounty/${id}`)
  }

  return (
    <div className="min-h-3/4 bounty-index-wrapper">
      <Helmet>
        <title>CreatorDAO-Bounty</title>
      </Helmet>
      <div className='bounty-index-container max-w-3xl mx-auto py-6 px-4 lg:max-w-7xl lg:px-8'>
        <div className='title-wrap'>
          <div className='title'>Bounty</div>
          <p className='tips'>Check available and on going Bounty here.
            To start a Bounty, an invitation is needed. Please reach out to our support team for access.</p>
        </div>
        <div className='button-group flex flex-row xs:flex-col items-center xs:items-start'>
          <Radio.Group defaultValue="all" size="large" onChange={onFilterChange}>
            <Radio.Button value="all">
              <div className='text-16 xs:text-12'>All</div>
            </Radio.Button>
            <Radio.Button value="10">
              <div className='text-16 xs:text-12'>On going</div>
            </Radio.Button>
            <Radio.Button value="30">
              <div className='text-16 xs:text-12'>Expired</div>
            </Radio.Button>
            <Radio.Button value="my_request">
              <div className='text-16 xs:text-12'>My requests</div>
            </Radio.Button>
          </Radio.Group>
          {
            canCreate && <div className='button-item basic right mt-0 xs:mt-5 xs:w-full text-16 xs:text-12' onClick={() => goToBountyCreate()}>Create a request</div>
          }
        </div>
        <div className='request-wrap'>
          {
            bountyList.map((item, index) => {
              return (
                <div className='request-item flex flex-col md:flex-row lg:flex-row' key={`item_${index}`} onClick={() => goToBountyDetail(item.id)}>
                  {/* <img className='request-item__img w-full md:w-200 lg:w-200' src={ExampleImg}></img> */}
                  <div className='right'>
                    <div className='head'>
                      <div className='title-wrap'>
                        <span className='title'>{item.title}</span>
                        {
                          item.state === 10 && <div className='tag tag-green'>On Going</div>
                        }
                        {
                          item.state === 20 && <div className='tag tag-grey'>Expired</div>
                        }
                        {
                          item.state === 30 && <div className='tag tag-grey'>End</div>
                        }

                        {item.submitted && <div className='tag tag-basic'>Submitted</div>}

                      </div>
                    </div>
                    <div className='foot text-12 md:text-16 lg:text-16'>
                      <div className='info-item request-info'>
                        Requested by
                        <img src={item.address && getAvatar(item.avatar,item.address)} className="request-info-img mx-2" />
                        <span style={{ color: '#222222' }}>{item.address && getUserName(item.user_name,item.address)}</span>
                      </div>
                      <div className='info-item time-info'>
                        Deadline: <div style={{ color: '#222', paddingLeft: '4px' }}>{item.deadline} GMT</div>
                      </div>
                      <div className='info-item winner-info'>
                        <div className='info-item__title'>Winner</div>
                        <div className='info-item__value'>{item.winner_number <= 1 ? 'Single winner contest' : `${item.winner_number} Possible winners`}</div>
                      </div>
                      <div className='info-item submit-info'>
                        <div className='info-item__title'>Submissions</div>
                        <div className='info-item__value'>{item.work_number} Received</div>
                      </div>
                      <div className='info-item offer-info'>
                        <div className='info-item__title'>Offer</div>
                        <div className='info-item__value'>{item.offer || 0} {item.currency || 'ETH'}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}
export default BountyIndex;