import moment from 'moment';
import utc from 'dayjs/plugin/utc';
import { Helmet } from "react-helmet";
import { AppContext } from "@/AppContext";
import { useToast, Button } from "@chakra-ui/react";
import { useParams, useNavigate } from 'react-router-dom';
import { getWorkDetailRequest } from "@/request/bounty";
import Example from '@/static/image/bg-new.png'
import makeBlockie from 'ethereum-blockies-base64';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { truncateAddress } from "@/utils/utils";

moment.extend(utc)

import "./index.less"

const ProposalDetail = () => {
  const toast = useToast();
  const params = useParams();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext)
  const [detail, setDetail] = useState({})

  useEffect(() => {
    getWorkDetail()
  }, [])

  const getWorkDetail = async () => {
    const { pid } = params;
    getWorkDetailRequest({
      id: pid
    }).then(res => {
      if (res.data.code === 0) {
        setDetail(res.data.data)
      } else {
        toast({
          position: 'top',
          title: 'Something went wrong.',
          description: res.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    }).catch(error => {
      toast({
        position: 'top',
        title: 'Something went wrong.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    })
  }

  return (
    <div className="min-h-3/4 proposal-index-wrapper">
      <Helmet>
        <title>CreatorDAO-Bounty Proposal</title>
      </Helmet>
      <div className="max-w-7xl mx-auto px-4 md:px-0">
        <section className='button-group mb-8'>
          <Button
            borderRadius='1px'
            _hover={{ bg: 'transparent' }}
            borderColor="#D9D9D9"
            variant='outline'
            onClick={() => navigate(-1)}
          >CANCEL</Button>
        </section>
        <section className='content'>
          <div className='title-wrap'>
            <div className='title'>{detail.name}</div>
            {
              state.profile.address && <div className='author'>
                <img src={makeBlockie(state.profile.address)} /> {truncateAddress(state.profile.address)}
              </div>
            }
            <p className='tips'>{detail.description}</p>
          </div>

          <div className='img-wrap'>
            <img src={detail.file_url}></img>
          </div>
        </section>
      </div>
    </div>
  )
}
export default ProposalDetail;