import axios from "axios";

export function bountyCreateRequest(data) {
  return axios({
    method: "post",
    url: "/v1/bounty/creating",
    data: data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },

  });
}

export function getBountyList(data) {
  const { address, state, submit_address } = data
  return axios({
    method: "get",
    url: `/v1/bounty/list?page=1&size=20&address=${address}&state=${state}&submit_address=${submit_address}`,
    headers: { "Content-Type": "application/json;charset=UTF-8" },
  });
}

export function getBountyDetailRequest(data) {
  const { id } = data
  return axios({
    method: 'get',
    url: `/v1/bounty/detail?id=${id}`,
    headers: { "Content-Type": "application/json;charset=UTF-8" },
  })
}

export function bountyWorkUploadRequest(data, token) {
  return axios({
    method: 'post',
    url: '/v1/bounty/work/uploading',
    data: data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: token ? `Bearer ${token}` : null
    }
  })
}


export function bountyCheckCreating(data) {
  return axios({
    method: 'post',
    url: '/v1/bounty/check/creating',
    data: data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    }
  })
}

export function bountySelectedRequest(data, token) {
  return axios({
    method: 'post',
    url: '/v1/bounty/work/selected',
    data: data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: token ? `Bearer ${token}` : null
    }
  })
}

export function checkMintingRequest(data) {
  return axios({
    method: 'post',
    url: '/v1/bounty/check/minting',
    data: data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    }
  })
}

export function getWorkDetailRequest(data) {
  const { id } = data;
  return axios({
    method: 'get',
    url: `/v1/bounty/work/detail?id=${id}`,
    data: data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    }
  })
}

export function checkWhitelistRequest(data) {
  return axios({
    method: 'post',
    url: '/v1/bounty/check/whitelist',
    data: data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    }
  })
}

export function checkReclaimRequest(data) {
  return axios({
    method: 'post',
    url: '/v1/bounty/check/reclaim',
    data: data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    }
  })
}
