import axios from "axios";

export function homeData() {
     return axios({
          method: "get",
          url: "/v1/data/home-data",
          headers: { 'Content-Type': 'application/json;charset=UTF-8' }
     })
}

export function ExplorerData() {
     return axios({
          method: "get",
          url: "/v1/data/explorer-data",
          headers: { 'Content-Type': 'application/json;charset=UTF-8' }
     })
}

export function ShopDetailData(shopId) {
     return axios({
          method: "get",
          url: "/v1/data/shop-detail?shop_id=" + shopId,
          headers: { 'Content-Type': 'application/json;charset=UTF-8' }
     })
}

export function MemberData() {
     return axios({
          method: "get",
          url: "/v1/data/members",
          headers: { 'Content-Type': 'application/json;charset=UTF-8' }
     })
}

export function getGallery(page, size) {
     return axios({
          method: "get",
          url: `/v1/data/gallery/list?page=${page}&size=${size}`,
          headers: { 'Content-Type': 'application/json;charset=UTF-8' }
     })
}

export function getSpaceTagsResuest(data) {
     const { search, order } = data
     return axios({
          method: "get",
          url: `/v1/data/space/tags?search=${search}&order=${order}`,
          headers: { 'Content-Type': 'application/json;charset=UTF-8' }
     })
}

export function getSpaceListResuest(data, type) {
     const { page, size, tag, category, address, state, submit_address } = data
     let url = `/v1/data/space/list?page=${page}&size=${size}&tag=${tag}&category=${category}`;
     if (type) {
          url += `&address=${address}&state=${state}&submit_address=${submit_address}`
     }
     return axios({
          method: "get",
          url: url,
          headers: { 'Content-Type': 'application/json;charset=UTF-8' }
     })
}

export function getSpaceInfoResuest(data) {
     const { tag } = data
     return axios({
          method: "get",
          url: `/v1/data/space/info?tag=${tag}`,
          headers: { 'Content-Type': 'application/json;charset=UTF-8' }
     })
}
