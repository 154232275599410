import { Helmet } from "react-helmet";
import { AppContext } from "@/AppContext";
import { useSignWallet } from "@/wallet/sign";
import { DeleteFilled } from '@ant-design/icons';
import makeBlockie from 'ethereum-blockies-base64';
import CheckCode from '@/components/CheckCode';
import ImageViewer from '@/components/ImageViewer';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import { getUserInfo, getUserWorks, removeWork } from "@/request/user";
import { Box, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, useToast, Button, Icon } from "@chakra-ui/react";

import IconEdit from '@/static/image/icon-edit.png';
import IconDiscord from '@/static/image/discord.svg';

import "./myGallery.less"

const Profile = () => {
  const toAuth = useSignWallet();
  const toast = useToast();
  const params = useParams();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext)

  const cancelRef = React.useRef()
  const [info, setInfo] = useState({})
  const [list, setList] = useState([])
  const [isMain, setIsMain] = useState(false)
  const [previewUrl, setPreviewUrl] = useState('');
  const [imageIsOpen, setImageOpen] = useState(false);
  const [needCode, setNeedCode] = useState(false);

  // useEffect(() => {
  //   if (state.profile.address === params.address) {
  //     setIsMain(true)
  //   } else {
  //     setIsMain(false)
  //   }
  // }, [params.address])

  useEffect(() => {
    setInfo({})
    setList([])
  }, [])

  useEffect(() => {
    if (params.address === state.profile.address) {
      setIsMain(true)
    } else {
      setIsMain(false)
    }
  }, [state.profile.address, params.address])

  useEffect(() => {
    initData(params.address)
  }, [params.address])

  const initData = async (addr) => {
    getUserInfo(addr).then(res => {
      if (res.data.code != 0) {
        if (res.data.code === 3999) {
          // setNeedCode(true)
          navigate('/profile');
          return
        } else {
          toast({
            position: 'top',
            title: 'Get User Info error.',
            description: res.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        }
      } else {
        if (res.data.data.state === 0) {
          navigate('/profile')
          return
        } else {
          if (!res.data.data.has_invited) {
            setNeedCode(true)
          }
          setInfo(res.data.data || {});
          handleGetWorks(addr)
        }
      }
    }).catch(error => {
      toast({
        position: 'top',
        title: 'Get User Info error.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    })
  }

  const handleGetWorks = async (addr) => {
    const userWorks = await getUserWorks(addr);
    if (userWorks.data.data?.list && userWorks.data.data?.list.length) {
      userWorks.data.data.list.map(item => {
        item.hover = false
        return item
      })
    }
    setList(userWorks.data.data?.list || [])
  }

  const handleSetHover = (isHover, index) => {
    let listCopy = [...list];
    listCopy[index].hover = isHover;
    setList(listCopy)
  }

  const handlePlayVideo = (url) => {
    setPreviewUrl(url);
    setImageOpen(true);
  }

  const toDelete = async (id) => {
    const token = await toAuth();
    if (!token) {
      return;
    }
    removeWork({ id: id }, token).then(res => {
      if (res.data.code != 0) {
        toast({
          position: 'top',
          title: 'Remove work error.',
          description: res.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        return
      }

      toast({
        position: 'top',
        title: 'Remove work success.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      handleGetWorks(params.address)
    }).catch(error => {
      toast({
        position: 'top',
        title: 'Remove work error.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    })
  }

  const handleCopyDiscord = async () => {
    await navigator.clipboard.writeText(info.discord)
    toast({
      position: 'top',
      title: 'Copy successful.',
      description: 'Discord account has been copied!',
      status: 'success',
      duration: 3000,
      isClosable: true,
    })
  }
  
  return (
    <div className="min-h-3/4 my-gallery-wrapper">
      <Helmet>
        <title>CreatorDAO-Profile</title>
      </Helmet>
      <div className="max-w-2xl box-border mx-auto py-6 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="info mb-8">
          <div className='left'>
            <img src={info.avatar || makeBlockie(params.address)}></img>
          </div>
          <div className='right'>
            <div className="name-wrap">
              {info.name && <div className='name'>{info.name}</div>}
              {
                isMain && <img className="icon-edit" src={IconEdit} onClick={() => {
                  navigate('/profile')
                }}></img>
              }
            </div>

            {info.description && <div className='intro'>“{info.description}”</div>}
            <div className="flex">
              {
                !isMain && <Button
                  fontWeight={700}
                  color='#222'
                  width='128px'
                  height='30px'
                  marginRight='16px'
                  marginTop="3px"
                  borderRadius='1px'
                  variant='solid'
                  colorScheme='customPrimary'
                  isDisabled={true}
                >Commission</Button>
              }

              {
                ((info.twitter && info.show_twitter) || info.email || info.instagram || info.discord) && (
                  <div className='icon-group'>
                    {
                      info.twitter && info.show_twitter && <a href={info.twitter.indexOf("twitter.com") >= 0 ? info.twitter : 'https://twitter.com/' + info.twitter} rel="noopener noreferrer" target="_blank">
                        <span className='iconfont icon-twitter'></span>
                      </a>
                    }
                    {
                      !!info.email && <a href={`mailto:${info.email}`}><span className='iconfont icon-email'></span></a>
                    }
                    {
                      !!info.instagram && <a href={`https://www.instagram.com/${info.instagram}`} rel="noopener noreferrer" target="_blank">
                        <span className='iconfont icon-instagram'></span>
                      </a>
                    }
                    {
                      info.discord && <img className="icon-discord" src={IconDiscord} onClick={() => handleCopyDiscord()}></img>
                    }
                  </div>
                )
              }
            </div>
          </div>
        </div>
        {
          !needCode && (
            <div className='grid grid-cols-1 gap-y-10 md:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8 content-wrap'>
              {
                list.map((item, index) => {
                  return (
                    <a key={`item_${index}`} className={`w-full md:pb-0 md:px-0 md:w-296 md:h-296 no-pointer m-auto content-item ${item.hover ? 'hover' : ''}`} onMouseEnter={() => handleSetHover(true, index)}
                      onMouseLeave={() => handleSetHover(false, index)}>
                      {
                        item.hover && isMain ? <div className='content-item__top_tips'>
                          <DeleteFilled className='float-right' onClick={toDelete.bind(this, item.id)} twoToneColor="#52c41a" />
                        </div> : ''
                      }
                      {
                        item.thumb_file ? <ImageViewer
                          className='content-item__img'
                          src={item.thumb_file}
                          realsrc={item.file}
                        ></ImageViewer> : (
                          <div className="content-item__video">
                            <video>
                              <source src={item.file} type="video/mp4" />
                              Your browser does not support video tags.
                            </video>
                            {/* <div className="iconfont icon-play"></div> */}
                            {
                              item.hover && <div className="iconfont icon-play" onClick={() => handlePlayVideo(item.file)}></div>
                            }
                          </div>
                        )
                      }
                      {
                        item.hover && item.title ? <div className='content-item__tips'>
                          {item.title}
                        </div> : ''
                      }
                    </a>
                  )
                })
              }
              {
                isMain && <Box
                  cursor='pointer'
                  className='content-item w-full md:pb-0 md:px-0 h-mfull md:w-296 md:h-296 no-pointer m-auto content-item'
                  onClick={() => {
                    navigate('/upload')
                  }}
                >
                  <div className='upload-wrap'>
                    <span className='iconfont icon-add'></span>
                    <p>Upload</p>
                  </div>
                </Box>
              }
            </div>
          )
        }

        {
          needCode && <CheckCode></CheckCode>
        }


        <AlertDialog
          motionPreset='scale'
          isCentered
          isOpen={imageIsOpen}
          leastDestructiveRef={cancelRef}
          onClose={() => setImageOpen(false)}
          size="2xl"
          id="personal-image-preview"
        >
          <AlertDialogOverlay />

          <AlertDialogContent>
            <AlertDialogHeader />
            <span className='dialog-close-btn iconfont icon-close' onClick={() => setImageOpen(false)}></span>

            <AlertDialogBody>
              <video controls width={800}>
                <source src={previewUrl} type="video/mp4" />
                Your browser does not support video tags.
              </video>
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </div>
  )
}
export default Profile;