import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react"
import { MoralisProvider } from "react-moralis";
const theme = extendTheme({
  colors: {
    customPrimary: {
      500: '#FFC400'
    },
  },
})
ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <MoralisProvider serverUrl="https://nslljcz57w69.usemoralis.com:2053/server" appId="OE5c7KOfPfFpUONOgN83czjoMSJY5WdbxJ05BgHk">
        <App />
      </MoralisProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
