import React, { Component } from 'react'

import Viewer from '@/components/viewer';
import '@/components/viewer/viewer.min.css'

export default class PackageViewer extends Component {
  constructor (props) {
    super(props)
    this.container = null
    this.viewer = null
  }

  componentDidMount () {
    this.viewerInit()
  }

  getViewer () {
    return {
      viewer: this.viewer,
      container: this.container
    }
  }

  componentDidUpdate () {
    if (!this.viewer || this.isIdentical()) return
    this.viewerInit()
  }

  componentWillUnmount () {
    if (this.viewer) this.viewer.destroy()
  }
  
  isIdentical () {
    const imgarr = this.viewer.images || []
    this.viewer.update()
    const imgarr2 = this.viewer.images || []
    if (!imgarr.length && !imgarr2.length) return true
    if (imgarr.length !== imgarr2.length) return false
    return imgarr2.every((img, index) => imgarr[index] === img && img.src === imgarr[index].src)
  }
  viewerInit () {
    if (this.viewer) this.viewer.destroy()
    const { options = {}, children, triggerview } = this.props
    const image = this.container
    this.viewer = new Viewer(image, {
      navbar: !!(Array.isArray(children) && children.length),
      ...options,
      url: (image) => {
        return image.getAttribute('realsrc')
      },
      viewed: () => {
        triggerview && triggerview()
      }
    })
  }

  shouldComponentUpdate (nextProps) {
    return false
  }

  render () {
    const { children, ...others } = this.props
    let otherObj = {...others}
    delete otherObj.triggerview;
    return (
      <div ref={(container) => { this.container = container }} {...otherObj}>
        {children}
      </div>
    )
  }
}