
import React, { useContext } from "react";
import { signUp, auth } from "../request/user";
import { AppContext } from "../AppContext";
import Web3 from 'web3';
// import { H } from 'react-router-dom'
import { SIGN_KEY } from "../config/params";
import {
  useToast
} from '@chakra-ui/react'

export const useSignWallet = () => {
  const toast = useToast()
  const { state, dispatch } = useContext(AppContext)
  let web3 = null

  const toAuth = async () => {
    let provider = state.profile.provider
    if (!provider) {
      await window.web3.currentProvider.enable();
      provider = window.web3.currentProvider
    }
    web3 = new Web3(provider)
    let address = state.profile.address
    address = address.toLowerCase()
    // const coinbase = await web3.eth.getCoinbase();
    // console.log(handleSignMessage(coinbase,222));return;
    return signUp({ address: address })
      .then(res => { return handleSignMessage(address, res.data.nonce) })
      .then(auth)
      .then(res => {
        return res.token
        // return handleLoggedIn(res.token)
      })
      .catch((err) => {
        console.log(err)
        toast({
          position: 'top',
          title: 'Signature error.',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        return
      });
  }

  const handleLoggedIn = (auth) => {
    localStorage.setItem(SIGN_KEY, auth);
    // setHasSign();
    return auth
  };

  const handleSignMessage = async (address, nonce) => {
    try {
      const signature = await web3.eth.personal.sign(
        `Please sign to let us verify that you are the owner of this address ${address}. [nonce: ${nonce}]`,
        address,
        '' // MetaMask will ignore the password argument here
      );

      return { address, signature };
    } catch (err) {
      throw new Error(
        'You need to sign the message to be able to log in.'
      );
    }
  }

  return toAuth
}
