export default (
  '<div class="viewer-container" tabindex="-1" touch-action="none">'
    + '<div class="viewer-canvas"></div>'
    + '<div class="viewer-footer">'
      + '<div class="viewer-title"></div>'
      + '<div class="viewer-toolbar"></div>'
      + '<div class="viewer-navbar">'
        + '<ul class="viewer-list" role="navigation"></ul>'
      + '</div>'
    + '</div>'
    + '<div class="viewer-tooltip" role="alert" aria-hidden="true"></div>'
    + '<div class="viewer-button" data-viewer-action="mix" role="button" style="visibility: hidden;"></div>'
    + '<div class="viewer-player"></div>'
  + '</div>'
);
