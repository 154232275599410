import { Helmet } from "react-helmet";
import { Image } from '@chakra-ui/react'
import { viewAdd } from '@/request/user';
import { getGallery } from '@/request/data';
import { useToast } from '@chakra-ui/react';
import Lightbox from 'react-image-lightbox';
import { useNavigate } from 'react-router-dom';
import { PhotoAlbum } from "react-photo-album";
import React, { useEffect, useState } from "react";

import holder_png from "@/static/image/holder.png"
import DefaultAvatar from '@/static/image/default-avatar.png';

import 'react-image-lightbox/style.css';
import './gallery.less';


const Gallery = () => {
  const size = 12;
  const toast = useToast();
  const navigate = useNavigate();
  const [page, setPage] = useState(1)
  const [index, setIndex] = useState(-1)

  const [imageList, setImageList] = useState([]);
  const [haveNext, setHaveNext] = useState(true);

  useEffect(() => {
    console.log('PhotoAlbum', PhotoAlbum);
    setImageList([])
  }, [])

  useEffect(() => {
    handleGetGallery()
  }, [page])

  const renderPhoto = ({ layout, layoutOptions, photo, imageProps: { alt, style, ...restImageProps } }) => {
    return (
      <div
        className={`gallery-item ${photo.hover ? 'hover' : ''}`}
        style={{
          borderRadius: "4px",
          boxSizing: "content-box",
          alignItems: "center",
          width: style?.width,
          height: style?.height,
          display: 'flex',
          flexDirection: 'column'
        }}
        onMouseEnter={() => handleSetHover(true, photo.index)}
        onMouseLeave={() => handleSetHover(false, photo.index)}
      >
        
        <Image alt={alt} style={{ ...style, width: "100%", padding: 0 }} {...restImageProps} fallbackSrc={holder_png} />
        {/* <img alt={alt} style={{ ...style, width: "100%", padding: 0 }} {...restImageProps} /> */}
        {
          photo.hover && <div className="gallery-item-info" onClick={() => {
            handleClickPhotoAlbum(null, null, photo.index);
            setIndex(photo.index);
          }}>
            <div className='gallery-item-info__top'>
              <span className="iconfont icon-view"></span>
              <span>{photo.views || 0}</span>
            </div>
            <div className='gallery-item-info__bottom'>
              <div className="artist-info" onClick={() => {
                navigate(`/my-gallery/${photo.address}`)
              }}>
                <img src={photo.avatar || DefaultAvatar}></img>
                <span>{photo.artist_name}</span>
              </div>
              <div className='art-title'>{photo.title}</div>
            </div>
          </div>
        }
      </div>
    );
  }

  const handleGetGallery = () => {
    let newList = [...imageList];
    getGallery(page, size).then(res => {
      if (res.data.code !== 0) {
        toast({
          position: 'top',
          title: 'Get Gallery Error.',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      } else {
        if (res.data.data.length >= size) {
          setHaveNext(true)
        } else {
          setHaveNext(false)
        }
        res.data.data.map(item => {
          item.hover = false
          item.src = item.little_picture
        })

        newList = newList.concat(res.data.data)

        newList.map((item, index) => item.index = index)
        setImageList(newList)
      }
    }).catch(error => {
      toast({
        position: 'top',
        title: 'Get Gallery Error.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    })
  }

  const handleSetHover = (isHover, index) => {
    let listCopy = [...imageList];
    listCopy[index].hover = isHover;
    setImageList(listCopy)
  }

  const handleView = (index) => {
    let newList = [...imageList]
    viewAdd({
      id: newList[index].id
    }).then(res => {
      if (res.data.code === 0) {
        newList[index].views = newList[index].views + 1
        setImageList(newList)
      }
    }).catch(error => {
      console.log(error);
    })
  }

  const handleClickPhotoAlbum = (event, photo, index) => {
    handleView(index)
    setIndex(index)
  }

  return (
    <div className="gallery-page bg-white min-h-3/4">
      <Helmet>
        <title>CreatorDAO-Gallery</title>
      </Helmet>
      <div className="max-w-2xl mx-auto pt-6 pb-16 px-4 sm:py-18 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="title-wrap">
          <div className="title">Gallery</div>
          <div className="tips">By our web3 creators.</div>
        </div>
        <PhotoAlbum
          layout="rows"
          photos={imageList}
          renderPhoto={renderPhoto}
          onClick={(event, photo, index) => handleClickPhotoAlbum(event, photo, index)}
        ></PhotoAlbum>
        {
          index >= 0 && <Lightbox
            mainSrc={imageList[index].file}
            onCloseRequest={() => setIndex(-1)}
          />
        }
        {
          haveNext && <div className="load-more-btn" onClick={() => {
            const newPage = page + 1
            setPage(newPage)
          }}>LOAD MORE</div>
        }
      </div>
    </div>
  )
}

export default Gallery;