import React from "react";
import { Helmet } from "react-helmet";

function Terms(){
    return (
        <div className="min-h-1/2 my-10 w-full md:w-4/5 m-auto bg-white px-8">
              <Helmet>
                <title>CreatorDAO-Terms</title>
            </Helmet>
            <h1 className="text-size-32 m-auto h-12 text-gray-700  md:mt-6 font-sans text-xl lg:text-2xl font-mono font-extrabold ">
            terms
            </h1>
            <div className="w-full mt-6 m-auto">
            <p className="text-left font-bold ">
            Overview
            </p>
            <br />
            <p className="text-left  text-gray-700 ">
            This site is operated by CreatorDAO. Throughout the site, the terms “we”, “us” and “our” refer to creatordao.cc. creatordao.cc offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
            </p>
             <br />
            <p className="text-left  text-gray-700 ">
            By using this site and/or commissioning with CreatorDAO, you confirm that you accept these terms of use and you agree to comply with them.
                </p>
                <br />
                <p className="text-left  text-gray-700 ">
                Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.
               </p>
               <br />
               <p className="text-left  text-gray-700 ">
               Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.
                   </p>
               <br />
               <p className="text-left  font-bold">
               Commission terms
                </p>
                <br />
                <p className="text-left  text-gray-700 ">
                The commission work curated by CreatorDAO are digital collectibles (NFTs) transferred via OpenSea. This website is only an interface allowing users to reserve their place in the queue to request a commission and pay a deposit. By submitting a commission request, the user gives the recreation rights of the NFT to the person who accepts the commission request. Users are entirely responsible for the safety and management of their own private ethereum wallets and validating all transactions and contracts generated by this website before approval. Furthermore, as the CreatorDAO smart contract runs on the ethereum network, there is no ability to undo, reverse or restore any transactions. The commission items curated by CreatorDAO are artistic interpretations from the artists, all commission work provided are accepted by each user as final and there will be no obligation whatsoever for CreatorDAO to make any further changes after completion.
                </p>
                <br />
                <p className="text-left font-bold ">
                General Conditions
                </p>
                <br />
                <p className="text-left  text-gray-700 ">
                This website and its connected services are provided “as is” and “as available” without any representation or warranty (whether express or implied) of any kind. By using this website you are accepting sole responsibility for any and all transactions involving CreatorDAO digital collectibles. CreatorDAO reserves absolute discretion to reject any requests made for any reason.
                </p>
                <br />
                <p className="text-left  font-bold ">
                Intellectual Property
                </p>
                <br />
                <p className="text-left  text-gray-700 ">
                We respect the property rights rule of the blockchain. Unless specified by the individual commission shop on this site, all intellectual property rights of all the digital collectibles (NFTs) published on this site belong to their owners at the time according to the records on the blockchain. This website is only an interface interacting with the blockchain network in order to provide services for users.
                </p>
                <br />
                <p className="text-left  text-gray-700 ">
                CreatorDAO is the owner or the licensee of all intellectual property rights on this site and in the material published on it that is off the blockchain. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.
                </p>
                <br />
                <p className="text-left  font-bold ">
                Accuracy Completeness and Timeliness of Information
                </p>
                <br />
                <p className="text-left text-gray-700 ">
                We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.
                </p>
                <br />
                <p className="text-left  text-gray-700 ">
                This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.
                </p>
                <br />
                <p className="text-left font-bold  ">
                Modifications to the Service and Price
                </p>
                <br />
                <p className="text-left  text-gray-700 ">
                Prices for our products are subject to change without notice.
                </p>
                <br />
                <p className="text-left  text-gray-700 ">
                We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.
                </p>
                <br />
                <p className="text-left text-gray-700">
                We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.
                </p>
                <br />
                <p className="text-left font-bold">
                Optional Tools
                </p>
                <br />
                <p className="text-left text-gray-700">
                We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.
                </p>
                <br />
                <p className="text-left text-gray-700">
                You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.
                </p>
                <br />
                <p className="text-left text-gray-700">
                Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).
                </p>
                <br />
                <p className="text-left text-gray-700">
                We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.
                </p>
                <br />
                <p className="text-left font-bold">
                Third-Party Links
                </p>
                <br />
                <p className="text-left text-gray-700">
                Certain content, products and services available via our Service may include materials from third-parties.
                </p>
                <br />
                <p className="text-left text-gray-700">
                Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.
                </p>
                <br />
                <p className="text-left text-gray-700">
                We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party{"'"}s policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.
                </p>
                <br />
                <p className="text-left font-bold">
                Errors, Inaccuracies and Omissions
                </p>
                <br />
                <p className="text-left text-gray-700">
                Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).
                </p>
                <br />
                <p className="text-left text-gray-700">
                We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.
                </p>
                <br />
                <p className="text-left font-bold">
                Prohibited Uses
                </p>
                <br />
                <p className="text-left text-gray-700">
                n addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.
                </p>
                <br />
                <p className="text-left font-bold">
                Disclaimer of Warranties; Limitation of Liability
                </p>
                <br />
                <p className="text-left text-gray-700">
                We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.
                </p>
                <br />
                <p className="text-left text-gray-700">
                We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.
                </p>
                <br />
                <p className="text-left text-gray-700">
                You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.
                </p>
                <br />
                <p className="text-left text-gray-700">
                You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided {"'"}as is{"'"} and {"'"}as available{"'"} for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.
                </p>
                <br />
                <p className="text-left text-gray-700">
                In no case shall creatordao.cc, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.
                </p>
                <br />
                <p className="text-left font-bold">
                Indemnification
                </p>
                <br />
                <p className="text-left text-gray-700">
                You agree to indemnify, defend and hold harmless creatordao.cc and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.
                </p>
                <br />
                <p className="text-left text-gray-700">
                We do not guarantee that this site will be secure or free from bugs. You are responsible for configuring your information technology, computer programmes and platform to access our site. You should use your own virus protection software. You must not misuse our site by knowingly introducing viruses, bugs, worms, logic bombs or other material that is malicious or technologically harmful.
                </p>
                <br />
                <p className="text-left font-bold">
                Severability
                </p>
                <br />
                <p className="text-left text-gray-700">
                In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.
                </p>
                <br />
                <p className="text-left font-bold">
                Termination
                </p>
                <br />
                <p className="text-left text-gray-700">
                The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.
                </p>
                <br />
                <p className="text-left text-gray-700">
                These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.
                </p>
                <br />
                <p className="text-left text-gray-700">
                If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).
                </p>
                <br />
                <p className="text-left font-bold">
                Entire Agreement
                </p>
                <br />
                <p className="text-left text-gray-700">
                The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.
                </p>
                <br />
                <p className="text-left text-gray-700">
                These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).
                </p>
                <br />
                <p className="text-left text-gray-700">
                Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.
                </p>
                <br />
                <p className="text-left font-bold">
                Governing Law
                </p>
                <br />
                <p className="text-left text-gray-700">
                These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws in China.
                </p>
                <br />
                <p className="text-left font-bold">
                Changes to Terms of Service
                </p>
                <br />
                <p className="text-left text-gray-700">
                You can review the most current version of the Terms of Service at any time at this page.
                </p>
                <br />
                <p className="text-left text-gray-700">
                We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.
                </p>
                <br />
                <p className="text-left font-bold">
                Contact Information
                </p>
                <br />
                <p className="text-left text-gray-700">
                Questions about the Terms of Service should be sent to us at creatordao@gmail.com
                </p>
                <br />
               
            </div>
            
        </div>
    )
}

export default Terms;