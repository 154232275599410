import React, { useContext, useEffect, useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton
} from '@chakra-ui/react'
import { AppContext } from "../../AppContext";
import { useWeb3React } from "@web3-react/core"
import OrderList from "../../components/list/orderList";
import { orderList, accountOrderList } from "../../request/order";
import { homeData } from "../../request/data";
import { Helmet } from "react-helmet";

import CarouselItems from "../../components/carousel/carouselItems"

import Apply from "../../components/modals/apply";
import bg from "../../static/image/bg-new.png"


import './home.css';

const ApplyUrl = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(`I would like to apply as creator. @creatordaocc
Looking for invite code! 
creatordao.cc

#CD #CreatorDAO`) + '&url=' + encodeURIComponent(`
creatordao.cc
`)

function Home() {
    // const {account} = useWeb3React()
    const { state, dispatch } = useContext(AppContext)
    const [shopNumbers, setShopNumbers] = useState(0)
    const [creatorNumbers, setCreatorNumbers] = useState(0)
    const [orderNumbers, setOrderNumbers] = useState(0)
    // const [selectedList,setSelectedList] = useState([])

    // const [queuedList,setQueuedList] = useState([])
    // const [accountList,setAccountList] = useState([])

    const [tweetModalOpen, setTweetModalOpen] = useState(false)


    useEffect(() => {
        // orderList().then(res=>{
        //     let listProduction = []
        //     let listQueue = []
        //     res.data.data.filter((value)=>{
        //         if(value.state === 10  && listQueue.length<5){
        //             listQueue.push(value)
        //         }else if (value.state === 20){
        //             listProduction.push(value)
        //         }
        //     })
        //     setQueuedList(listQueue)
        //     setProductionList(listProduction)
        // })

        homeData().then(res => {
            const resData = res.data
            if (resData.code == 0) {
                setShopNumbers(resData.data.shop_numbers)
                setCreatorNumbers(resData.data.creator_numbers)
                setOrderNumbers(resData.data.order_numbers)
                // setSelectedList(resData.data.selected_list)
            }
        })

    }, [state.profile.address])

    return (
        <div>
            <Helmet>
                <title>CreatorDAO-Home</title>
            </Helmet>
            <div id="plans" className="bg-img mb-6">
                <div className="self-auto pr-5 ml-24 lg:ml-120">
                    <div className="tracking-wide font-extrabold m-auto text-color-fc md:text-48 text-32 pt-180 lg:pt-323">
                        Marketplace to Commission
                    </div>
                    <div className="font-extrabold m-auto text-left text-color-fc text-32  md:text-48">
                        Special Edition NFTs
                    </div>
                    <div className="mt-12 lg:mt-4 font-size-16 text-color-ff font-semibold  lg:w-650">
                        Special Curated Collection from the CreatorDAO artists and partners. Shaping the future of the creatorverse with every piece, every drop, every NFT.
                    </div>
                    <div className="m-auto flex flex-row">
                        <a href="/booth" className="block w-2/4 max-w-2xl lg:w-317 mr-5">
                            <button className="btn-middle justify-center flex bg-color-fff py-3 max-w-2xl lg:py-2 font-bold  text-16 lg:text-24 px-4 h-12 lg:h-16  w-full lg:w-317 text-color-222 my-20 lg:my-8">EXPLORE  &nbsp;
                                <span className="iconfont icon-jt"></span>
                            </button>
                        </a>
                        <button className="btn-middle justify-center flex bg-color-fff py-3 max-w-2xl lg:py-2 font-bold  text-16 lg:text-24 px-4 h-12 lg:h-16  w-full lg:w-317 text-color-222 my-20 lg:my-8" onClick={() => { setTweetModalOpen(true) }}>APPLY
                        </button>
                    </div>
                </div>
            </div>
            <div className="my-6 w-full  grid grid-cols-1 lg:grid-cols-12">
                <div className="apply-css m-auto px-6 lg:ml-120 lg:col-span-8">
                    <h1 className="tracking-wide font-extrabold m-auto text-center md:text-left text-32  md:text-56   mt-6   ">
                        Apply as Creator
                    </h1>
                    <div className=" mt-8 lg:mt-4 mb-2 font-size-16 text-desc-color  m-auto md:mr-20">
                        Be part of the web3 creator first movement, anything can be created from imaginations: illustrations, animation, 3D model, music, lore, or new innovative ideas!
                    </div>
                    <div className=" mt-1 mb-2 font-size-16 text-desc-color  m-auto md:mr-20">
                        We would love to have you join this web3 journey together, empowering creators one creation at a time.
                    </div>
                    {/* <div className=" mt-1  font-size-16 text-desc-color  m-auto md:mr-20">
                        After selecting <span className="font-bold">&quot;APPLY&quot;</span>, Please fill out the form and required information, all submissions will be manually reviewed and we will get back to you ASAP. (Within Three Days)
                        As we continue to build, more methods will be available such as invitation methods by existing creators.
                    </div> */}
                    {/* <div className=" font-size-14 text-desc-color  m-auto md:mr-20">
                            No invite code？Apply here and we will contact you within 2 days. 
                        </div> */}
                    <div className="flex justify-start mt-8">
                        <div>
                            <div className="font-bold text-16 lg:text-32 text-color-222 tracking-tighter">72+</div>
                            <div className="font-bold text-16 lg:text-20 nav-text-color">CREATORS</div>
                        </div>
                        <div className="ml-10">
                            <div className="font-bold text-16 lg:text-32 text-color-222 tracking-tighter">{orderNumbers}+</div>
                            <div className="font-bold text-16 lg:text-20 nav-text-color">PIECES</div>
                        </div>
                    </div>
                    {/* <div className="mt-8">
                        <a href="https://forms.gle/KiFdSmLvTmZ6XZvE8" rel="noopener noreferrer" target="_blank" className="block w-full lg:w-48">
                            <button className="justify-center m-auto md:ml-0 btn-middle flex  bg-color-black  font-bold text-16 lg:text-20   px-4 h-12 lg:h-16 w-full lg:w-48 text-white ">APPLY NOW &nbsp;
                                <span className="iconfont icon-jt_r"></span>
                            </button>
                        </a>
                    </div> */}

                </div>
                <div className=" lg:col-span-4  pt-20">
                    <img src={bg} className=" w-full m-auto md:min-w-550 max-w-600 lg:float-right"></img>
                </div>
            </div>

            <Modal
                size="md"
                isOpen={tweetModalOpen}
                closeOnOverlayClick={true}
                onClose={() => { setTweetModalOpen(false) }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Invitation</ModalHeader>
                    <ModalCloseButton className="remove-focus" />
                    <ModalBody className="text-16">
                        <div className="pb-2">
                            In the current beta, creators are by invitation only.
                            The creator can click on the &apos;Apply&apos; button to express your desire to join (using Twitter).
                        </div>
                        <div>
                            The collector can click on their wallet at the top right corner of the screen to setup their profile.
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <a target="_blank" rel="noopener noreferrer" type="external" href={ApplyUrl} className="inline-block w-full">
                            <button className="twitter-share-button flex justify-center btn-middle bg-color-base max-w-2xl font-bold text-16 lg:text-20 px-4 h-12 lg:h-14 w-full text-color-222">
                                {/* <span className="iconfont icon-twitter"></span> */}
                                Apply
                            </button>
                        </a>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default Home;