import React, { useReducer, useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'; // Pages
import './App.css';
import '@/styles/theme.less';
import Header from './components/header/header';
import Home from './pages/home/home';
import About from './pages/about/about';
import Footer from './components/footer/footer';
import Gallery from './pages/gallery/gallery';
import Explorer from './pages/explorer/explorer';
import Terms from './pages/terms/terms';

import { AppContext } from './AppContext'
import { reducers, store } from './reducer/reducer';
import Shop from './pages/shop/shop';
import Members from './pages/members/members';
import Queue from './pages/queue/queue';
import MyWork from './pages/myWork/myWork';
import MyGallery from './pages/myGallery/myGallery';
import UploadWorkPage from './pages/uploadWork';
import Homepage from './pages/Homepage';
import Profile from './pages/profile/profile';
import BountyIndex from '@/pages/bounty';
import BountyCreate from '@/pages/bounty/create';
import BountyDetail from '@/pages/bounty/detail';
import ProposalDetail from '@/pages/bounty/proposal'
import SpaceIndex from '@/pages/space';
import SpaceDetail from '@/pages/space/detail';

function App() {
  // const [wallet, setWallet] = useLocalStorage('activeConnector', '');
  // const {account,activate,active,chainId,library} = useWeb3React()
  const [state, dispatch] = useReducer(reducers, store);
  //TODO 临时方案 接入多个钱包来规避退出后自动登录
  // useEffect(()=>{
  //   if(account){
  //       dispatch({type:'login',value:account})
  //     }

  //     // test()
  // },[account])

  // setTo({address:"erererer"})
  return (
    <AppContext.Provider value={{ state, dispatch }}>
        <BrowserRouter>
        <div className="App min-h-screen h-screen">
          <Header />
          <Routes>
            <Route path='/' element={<Homepage />} />
            <Route path='/home' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/booth' element={<Explorer />} />
            <Route path='/shop/:shopId' element={<Shop />} />
            <Route path='/my-work' element={<MyWork />} />
            <Route path='/my-commission' element={<Queue />} />
            <Route path='/creators' element={<Members />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/my-gallery/:address' element={<MyGallery />}></Route>
            <Route path='/upload' element={<UploadWorkPage />}></Route>
            <Route path='/upload/:id' element={<UploadWorkPage />}></Route>
            <Route path='/profile' element={<Profile />}></Route>
            <Route path='/bounty' element={<BountyIndex />}></Route>
            <Route path='/bounty/create' element={<BountyCreate />}></Route>
            <Route path='/bounty/:id' element={<BountyDetail />}></Route>
            <Route path='/bounty/:id/proposals/:pid' element={<ProposalDetail />}></Route>
            <Route path='/space' element={<SpaceIndex />}></Route>
            <Route path='/space/:id' element={<SpaceDetail />}></Route>
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
    </AppContext.Provider>

  );
}

export default App;
