import { Cooperation } from './Components/Cooperation'
import { Flow } from './Components/Flow'
import { Hero } from './Components/Hero'
import { Highlight } from './Components/Highlight'
import { RecentRequest } from './Components/RecentRequest'

export const Homepage = () => {
  return (
    <div className="homepage min-h-full">
      <Hero />
      <RecentRequest />
      <Cooperation />
      <Flow />
      <Highlight />
    </div>
  )
}

export default Homepage
