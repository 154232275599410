import { Content } from 'src/components/Layout/content'

const cooperations = [
  ['01A1', '/images/co-01A1.jpeg'],
  ['98KDAO', '/images/co-98KDAO.jpeg'],
  ['ChainCatcher', '/images/co-ChainCatcher.png'],
  ['CheersUp', '/images/co-cheersup.png'],
  ['Cryptonatty', '/images/co-cryptonatty.png'],
  ['DODO', '/images/co-dodo.png'],
  ['Double', '/images/co-double.png'],
  ['FindTruman', '/images/co-FindTruman.jpeg'],
  ['HelloBTC', '/images/co-HelloBTC.jpeg'],
  ['Loopring', '/images/co-loopring.jpeg'],
  ['Mask', '/images/co-mask.png'],
  ['Meson', '/images/co-meson-token-icon.png'],
  ['Metaverse Labs', '/images/co-Metaverse_Labs.jpeg'],
  ['PANews', '/images/co-PANews.jpeg'],
  ['ResearchDAO', '/images/co-ResearchDAO.jpeg'],
  ['RSS3', '/images/co-RSS3.png'],
  ['Winkrypto', '/images/co-Winkrypto.jpeg'],
  ['X2Y2', '/images/co-x2y2.png'],
]

export const Cooperation = () => {
  return (
    <div className="bg-dark-2 py-7 px-5 md:px-0">
      <Content className="flex align-center flex-col">
        <h2 className="text-xl md:text-3xl text-white text-center inline-block mx-auto my-6 line">
          Trusted by
        </h2>
        <ul className="flex flex-row gap-12 justify-center py-7 flex-wrap">
          {cooperations.map(([name, img], index) => {
            return (
              <li key={index} className="inline-flex flex-col">
                <img
                  src={img}
                  className="h-24 md:h-36 w-24 md:w-36 inline-block rounded-full"
                  alt=""
                />
                <span className="text-white text-center text-xl">{name}</span>
              </li>
            )
          })}
        </ul>
      </Content>
    </div>
  )
}
