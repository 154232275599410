import { ethers } from "ethers";
import React, { useEffect, useState, useContext, useCallback } from "react";
import {
  useToast
} from '@chakra-ui/react'
import detectEthereumProvider from '@metamask/detect-provider';
import {
  getDomainSeperator,
  getDataToSignForEIP712,
  buildForwardTxRequest,
  getBiconomyForwarderConfig
} from '../biconomyForwarderHelper';
import { AppContext } from "../../AppContext";
import { init } from "hellojs";

const abi = JSON.parse(process.env.REACT_APP_ABI)
const contract_address = process.env.REACT_APP_ADDRESS
const api_url = process.env.REACT_APP_META_API
const api_key = process.env.REACT_APP_META_API_KEY
const api_network_id = process.env.REACT_APP_NETWORK_ID
const process_commission_api_id =  process.env.REACT_APP_META_PROCESS_COMMISSION_ID
const settle_commission_api_id = process.env.REACT_APP_META_SETTLE_COMMISSION_ID
const add_shop_api_id = process.env.REACT_APP_META_ADD_SHOP_ID

let contract = null
let walletProvider = null
let userAddress = null
let ethersProvider = null
let biconomy = null

const useMexaSign = () => {
  const toast = useToast()
  const [metaTxEnabled] = useState(true);
  const [transactionHash, setTransactionHash] = useState('');
  const { state } = useContext(AppContext)

  useEffect(() => {
    if (state.profile.address && state.profile.biconomy) {
      biconomy = state.profile.biconomy
      userAddress = state.profile.address
      ethersProvider = state.profile.ethersProvider
      init()
    }
  }, [state.profile.address, state.profile.biconomy])

  const init = async () => {
    contract = new ethers.Contract(
      contract_address,
      abi,
      biconomy.getSignerByAddress(userAddress)
    );
    const detectEthProvider = await detectEthereumProvider();
    walletProvider = new ethers.providers.Web3Provider(detectEthProvider);
  }

  const addShop = async (minBid, tax) => {
    let { data } = await contract.populateTransaction.addShop(minBid, tax, userAddress);
    console.log('data', data);
    return toExcuteOperator(data,add_shop_api_id)
  }

  const processCommission = async (commissionId) => {
    let { data } = await contract.populateTransaction.processCommissions([commissionId]);
    console.log('data', data);
    return toExcuteOperator(data,process_commission_api_id)
  }

  const settleCommission = async (commissionId) => {
    let { data } = await contract.populateTransaction.settleCommissions([commissionId]);
    console.log('data', data);
    return toExcuteOperator(data,settle_commission_api_id)
  }

  const toAddShop = useCallback((minBid, tax) => {
    return addShop(minBid, tax)
  })

  // if (web3Modal.cachedProvider) {
  // connectWallet();
  //   }
  const toProcessCommission = useCallback((commissionId) => {
    return processCommission(commissionId)
  })

  const toSettleCommission = useCallback((commissionId) => {
    return settleCommission(commissionId)
  })

  const toExcuteOperator = async (data,app_id) => {
    if (contract) {
      setTransactionHash('');
      if (metaTxEnabled) {
        try{
          let gasLimit = await ethersProvider.estimateGas({
            to: contract_address,
            from: userAddress,
            data: data,
          });
  
          let forwarder = await getBiconomyForwarderConfig(api_network_id);
          let forwarderContract = new ethers.Contract(
            forwarder.address,
            forwarder.abi,
            biconomy.getSignerByAddress(userAddress),
          );
  
          const batchNonce = await forwarderContract.getNonce(userAddress, 0);
          //const batchId = await forwarderContract.getBatch(userAddress);
  
          const gasLimitNum = Number(gasLimit.toNumber().toString());
          const batchId = 0;
          const req = await buildForwardTxRequest({
            account: userAddress,
            to: contract_address,
            gasLimitNum,
            batchId,
            batchNonce,
            data,
          });
  
          const domainSeparator = await getDomainSeperator(api_network_id);
          const dataToSign = await getDataToSignForEIP712(req, api_network_id);
          const sig = await walletProvider
          .send('eth_signTypedData_v3', [userAddress, dataToSign])
          .then(function (sig) {
            return sig
          })
          .catch(function (error) {
            console.log(error);
            toast({
              position: 'top',
              title: 'Transaction confirmed error',
              description: error.message,
              status: 'error',
              duration: 3000,
              isClosable: true,
            })

            return "error"
          });

          if(sig == "error")return sig;
          console.log(sig,"ssssssignnnnnn")

          const transRes =  await sendTransaction({
            request: req,
            domainSeparator,
            sig,
            app_id
          });

          return transRes;
        }catch(error){
          console.log(error);
          toast({
            position: 'top',
            title: 'Transaction confirmed error',
            // description: error.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          })

          return "error"
        }
      } else {
        let tx = await contract.setQuote(newQuote);
        console.log('Transaction hash : ', tx.hash);
        // showInfoMessage(`Transaction sent by relayer with hash ${tx.hash}`);
        // let confirmation = await tx.wait();
        // console.log(confirmation);
        setTransactionHash(tx.hash);
        return tx.hash;
        // message.success('Transaction confirmed on chain');
      }
    } else {
      toast({
        position: 'top',
        title: 'Please enter the data',
        // description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })

      return "error"
      //   message.error('Please enter the data');
    }

    return ""
  };

  const sendTransaction = async ({ request, domainSeparator,sig,app_id }) => {
    if (ethersProvider && contract) {
      let params;
      if (domainSeparator) {
        params = [request, domainSeparator, sig];
      } else {
        params = [request, sig];
      }
      try {
       const txHash = await fetch(`${api_url}/api/v2/meta-tx/native`, {
          method: "POST",
          headers: {
            "x-api-key": api_key,
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify({
            to: contract_address,
            apiId: app_id,
            // apiId: "4ee469f2-c6ba-461d-b337-54118c1301d6",
            //apiId: "ca45b2dc-0279-4952-87cd-bbc584427538",
            params: params,
            from: userAddress,
            signatureType: 'EIP712_SIGN'
          }),
        })
          .then((response) => response.json())
          .then(function (result) {
            // showInfoMessage(`Transaction sent by relayer with hash ${result.txHash}`);
            return result.txHash;
            // todo - fetch mined transaction receipt, show tx confirmed and update quotes
          })
          .catch(function (error) {
            console.log(error);
            toast({
              position: 'top',
              title: 'Transaction error',
              description: error.message,
              status: 'error',
              duration: 3000,
              isClosable: true,
            })
      
            return "error"
          });

          return txHash;

    
         
      } catch (error) {
        console.log(error);
      }
    }
  };

  return {toAddShop, toProcessCommission, toSettleCommission}
}
export default useMexaSign;


