import eth_logo from "@/static/image/eth.jpg";
import { useNavigate } from 'react-router-dom';
import holder_png from "@/static/image/holder.png"
import makeBlockie from 'ethereum-blockies-base64';
import { Avatar, AvatarGroup, Image, Spinner } from '@chakra-ui/react'

const ShopList = ({ list }) => {
  const navigate = useNavigate();

  console.log('list', list);

  const toShop = shopId => {
    navigate('/shop/' + shopId);
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-y-8 gap-x-7 lg:grid-cols-3">
      {
        list.length > 0 && list.map((product) => (
          <a key={product.id} onClick={() => toShop(product.shop_id)} className="hover:shadow-lg group border-css cursor-pointer custom-modal">
            <div className="w-full m-h-32 bg-gray-200 overflow-hidden" style={{ backgroundImage: `url(${product.banner_image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
            <div className="mt-fu">
              <div className="w-12 h-12 bg-gray-200 ml-4 rounded-full overflow-hidden">
                <Image src={product.avatar} className="w-full" fallbackSrc={holder_png} />
              </div>
              <div className="mx-4 mt-2">
                <div className="font-medium font-size-18 h-25 grid grid-cols-7 items-center" style={{textAlign: 'left'}}>
                  <div className="overflow-text font-bold overflow-line-1 col-span-6">{product.shop_name}</div>
                  <div className="float-right flex h-6 items-center font-bold justify-end">{product.min_price} <img src={eth_logo} className="h-4 ml-1" /></div>
                </div>
                <div className="mt-2 text-xs text-color-888 font-size-14 h-43 overflow-text overflow-line-2" style={{textAlign: 'left'}}>{product.description}</div>
                <div className="flex items-center">
                  {
                    product.list_order.length > 0 ?
                      <AvatarGroup size='sm' className="mt-2" color='#ffffff' spacing={-2} max={2}>
                        {
                          product.list_order.map((item, key) => (
                            item ? <Avatar key={key} name='' color='#ffffff' bg='#666666' src={makeBlockie(item)} /> : ''
                          ))
                        }
                      </AvatarGroup>
                      :
                      <Avatar size='sm' name='0' bg='#666666' color='#ffffff' className="mt-3 font-size-14" />
                  }
                  <div className="text-color-888 ml-4 mt-2"> in queue...</div>
                </div>

                <div className="mt-4 mb-4 height-88 grid gap-x-1 grid-cols-4 overflow-hidden items-center">
                  {
                    product.pictures.map((item, key) => (
                      <Image key={key} src={item} className="object-contain" fallbackSrc={holder_png} />
                    ))
                  }
                </div>
              </div>
            </div>
          </a>
        ))
      }
    </div>
  )
}
export default ShopList;