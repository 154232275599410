
import {
  Select,
  InputGroup,
  InputLeftElement,
  Input
} from '@chakra-ui/react'
import { Helmet } from "react-helmet";
import { AppContext } from "@/AppContext";
import { useNavigate } from 'react-router-dom';
import { getSpaceTagsResuest } from '@/request/data';
import { SearchIcon } from "@heroicons/react/outline";
import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import CheckHover from '@/static/image/space-check-hover.png';
import Check from '@/static/image/space-check.png';

import "./index.less"

const SpaceIndex = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext)
  const [spaceList, setSpaceList] = useState([])
  const [search, setSearch] = useState('')

  const getSpaceList = async () => {
    const { data } = await getSpaceTagsResuest({
      search: search,
      order: 'asc'
    })
    if (data && data.code === 0) {
      setSpaceList(data.data || [])
    }
  }

  const handleSetHover = (isHover, index) => {
    let listCopy = [...spaceList];
    listCopy[index].hover = isHover;
    setSpaceList(listCopy)
  }

  function useDebounce(fn, delay, dep = []) {
    const { current } = useRef({ fn, timer: null });
    useEffect(function () {
      current.fn = fn;
    }, [fn]);

    return useCallback(function f(...args) {
      if (current.timer) {
        clearTimeout(current.timer);
      }
      current.timer = setTimeout(() => {
        current.fn.call(this, ...args);
      }, delay);
    }, dep)
  }

  const onChangeSearchParam = useDebounce((e) => {
    setSearch(e.target.value)
  }, 500)

  useEffect(() => {
    getSpaceList()
  }, [search])

  return (
    <div className="min-h-3/4 space-index-wrapper theme-light">
      <Helmet>
        <title>CreatorDAO-Space</title>
      </Helmet>
      <div className='space-index-container max-w-3xl mx-auto py-6 px-4 lg:max-w-7xl lg:px-8'>
        <div className='title-wrap'>
          <div className='title page-title'>Space</div>
          <p className='tips'>Discover & customize all your commission needs from indie creator/ studio.</p>
        </div>
        <div className='search-group flex flex-row items-center'>
          <div className='select-wrapper custom-form'>
            <Select>
              <option value='1'>Popular</option>
            </Select>
          </div>
          <InputGroup>
            <InputLeftElement className="ml-3" pointerEvents='none'>
              <SearchIcon size='xs' className="ml-4 form-icon" />
            </InputLeftElement>
            <Input onChange={(e) => onChangeSearchParam(e)} width='auto' className="ml-5 custom-form" type='text' placeholder='Search...' />
          </InputGroup>
        </div>
        <div className='space-list-wrap'>
          <div className="space-list-content gap-7 grid-cols-4 xs:grid-cols-1">
            {
              spaceList.length > 0 && spaceList.map((item, index) => {
                return (
                  <a
                    key={`item_${index}`}
                    className={`w-full md:pb-0 md:px-0 no-pointer m-auto content-item custom-modal ${item.hover ? 'hover' : ''}`}
                    onClick={() => {
                      navigate(`/space/${item.symbol}`)
                    }}
                    onMouseEnter={() => handleSetHover(true, index)}
                    onMouseLeave={() => handleSetHover(false, index)}
                  >
                    <div className='space-item'>
                      <img className='space-item__img' alt='' src={item.logo}></img>
                      <div className='space-item-info'>
                        <span className='space-item__name'>{item.name}</span>
                        <img className='space-item__icon' src={item.hover ? CheckHover : Check}></img>
                      </div>
                    </div>
                  </a>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default SpaceIndex;