import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { MemberData } from "../../request/data";
import { Spinner } from '@chakra-ui/react'
import { Helmet } from "react-helmet";
import { Image } from '@chakra-ui/react'
import "./members.css"
import info from "../../static/image/info.svg"
import holder_png from "../../static/image/holder.png"
import twitter from '../../static/image/twitter2.svg'

function Members() {
    const navigate = useNavigate();
    const [memberList, setMemberList] = useState(null)
    useEffect(() => {
        MemberData().then(res => {
            const resData = res.data
            if (resData.code == 0) {
                setMemberList(resData.data)
            }
        })
    }, [])

    const toPersonalPage = (addr) => {
        navigate(`/my-gallery/${addr}`)
    }

    return (
        <div className="min-h-3/4">
            <Helmet>
                <title>CreatorDAO-Creators</title>
            </Helmet>
            <div className=" max-w-2xl mx-auto py-6 px-4 sm:px-6 lg:max-w-7xl lg:px-8  ">
                {/* <h1 className=" m-auto mb-8  font-sans text-size-32 font-color-222 font-extrabold ">
                    {memberList ? memberList.member_numbers : 0} Creators
                </h1> */}

                <div className="grid grid-cols-1 gap-y-10 md:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                    {memberList ? memberList.artist_list.map((product, key) => (
                        <a key={key} className="border-css hover:shadow-lg w-full pb-5 px-2 md:pb-0 md:px-0 md:w-296 md:min-h-360 no-pointer m-auto">
                            <div className=" mb-4 mt-6 flex items-center h-14" onClick={() => toPersonalPage(product.address)}>
                                <div className="md:ml-5 rounded-full w-14 h-full max-h-14 bg-gray-200 over-hidden">
                                    <img src={product.avatar} className="w-full" />
                                </div>
                                <div className="w-48 ml-2 font-color-000 font-bold">{product.artist_name}</div>
                            </div>
                            <div className=" md:ml-5 w-full md:min-h-168 md:w-296 aspect-w-1 aspect-h-1  overflow-hidden flex">
                                <div className="h-168">
                                    <Image src={product.pictures[0] ? product.pictures[0] : ""} className="md:h-168 w-220 md:w-full object-center object-cover " fallbackSrc={holder_png} />
                                </div>
                                <div className="ml-2">
                                    <div className="w-103 md:w-20 md:h-20 overflow-hidden">
                                        <Image src={product.pictures[1] ? product.pictures[1] : ""}
                                            className="w-full object-center object-cover " fallbackSrc={holder_png} />
                                    </div>
                                    <div className="w-103 md:w-20 md:h-20 overflow-hidden mt-2">
                                        <Image src={product.pictures[2] ? product.pictures[2] : ""}
                                            className="w-full object-center object-cover " fallbackSrc={holder_png} />
                                    </div>
                                </div>
                            </div>
                            <div className="md:ml-5 w-full flex mt-4" >
                                <a href={product.twitter.indexOf("twitter.com") != -1 ? product.twitter : "https://twitter.com/" + product.twitter} rel="noopener noreferrer" target="_blank" className="flex   w-4/5 md:w-200 items-center">
                                    <div className="h-12 w-full md:w-200 bg-color-222 flex text-white leading-12 items-center ">
                                        <div className="flex m-auto   items-center text-size-14">
                                            <img src={twitter} className="h-6 mr-2" />
                                            TWITTER
                                        </div>
                                    </div>
                                </a>
                                <div className="ml-2 w-12 h-12 bg-color-ea flex items-center" onClick={() => toPersonalPage(product.address)}>
                                    <img src={info} className="h-5 m-auto" />
                                </div>
                            </div>
                        </a>
                    )) :
                        <Spinner
                            className="lg:col-span-4 m-auto"
                            thickness='4px'
                            speed='1.5s'
                            emptyColor='gray.200'
                            color='#ffc400'
                            size='lg'
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default Members;