import { AppContext } from "@/AppContext";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useContext, useRef } from 'react';
import Codebox from '@/components/codebox';
import { checkCode, getUserInfo } from '@/request/user';
import { verifyCode } from '@/request/auth';
import { useToast } from "@chakra-ui/react";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react'
import IconArrowRight from '@/static/image/icon-arrow-right.png';

import './index.less'

const ApplyUrl = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(`I would like to apply as creator. @creatordaocc
Looking for invite code! 
creatordao.cc

#CD #CreatorDAO`) + '&url=' + encodeURIComponent(`
creatordao.cc
`)

const CheckCode = () => {
  const codeLength = 6;
  const toast = useToast();
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const [token, setToken] = useState(null);
  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const [code, setCode] = useState("");
  const [isWrong, setIsWrong] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false)
  const { state, dispatch } = useContext(AppContext)
  const [tweetModalOpen, setTweetModalOpen] = useState(false)

  // useEffect(() => {
  //   if (code.trim().length === codeLength) {
  //     handleCheckCode(code, state.profile.address)
  //   }
  // }, [code, state.profile.address])

  useEffect(() => {
    if (code.trim().length === codeLength) {
      setButtonDisable(false)
    } else {
      setButtonDisable(true)
    }
  }, [code])

  const handleCheckCode = async () => {
    checkCode({
      code: code,
      address: state.profile.address
    }).then(res => {
      if (res.data.code != 0) {
        if (res.data.code === 3001) {
          toast({
            position: 'top',
            title: 'Wrong Code!',
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
          setCode("")
          setToken(null)
          setIsWrong(true)
          setCaptchaSuccess(false)
        } else {
          toast({
            position: 'top',
            // title: 'Check Code Error.',
            description: res.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        }
      } else {
        toast({
          position: 'top',
          title: 'Welcome Aboard!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        // navigate('/edit-profile')
      }
    }).catch(error => {
      toast({
        position: 'top',
        title: 'Check Code Error.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    })
  }

  const onLoad = () => {
    // this reaches out to the hCaptcha JS API and runs the
    // execute function on it. you can use other functions as
    // documented here:
    // https://docs.hcaptcha.com/configuration#jsapi
    // captchaRef.current.execute();
  };

  const onVerify = (token, ekey) => {
    setToken(token);
  }

  useEffect(() => {
    if (token) {
      handleVerify(token)
    }
  }, [token]);

  const handleVerify = async (token) => {
    var data = new FormData()
    data.append("h-captcha-response", token);
    verifyCode(data).then(res => {
      if (res.data.code != 0) {
        toast({
          position: 'top',
          title: 'Verify Code Error.',
          description: res.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      } else {
        setCaptchaSuccess(true)
      }
    }).catch(error => {
      toast({
        position: 'top',
        title: 'Verify Code Error.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    })
  }

  return (
    <div className='checkcode-wrapper w-full sm:w-100 lg:w-100 md:w-100'>
      <div className='code-content-box'>
        <div className="title pt-16">👋 Enter invite code to upload your work</div>
        {
          captchaSuccess ? (
            <>
              <Codebox
                length={codeLength}
                isWrong={isWrong}
                changeState={(e) => {
                  setIsWrong(e)
                }}
                onChange={codeArray => {
                  setCode(codeArray.map(v => (v === "" ? " " : v)).join(""))
                }}
              />
              {
                isWrong && <div className="error-tips">Wrong Code</div>
              }

              <button className="main-button" disabled={buttonDisable} onClick={() => handleCheckCode()}>
                Become a creator
                <img src={IconArrowRight}></img>
              </button>
            </>
          ) : (
            <div className="captcha-wrap">
              <div className="title">Are you human?</div>
              <form>
                <HCaptcha
                  id="CodeVerify"
                  sitekey="ba9b86bd-ea50-454b-a8d2-ab5d36744f29"
                  onLoad={onLoad}
                  onVerify={(token, ekey) => onVerify(token, ekey)}
                  ref={captchaRef}
                />
              </form>
            </div>
          )
        }
        <div className="tips">Click <a className="tips-link" onClick={() => { setTweetModalOpen(true) }}>HERE</a> to Apply For Invite Code. </div>

        <Modal
          size="md"
          isOpen={tweetModalOpen}
          closeOnOverlayClick={true}
          onClose={() => { setTweetModalOpen(false) }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Invitation</ModalHeader>
            <ModalCloseButton className="remove-focus" />
            <ModalBody className="text-16">
              <div className="pb-2">
                In the current beta, creators are by invitation only.
                The creator can click on the &apos;Apply&apos; button to express your desire to join (using Twitter).
              </div>
              <div>
                The collector can click on their wallet at the top right corner of the screen to setup their profile.
              </div>
            </ModalBody>
            <ModalFooter>
              <a target="_blank" rel="noopener noreferrer" type="external" href={ApplyUrl} className="inline-block w-full">
                <button className="twitter-share-button flex justify-center btn-middle bg-color-base max-w-2xl font-bold text-16 lg:text-20 px-4 h-12 lg:h-14 w-full text-color-222">
                  {/* <span className="iconfont icon-twitter"></span> */}
                  Apply
                </button>
              </a>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </div>
  )
}
export default CheckCode;