import React, { useEffect, useState } from "react";
import { textSelect, removeDefaultBehavior, isFunction } from "./utils";
import "./index.less";

const Codebox = ({ length, isWrong, changeState, validator, onChange }) => {
  const [code, setCode] = useState(new Array(length).fill(""));
  const [dom, setDom] = useState(new Array(length));

  const handleInputOnChange = (e, i) => {
    const value = e.target.value.trim();
    if (isFunction(validator)) {
      if (value !== "" && !validator(value, i)) {
        textSelect(e.target);
        return;
      }
    }

    if (code[i] !== value && value) {
      focusOn(i + 1);
    }

    const newCode = code.map(v => v);
    newCode[i] = value;
    setCode(newCode)
    textSelect(e.target);
    if (value !== "") {
      focusOn(i + 1);
    }
    if (isFunction(onChange)) {
      if (newCode.every(v => v !== "")) {
        e.target.blur();
      }
      onChange(newCode);
    }
  }

  const getPrevBox = (i) => {
    return dom[i - 1];
  }

  const getNextBox = (i) => {
    return dom[i + 1];
  }

  const focusOn = (i) => {
    const element = dom[i];
    if (element) {
      element.focus();
    }
  }

  const onKeyDown = (e, i) => {
    const inputElement = e.target;
    switch (e.keyCode) {
      case 8: // 删除完之后，退回到上一个输入框
        if (e.target.value === "") {
          // 如果空的话，那么就退回到上一个输入框
          removeDefaultBehavior(e);
          focusOn(i - 1);
        }
        break;
      case 37: // 左
      case 38: // 上
        removeDefaultBehavior(e);
        if (getPrevBox(i)) {
          focusOn(i - 1);
        } else {
          focusOn(i);
        }
        break;
      case 39: // 右
      case 40: // 下
        removeDefaultBehavior(e);
        if (getNextBox(i)) {
          focusOn(i + 1);
        } else {
          focusOn(i);
        }
        break;
      default:
        // 不管你输入什么
        // 都会聚焦文本
        textSelect(inputElement);
    }
  }

  const onPaste = (e, i) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text/plain").split("")
    const newText = text.splice(0, length)
    setCode(newText);
    onChange(newText);
  }

  useEffect(() => {
    if (isWrong) {
      setTimeout(() => {
        changeState(false)
        setCode(new Array(length).fill(""))
      }, 2000)
    }
  }, [isWrong, length])

  return (
    <div className="codebox-container">
      {
        new Array(length).fill("").map((item, i) => {
          return (
            <div key={`item_${i}`} className="codebox-field-wrap">
              <input
                className={`${code[i].trim() ? 'fill' : ''} ${isWrong ? 'wrong' : ''}`}
                type="text"
                maxLength="1"
                autoComplete="false"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                value={code[i]}
                ref={domRef => {
                  const newDom = dom
                  newDom[i] = domRef
                }}
                onFocus={e => {
                  textSelect(e.target)
                }}
                onClick={e => textSelect(e.target)}
                onChange={e => handleInputOnChange(e, i)}
                onKeyDown={e => onKeyDown(e, i)}
                onPaste={e => onPaste(e, i)}
              />
            </div>
          )
        })
      }
    </div>
  );
}

export default Codebox;