/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useRef, useState,useContext } from 'react'
import { useMoralisWeb3Api,useMoralis } from "react-moralis";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  CloseButton ,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Select,
  Image,
} from '@chakra-ui/react'
import { AppContext } from '../../AppContext';
import { useNewConnectWallet } from '../../wallet/newConnect';
// import { useWeb3React } from "@web3-react/core"
import Web3 from 'web3';
import { postOrder,checkOrderQueued } from '../../request/order';
import { MIN_BID,PROCESS_RESET,PROCESS_WAITING,PROCESS_DONE } from '../../config/params';
import { ethers } from "ethers";

import {Stepper,Uploader } from 'react-vant';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


const abi = JSON.parse(process.env.REACT_APP_ABI)
const contract_address = process.env.REACT_APP_ADDRESS

export default function Commisson(props) {
  const {state,dispatch} = useContext(AppContext)
  const toast = useToast()
  // const {account,activate,active,chainId,library} = useWeb3React()
  const {isOpen,onOpen,onClose,shopId,shopType,setNewRecord,tokenAddress,tokenName} = props
  // const [showEmail,setShowEmail] = useState(false)
  const [errMsg,setErrMsg] = useState("")
  const [process,setProcess] = useState(PROCESS_RESET)
  const [bid,setBid] = useState(MIN_BID)
  const [minBid,setMinBid] = useState(MIN_BID)
  const [email,setEmail] = useState("")
  const [twitter,setTwitter] = useState("")
  const [notes,setNotes] = useState("")
  const [address,setAddress] = useState("")
  const [bayc,setBayc] = useState("")
  const [showError,setShowError] = useState(false)
  const [isChecked,setIsChecked] = useState(false)
  const [beforeRead, setBeforeRead] = useState([])
  const [currentList, setCurrentList] = useState([])
  const [loading, setLoading] = useState(false);
  const [agree, setAgree] = useState(false);
  const [tx, setTx] = useState("");

  async function toSubmit(){
    setShowError(true)
    if (!state.profile.address){
      setErrMsg("please connect wallet")
      // connectWallet()
      return;
    }
    if (!agree){
      setErrMsg("Please check the agree service agreement")
      // connectWallet()
      return;
    }

    if (address.replace(/(^s*)|(s*$)/g, "").length ==0){
      return
    }
    if (bayc.replace(/(^s*)|(s*$)/g, "").length ==0){
      return
    }
    if (twitter.replace(/(^s*)|(s*$)/g, "").length ==0){
      return
    }
    setProcess(PROCESS_WAITING)
    
  if (twitter.replace(/(^s*)|(s*$)/g, "").length ==0){
      setProcess(PROCESS_RESET)
      return
    }

    let provider = state.profile.provider
    if (!provider){
      await window.web3.currentProvider.enable();
      provider = window.web3.currentProvider
    }
   
    const  web3 = new Web3(provider);
    const contract =  new web3.eth.Contract(abi, contract_address)

    let avatarFile
    if (beforeRead.length>0)avatarFile = beforeRead[0].file
   
    var data = new FormData()
    data.append("picture_file",avatarFile)
    data.append("shop_id",shopId)
    data.append("notes",notes)
    data.append("price",bid.toString())
    data.append("address",state.profile.address)
    data.append("received_address",address)
    data.append("token_id",bayc)
    data.append("email",email)
    data.append("twitter",twitter)

    contract.methods
    .commission(bayc,parseInt(shopId)).send({
      from: state.profile.address,
      value:ethers.utils.parseEther(bid.toString())
    })
    .on('transactionHash', function(hash){
      data.append("tx",hash)
      setTx(hash)
      postOrder(data)
  })
    .on("receipt", function (receipt) {
      toast({
        position: 'top',
        title: 'Submit commission success',
        // description: error.message,
        status: 'success',
        duration: 8000,
        isClosable: true,
      })

      setNewRecord({address:state.profile.address,price:bid})
      onClose()
      setProcess(PROCESS_DONE)
      checkOrderQueued({transaction_id:receipt.transactionHash})
    })
    .on("error", function (error, receipt) {
      if (error.code != 4001) {
        setErrMsg(error.message)
      }

      setProcess(PROCESS_RESET)
    });
  }

  const toChangeChoose= (e)=>{
    setIsChecked(e.target.checked)
    setAddress(!e.target.checked?state.profile.address:"")
  }

  const changeAgree=(e)=>{
    setAgree(e.target.checked)
  }

  const asyncBeforeRead = async (file)=>{
    const files = Array.isArray(file)?file:[file]
    return new Promise((resolve)=>{
        const passFiles = files.filter((f)=>{
            if (f.type !== 'image/png' && f.type !== 'image/jpeg'){
              toast({
                position: 'top',
                title: 'Upload picture error.',
                description: `${f.name}error,please upload png/jpg image`,
                status: 'error',
                duration: 3000,
                isClosable: true,
              })

                return false;
            }
            return true;
        })
        resolve(passFiles)
    })
}

  function clearUpAll(){
    // setShowEmail("")
    setErrMsg("")
    setProcess(PROCESS_RESET)
    setBid(MIN_BID)
    setEmail("")
    setTwitter("")
    setNotes("")
    setBeforeRead([])
    setShowError(false)
    setBayc("")
    setIsChecked(false)
    setAgree(false)
  }

  useEffect(()=>{
   if(props.minBid){
     setBid(parseFloat(props.minBid))
     setMinBid(parseFloat(props.minBid))
     setAddress(state.profile.address)
   }
  },[props])

  useEffect(()=>{
   
    return ()=>{
      clearUpAll()
    }
  },[isOpen])

  const Web3Api = useMoralisWeb3Api();

  const fetchNFTs = async () => {
    
    setBayc("")

    if (!address){
      setCurrentList([])
      return
    }
    if (!isOpen)return

    setLoading(true)

    // get NFTs for address
    const options = {
      chain: "eth",
      address: address,
      token_address:tokenAddress
    };
    let allNeedNFTs = []
    let cursor = null

    do{
      options.cursor = cursor
      const nfts = await Web3Api.account.getNFTsForContract(options);
      const needArr = nfts.result
    //   .filter((val,index)=>{
    //   if (val.symbol=="BAYC"){
    //     // const meta = JSON.parse(val.metadata)
    //     // const url = meta.image.replace("ipfs://","https://ipfs.io/ipfs/")
    //     // val.image_url = url
    //     return val
    //   }
    // })
    allNeedNFTs = allNeedNFTs.concat(needArr)
    cursor = nfts.cursor
    }while(cursor != "" && cursor != null)

    setCurrentList(allNeedNFTs)
    setLoading(false)

  };

  useEffect(()=>{
      fetchNFTs()
  },[address])

  useEffect(()=>{
      fetchNFTs()
  },[isOpen])

  // const toClose = ()=>{
  //   setNewRecord({address:'0x54be3a794282c030b15e43ae2bb182e14c409c5e',price:0.5})
  //   onClose()
  // }

  return (

    <Modal size={"xl"} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>

        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Submit Commission</ModalHeader>
          <ModalCloseButton className="remove-focus" />
          <ModalBody pb={6}>
          < form action="#" method="POST" className='mt-4'>
            <FormControl className='lg:grid lg:grid-cols-12 mt-4' isRequired>
              <FormLabel className='lg:col-span-2 mt-2 text-size-14 font-semibold text-color-222' htmlFor='address'>Address</FormLabel>
              <Input id='address' type='text'   disabled={!isChecked} onChange={(e)=>setAddress(e.target.value)} value={address} className='rounded-none h-9 lg:col-span-10 text-size-14'  placeholder="Please enter address" />
            </FormControl>
            {
              showError && !address?
              <FormControl className='lg:grid lg:grid-cols-12' isInvalid={ showError && !address}>
                  <FormLabel className='lg:col-span-2 mt-2 text-size-14 text-gray-700' htmlFor=''></FormLabel>
                  <FormErrorMessage className='lg:col-span-10 text-size-14'>Address is required.</FormErrorMessage>
              </FormControl>
              :""
            }
            
            <FormControl className='lg:grid lg:grid-cols-12'>
              <FormLabel className='lg:col-span-2 text-size-14 text-gray-700' ></FormLabel>
              <div className='lg:col-span-10 '>
              <Checkbox onChange={toChangeChoose} className=''>
              <span className='text-color-222 text-size-14'>Select to enter my {tokenName} holding wallet address.</span>
              </Checkbox>
              </div>
             
            </FormControl>
          <Spin spinning={loading} indicator={antIcon} >

            <FormControl className='lg:grid lg:grid-cols-12 mt-4' isRequired>
              <FormLabel className='lg:col-span-2 mt-2 text-size-14 font-semibold text-color-222' htmlFor='bayc'>{tokenName}</FormLabel>
              <div>
              <Select id='bayc'  onChange={(e)=>setBayc(e.target.value)} value={bayc} className='rounded-none h-9  text-size-14 lg:col-span-10 lg:w-440'  placeholder={"Select one "+tokenName} >
                {
                  currentList?currentList.map((value,key)=>{
                    return <option key={key} value={value.token_id}>
                      {value.symbol} #{value.token_id}</option>
                  })
                  :""
                }
                </Select>
              </div>
            </FormControl>
            {
              showError && !bayc?
              <FormControl className='lg:grid lg:grid-cols-12' isInvalid={ showError && !bayc}>
                  <FormLabel className='lg:col-span-2 mt-2' htmlFor=''></FormLabel>
                  <FormErrorMessage className='lg:col-span-10 text-size-14'>{tokenName} is required.</FormErrorMessage>
              </FormControl>
              :""
            }
      </Spin>

            <FormControl className='lg:grid lg:grid-cols-12 mt-4' isRequired>
              <FormLabel className='lg:col-span-2 mt-2 text-size-14 font-semibold text-color-222' htmlFor='twitter'>Twitter</FormLabel>
              <Input id='twitter' type='text' onChange={(e)=>setTwitter(e.target.value)} value={twitter} className='rounded-none h-9 lg:col-span-10 text-size-14'  placeholder="Please double check and make sure DM is open." />
            </FormControl>
            {
              showError && !twitter?
              <FormControl className='lg:grid lg:grid-cols-12 ' isInvalid={ showError && !twitter}>
                  <FormLabel className='lg:col-span-2 text-size-14 text-gray-700' htmlFor=''></FormLabel>
                  <FormErrorMessage className='lg:col-span-10 text-size-14'>Twitter is required.</FormErrorMessage>
              </FormControl>
              :""
            }
            <FormControl className='lg:grid lg:grid-cols-12 mt-4 text-gray-700'>
              <FormLabel className='lg:col-span-2 mt-2 font-semibold text-color-222' htmlFor='email'>Email</FormLabel>
              <Input id='email' type='email' onChange={(e)=>setEmail(e.target.value)} value={email} className='rounded-none h-9 lg:col-span-10 text-size-14'  placeholder="optional!" />
            </FormControl>
                   
                      {
                        shopType !==20?
                        <div className='lg:grid lg:grid-cols-12'>
                        <div className='lg:col-span-4'>
                        <label htmlFor="picture" className="mt-5 mb-2 lg:col-span-2 block text-sm font-semibold text-color-222">
                          Reference
                          Picture
                      </label>
                        <Uploader
                              maxCount={1}
                              onClickPreview={(items, detail) => {
                                // console.log(items, detail);
                              }}
                              value={beforeRead}
                              onChange={setBeforeRead}
                              beforeRead={asyncBeforeRead}
                            />
                        </div>
                        <div className='mt-4 lg:col-span-8'>
                          <div className="mt-1">
                            <textarea
                              id="about"
                              name="about"
                              rows={7}
                              onChange={(e)=>setNotes(e.target.value)}
                              className="pl-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 "
                              placeholder="Please add some description to help the artist understand what you'd like in your character design."
                              value={notes}
                            />
                          </div>
                        </div>
                      </div>
                        :""
                      }
                      <div className='hidden lg:block mt-4'>
                        <div className="grid grid-cols-12 gap-6 mt-4">
                          <label htmlFor="email" className="mt-2 col-span-8 block text-sm font-semibold text-color-222">
                            Your Offer
                          </label>
                          <div className="col-span-4 ">
                            <Stepper className='mt-1 float-right' min={minBid} step="0.01" onChange={(v)=>setBid(v)} value={bid}  disableInput />
                          </div>
                        </div>
                        <div className='mt-4 text-red-400'>
                            {errMsg}
                          </div>
                        <div>

                        </div>
                      </div>

                      <div  className='w-full mt-4'>
                        <div className='text-size-12 flex'>
                        <span className='text-red-600 mt-3'>*</span>
                        <div className='items-start text-size-12'>
                        <Checkbox isFocusable={false} isRequired onChange={changeAgree}  className='leading-4'>
                        <span className='text-size-12 leading-4'>By submitting, you agree you are the owner of The {tokenName} NFT or have obtained consent from the current NFT owner for this commission work.</span> 
                        </Checkbox>
                        </div>
                        
                          {/* <div className='text-xs leading-4'>
                          </div> */}
                        </div>
                      </div>
            </form>

        </ModalBody>

          <ModalFooter>
           
              <div className=" lg:hidden w-full float-left">
                <Stepper className='mt-1' min={minBid} step="0.01" onChange={(v)=>setBid(v)} value={bid}  disableInput />
              </div>
              <Button
                fontWeight={700}
                color='#222'
                width='128px'
                height='40px'
                // marginRight='16px'
                isLoading={process===PROCESS_WAITING}
                loadingText="Waiting..."
                borderRadius='1px'
                variant='solid'
                colorScheme='customPrimary'
                isDisabled={!twitter || !bayc || !address || !agree}
                onClick={() => toSubmit()}
              >Submit</Button>
          </ModalFooter>
        </ModalContent>

      </Modal>

  )
}
