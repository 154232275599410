
import { Tabs } from 'antd';
import { Helmet } from "react-helmet";
import { AppContext } from "@/AppContext";
import { useParams } from "react-router-dom";
import ShopListComponent from '@/components/ShopList';
import ArtsListComponent from '@/components/ArtsList';
import BountyListComponent from '@/components/BountyList';
import React, { useState, useEffect, useContext } from 'react';
import { getSpaceListResuest, getSpaceInfoResuest } from "@/request/data";

import "./index.less"

const { TabPane } = Tabs;

const SpaceDetail = () => {
  const pageSize = 10;
  const params = useParams();
  const symbol = params.id
  const [page, setPage] = useState(1)
  const [tabKey, setTabKey] = useState('gallery')
  const [artsPage, setArtsPage] = useState(1);
  const [haveNext, setHaveNext] = useState(true);
  const [spaceInfo, setSpaceInfo] = useState({
    space_info: {
      logo: ''
    }
  })
  const [spaceList, setSpaceList] = useState([]);
  const [finish, setFinish] = useState(true)

  useEffect(() => {
    getSpaceInfo()
  }, [])

  useEffect(() => {
    if (tabKey !== 'bounty') {
      getSpaceList()
    }
  }, [tabKey, artsPage])

  const getSpaceInfo = async () => {
    const { data } = await getSpaceInfoResuest({
      tag: symbol
    })
    if (data.code === 0) {
      setSpaceInfo(data.data)
    }
  }

  const getSpaceList = async () => {
    setFinish(false)
    const { data } = await getSpaceListResuest({
      page: page,
      size: pageSize,
      tag: symbol,
      category: tabKey
    })
    setFinish(true)
    if (data.code === 0) {
      if (tabKey === 'gallery') {
        setHaveNext(data.data.length >= pageSize)
        let newList = [...spaceList];
        data.data.map(item => {
          item.hover = false
          item.src = item.little_picture
        })
        newList = newList.concat(data.data)
        newList.map((item, index) => item.index = index)
        setSpaceList(newList)
      } else {
        setSpaceList(data?.data || [])
      }
    }
  }

  const onChange = (key) => {
    setPage(1)
    setSpaceList([])
    setTabKey(key)
  };

  return (
    <div className="min-h-3/4 space-detail-wrapper theme-light">
      <Helmet>
        <title>CreatorDAO-Space</title>
      </Helmet>
      <div className='title-wrap' style={{ backgroundImage: `url('${spaceInfo.space_info.logo}')` }}>
        <div className='title-box'>
          <div className='title-content w-full max-w-3xl mx-auto lg:max-w-7xl lg:px-8'>
            <img className='title-img' src={spaceInfo.space_info.logo}></img>
            <div className='title-text'>{spaceInfo.space_info.tag} Space</div>
          </div>
        </div>
      </div>

      <div className='tabs-wrap max-w-7xl mx-auto px-4 md:px-0'>
        <Tabs defaultActiveKey="gallery" onChange={onChange}>
          <TabPane tab={
            <>
              Fan arts
              <span className='number'>{spaceInfo.arts}</span>
            </>
          } key="gallery">
            {
              finish && spaceList.length > 0 && tabKey === 'gallery' && <ArtsListComponent list={spaceList} setList={(list) => setSpaceList(list)}></ArtsListComponent>
            }
            {
              haveNext && <div className="load-more-btn" onClick={() => {
                const newPage = page + 1
                setArtsPage(newPage)
              }}>LOAD MORE</div>
            }
          </TabPane>
          <TabPane
            tab={
              <>
                Shops
                <span className='number'>{spaceInfo.shops}</span>
              </>
            }
            key="shop"
          >
            {
              finish && spaceList.length > 0 && tabKey === 'shop' && <ShopListComponent list={spaceList}></ShopListComponent>
            }
          </TabPane>
          <TabPane tab={
            <>
              Bounties
              <span className='number'>{spaceInfo.bounties}</span>
            </>
          } key="bounty">
            {tabKey === 'bounty' && <BountyListComponent symbol={symbol} />}
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}
export default SpaceDetail;