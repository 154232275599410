import { useEffect, useState } from 'react'
import move from 'lodash-move'
import useSWR from 'swr'
import { motion } from 'framer-motion'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import {
  GridItemEight,
  GridItemFour,
  GridLayout,
} from 'src/components/Layout/grid'
import { homeData } from 'src/request/data'

const ApplyUrl =
  'https://twitter.com/intent/tweet?text=' +
  encodeURIComponent(`I would like to apply as creator. @creatordaocc
Looking for invite code! 
creatordao.cc
#CD #CreatorDAO`) +
  '&url=' +
  encodeURIComponent(`
creatordao.cc
`)

export const Hero = () => {
  return (
    <div className="bg-hero pt-24 md:pt-40 pb-6 md:pb-12 h-fit">
      <GridLayout className="flex">
        <GridItemEight className="flex flex-col justify-center">
          <Slogan />
          <CTA />
          <SiteData className="hidden md:inline-flex" />
        </GridItemEight>
        <GridItemFour>
          <CardStack />
          <SiteData className="inline-flex md:hidden" />
        </GridItemFour>
      </GridLayout>
    </div>
  )
}

const Slogan = () => {
  return (
    <div className="flex text-white flex-col justify-center">
      <span className="font-bold text-4xl md:text-52 leading-tight text-center md:text-left">
        Web3 Creative/Commission Platform
      </span>
      <span className="text-2xl md:text-3xl mt-5 text-center md:text-left">
        By Creator, For Creator.
      </span>
    </div>
  )
}

const CTA = () => {
  const [tweetModalOpen, setTweetModalOpen] = useState(false)

  return (
    <>
      <div className="text-white mt-14 md:mt-28 flex justify-center md:justify-start">
        <a
          onClick={() => setTweetModalOpen(true)}
          className="rounded text-xl py-2 px-6 mr-12 border border-dark-2 bg-dark-2 hover:text-primary"
        >
          Join Now →
        </a>

        <a
          href="/booth"
          className="text-xl rounded py-2 px-6 bg-transparent border-white border hover:text-primary hover:border-primary"
        >
          Explore
        </a>
      </div>
      <Modal
        size="md"
        isOpen={tweetModalOpen}
        closeOnOverlayClick={true}
        onClose={() => {
          setTweetModalOpen(false)
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invitation</ModalHeader>
          <ModalCloseButton className="remove-focus" />
          <ModalBody className="text-16">
            <div className="pb-2">
              In the current beta, creators are by invitation only. The creator
              can click on the &apos;Apply&apos; button to express your desire
              to join (using Twitter).
            </div>
            <div>
              The collector can click on their wallet at the top right corner of
              the screen to setup their profile.
            </div>
          </ModalBody>
          <ModalFooter>
            <a
              target="_blank"
              rel="noopener noreferrer"
              type="external"
              href={ApplyUrl}
              className="inline-block w-full"
            >
              <button className="twitter-share-button flex justify-center btn-middle bg-color-base max-w-2xl font-bold text-16 lg:text-20 px-4 h-12 lg:h-14 w-full text-color-222">
                {/* <span className="iconfont icon-twitter"></span> */}
                Apply
              </button>
            </a>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const CARD_OFFSET = 40
const CARD_FACTOR = 0.06
const CARD_IMAGES = [
  'https://ik.imagekit.io/creatordao/https%3A%2F%2Ffile.creatordao.cc%2Ffiles%2Fbanner%2Fbig-banner-1.jpg?s=294924d5268c02d9492f61231785fe5a',
  'https://ik.imagekit.io/creatordao/https%3A%2F%2Ffile.creatordao.cc%2Ffiles%2Fbanner%2Fbig-banner-2.jpg?s=50582f9937617ef9526c1095721e4873',
  'https://ik.imagekit.io/creatordao/https%3A%2F%2Ffile.creatordao.cc%2Ffiles%2Fbanner%2Fbig-banner-3.jpg?s=7322e84474623f8f855c2bdc481e4ff5',
]
const rotateZDeg = [0, '8deg', '-8deg']
const CardStack = () => {
  const [cards, setCards] = useState(CARD_IMAGES)
  const moveToEnd = from => setCards(move(cards, from, cards.length - 1))

  return (
    <div className="relative h-screen-1/2 md:h-full flex items-center w-full mt-36 md:mt-0 md:w-auto justify-center">
      <ul className="relative h-full z-0 w-screen md:w-fit px-12 md:px-0">
        {cards.map((img, index) => {
          const canDrag = index === 0
          return (
            <motion.li
              key={img}
              className="absolute w-4/5 box-border md:w-96 h-full bg-white rounded-lg p-4 origin-center shadow-xl flex flex-col"
              data-index={index % 3}
              style={{
                cursor: canDrag ? 'grab' : 'auto',
                rotate: rotateZDeg[index % 3],
                rotateZ: rotateZDeg[index % 3],
              }}
              animate={{
                top: index * -CARD_OFFSET,
                scale: 1 - index * CARD_FACTOR,
                zIndex: CARD_IMAGES.length - index,
              }}
              transformTemplate={({ rotate }) => `rotateZ(${rotate})`}
              drag={canDrag ? 'y' : false}
              dragConstraints={{
                top: 0,
                bottom: 0,
              }}
              onDragEnd={() => moveToEnd(index)}
            >
              <img
                src={img}
                className="select-none object-cover h-8/9 rounded-lg pointer-events-none"
              />
              <UserInfo />
            </motion.li>
          )
        })}
      </ul>
    </div>
  )
}

const UserInfo = () => {
  return (
    <div className="flex mt-auto items-center">
      <img
        className="rounded-full w-8 h-8 mr-2"
        src="https://creatordao.imgix.net/https%3A%2F%2Ffile.creatordao.cc%2Ffiles%2F2022%2F06%2F07%2FhkkwXvguIB%E5%A4%B4%E5%83%8FZZZ-03.png?h=100&s=fc18ccae68d152e1688ada34b34d422a"
        alt=""
      />
      <span className="font-bold">ziidu</span>

      {/* <span className="font-bold ml-auto">Sell at 0.1 ETH</span> */}
    </div>
  )
}

const SiteData = ({ className }) => {
  const { data: siteData } = useSWR<any>('siteData', homeData)
  const [data, setData] = useState({
    creators: 0,
    shops: 0,
    orders: 0,
  })

  useEffect(() => {
    if (siteData) {
      setData({
        creators: 80,
        shops: siteData.data.data.shop_numbers,
        orders: siteData.data.data.order_numbers,
      })
    }
  }, [siteData])
  return (
    <div
      className={`flex text-white gap-6 md:gap-12 w-full md:w-auto items-center justify-start mt-12 md:mt-20 flex-col md:flex-row ${className}`}
    >
      {Object.entries(data).map(([text, num]) => (
        <div
          key={text}
          className="flex-col gap-2 md:gap-4 justify-center items-center inline-flex"
        >
          <div className="flex items-center gap-2">
            <img src="/images/leaf-left.svg" alt="" />
            <span className="font-bold text-2xl">{num}+</span>
            <img src="/images/leaf-right.svg" alt="" />
          </div>
          <span className="text-xl">{text.toUpperCase()}</span>
        </div>
      ))}
    </div>
  )
}

export default Hero
