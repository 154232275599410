import { Content } from 'src/components/Layout/content'

export const Flow = () => {
  return (
    <div className="bg-black py-12 px-5 md:px-0">
      <Content className="flex align-center flex-col">
        <h2 className="text-xl md:text-3xl text-white text-center my-6 line mx-auto inline-block">
          Order Process
        </h2>
        <img className="mx-auto" src="/images/flow.svg" alt="" />
      </Content>
    </div>
  )
}
