import axios from "axios";
import { SIGN_KEY } from "../config/params";
export function postOrder(data){
   return axios({
        method:"post",
        url:"/v1/order/create",
        data:data,
        headers: {'Content-Type':'multipart/form-data;charset=UTF-8'}
      })
}


export function checkOrderQueued(data){
     return axios({
          method:"post",
          url:"/v1/order/check/newCommission",
          data:data,
          headers: {'Content-Type':'application/json;charset=UTF-8'}
        })
  }
  export function checkOrderCanceled(data){
     return axios({
          method:"post",
          url:"/v1/order/check/rescinded",
          data:data,
          headers: {'Content-Type':'application/json;charset=UTF-8'}
        })
  }
  
  export function checkOrderBidUpdated(data){
     return axios({
          method:"post",
          url:"/v1/order/check/bidUpdated",
          data:data,
          headers: {'Content-Type':'application/json;charset=UTF-8'}
        })
  }
  export function checkOrderAccepted(data){
     return axios({
          method:"post",
          url:"/v1/order/check/process",
          data:data,
          headers: {'Content-Type':'application/json;charset=UTF-8'}
        })
  }
  export function checkOrderSettled(data){
     return axios({
          method:"post",
          url:"/v1/order/check/settled",
          data:data,
          headers: {'Content-Type':'application/json;charset=UTF-8'}
        })
  }
export function postPic(data,token){
     return axios({
          method:"post",
          url:"/v1/order/upload/picture",
          data:data,
          headers: {
               'Content-Type':'multipart/form-data;charset=UTF-8',
               Authorization: token ? `Bearer ${token}`: null, // 携带token
          }
        })
  }
  export function acceptOrder(data,token){
     return axios({
          method:"post",
          url:"/v1/order/accept",
          data:data,
          headers: {
               'Content-Type':'application/json;charset=UTF-8;charset=UTF-8',
               Authorization: token ? `Bearer ${token}`: null, // 携带token
          }
        })
  }
export function postShop(data){
     return axios({
          method:"post",
          url:"/v1/shop/apply",
          data:data,
          headers: {'Content-Type':'multipart/form-data;charset=UTF-8'}
        })
  }

export function processOrderQueued(data){
    return axios({
         method:"post",
         url:"/v1/order/state/queued",
         data:data,
         headers: {'Content-Type':'application/json;charset=UTF-8'}
       })
 }

 export function orderList(){
  return axios({
       method:"get",
       url:"/v1/order/list",
       headers: {'Content-Type':'application/json;charset=UTF-8'}
     })
}

export function accountOrderList(account){
  return axios({
       method:"get",
       url:"/v1/order/list/account?address="+account,
       headers: {'Content-Type':'application/json;charset=UTF-8'}
     })
}
export function myWorkOrderList(account){
     return axios({
          method:"get",
          url:"/v1/data/my-work?address="+account,
          headers: {'Content-Type':'application/json;charset=UTF-8'}
        })
   }

   export function recentOrderList(){
     return axios({
          method:"get",
          url:"/v1/data/order/finished",
          headers: {'Content-Type':'application/json;charset=UTF-8'}
        })
   }