import axios from "axios";

export function verifyCode (data) {
  return axios({
    method: "post",
    url: "/v1/auth/verify-codes/hcaptcha",
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data;charset=UTF-8',
      // Authorization: token ? `Bearer ${token}` : null, // 携带token
    }
  })
}