import React from "react";
import { Helmet } from "react-helmet";
function About(){
    return (
        <div className="min-h-1/2 my-10 w-full md:w-4/5 m-auto bg-white px-8">
            <Helmet>
                <title>CreatorDAO-About Us</title>
            </Helmet>

            <h1 className=" text-center m-auto h-12 text-gray-700  md:mt-6 font-sans text-xl lg:text-2xl font-mono font-extrabold ">
            about us
            </h1>
            <div className="w-full md:w-4/5 m-auto">
            <p className="text-left tracking-widest text-gray-700 leading-loose">
            CreatorDAO is building a platform to allow anything to be commissioned (e.g. Image, Audio, 3D, Lore, Animation, etc). From vision to imagination to creation, spreading seeds with unlimited potential into the metaverse.
            </p>
            <br />
            <p className="text-left tracking-widest text-gray-700 leading-loose">
            We believe the NFTs space is entering the next stage, where original NFTs holders need new creativities and stories for their NFTs. For every BAYC, every Azuki, there could be an independent brand or even a specific metaverse.
                </p>
                <br />
            <p className="text-left tracking-widest text-gray-700 leading-loose">
            And we are a DAO making it possible.
                </p>
                <br />
               
               <p className="text-left tracking-widest text-gray-700 leading-loose">
               We are aming to creat values for both creators and collectors.
               </p>
               <br />
               <p className="text-left tracking-widest text-gray-700 leading-loose">
               On one side, CreatorDAO can help creators quickly understand web3 and get involved by being commissioned.
               </p>
               <br />
               <p className="text-left tracking-widest text-gray-700 leading-loose">
               On the other side, NFT holders can expand the possibility of their NFTs by commissioning artists to concretize their imagination.
               </p>
               <br />
               <p className="text-left tracking-widest text-gray-700 leading-loose">
               The re-created artworks and stories born with them belonged to the original NFT holders, but they are also a part of our many specific collections.
               </p>
               <br />
            </div>
            
        </div>
    )
}

export default About;