import discord from '../../static/image/discord2.svg'
import twitter from '../../static/image/twitter2.svg'
import github from '../../static/image/github2.svg'
import gitbook from '../../static/image/gitbook-icon.svg'
import { GridItemSix, GridLayout } from '../Layout/grid'

export const Footer = () => {
  return (
    <div className="bg-black divide-y divide-dark-2">
      <div className="pt-6 md:pt-12 flex mx-auto max-w-screen-xl pb-12">
        <div className="flex my-4 md:my-0 mr-auto items-start">
          <img
            className="block w-40 mr-auto"
            src="/images/cdlogo.png"
            alt="creatorDAO"
          />
        </div>
        <div className="flex flex-row justify-between px-6 md:px-0 mt-4 md:mt-0 gap-10 md:gap-40">
          <dl className="text-footnote-2 flex flex-col gap-2">
            <dt className="text-footnote-1">Discover</dt>
            <dd>
              <a
                href="https://creatordao.gitbook.io/creatordao"
                rel="noopener noreferrer"
                target="_blank"
              >
                GitBook
              </a>
            </dd>
            <dd>
              <a
                href="https://mirror.xyz/0xcreatordao.eth"
                rel="noopener noreferrer"
                target="_blank"
              >
                Mirror
              </a>
            </dd>
            <dd>
              <a
                href="https://medium.com/@CreatorDAO"
                rel="noopener noreferrer"
                target="_blank"
              >
                Medium
              </a>
            </dd>
          </dl>
          <dl className="text-footnote-2 flex flex-col gap-2">
            <dt className="text-footnote-1">Community</dt>
            <dd>
              <a
                href="https://discord.gg/creatordao"
                target="_blank"
                rel="noopener noreferrer"
              >
                Discord
              </a>
            </dd>
            <dd>
              <a
                href="https://twitter.com/creatordaocc"
                rel="noopener noreferrer"
                target="_blank"
              >
                Twitter
              </a>
            </dd>
            <dd>
              <a
                href="https://space.bilibili.com/1132077553"
                rel="noopener noreferrer"
                target="_blank"
              >
                Biliblii
              </a>
            </dd>
          </dl>
          <dl className="text-footnote-2 flex flex-col gap-2">
            <dt className="text-footnote-1">Support</dt>
            <dd>
              <a href="mailto:support@createdao.cc">support@createdao.cc</a>
            </dd>
          </dl>
        </div>
      </div>
      <div className="">
        <GridLayout className="-my-4 md:my-0">
          <GridItemSix className="flex items-center">
            <span className="text-footnote-2 mx-auto md:mx-0">
              © Copyright 2022 CreatorDAO - Public Goods for Creators
            </span>
          </GridItemSix>
          <GridItemSix className="flex flex-row mt-4 md:mt-0 md:ml-auto gap-10 items-center justify-center">
            <a
              href="https://discord.gg/creatordao"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="block h-4 w-auto" src={discord} alt="discord" />
            </a>
            <a
              href="https://twitter.com/creatordaocc"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img className="block h-4 w-auto" src={twitter} alt="twitter" />
            </a>
            <a
              href="https://github.com/creatordao-project"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img className="block h-5 w-auto" src={github} alt="github" />
            </a>
            <a
              href="https://creatordao.gitbook.io/creatordao"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img className="block h-6 w-auto" src={gitbook} alt="gitbook" />
            </a>
          </GridItemSix>
        </GridLayout>
      </div>
    </div>
  )
}

export default Footer
