import axios from 'axios';
import { useSignWallet } from '../wallet/sign';
// import { getToken } from '@/utils/auth';
// import config from '@/api/config';
  
// 创建axios实例
const service = axios.create({
//   baseURL: 'http://www.qingmu.com/api',
  withCredentials: true,
  timeout: 5000
});
 
// 请求拦截器
// service.interceptors.request.use(
//   config => {
//     config.headers['token'] = getToken() || 0;
//     return config;
//   },
//   error => {
//     // 处理请求错误
//     console.log(error); // for debug
//     return Promise.reject(error);
//   }
// );
 
// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data;
    // 未设置状态码则默认成功状态
    const code = res.errCode || 0;
    // if the custom code is not 20000, it is judged as an error.
    if (code !== 0) {
        console.log(code)
      if (code === 401) {
        useAutoSign()
      } 
      return Promise.reject(new Error(res.errMsg || 'Error'));
    } else {
      return res;
    }
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 401:
            error.message = 'Unauthorized';
             break;
        case 400:
            error.message = error.response.data.message;
            break;
        case 403:error.message = 'Forbidden'; break;
      }
    }
   
    return Promise.reject(error);
  }
);

const useAutoSign = ()=>{
    const toAuth = useSignWallet()
    toAuth()
}
 
export default service;