import Web3 from 'web3';
import moment from 'moment';
import { Checkbox } from 'antd';
import utc from 'dayjs/plugin/utc';
import { Helmet } from "react-helmet";
import { AppContext } from "@/AppContext";
import { useSignWallet } from "@/wallet/sign";
import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button, 
  useToast, 
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { getBountyDetailRequest, bountySelectedRequest, checkMintingRequest, checkReclaimRequest } from "@/request/bounty";
import ImageViewer from '@/components/ImageViewer';

moment.extend(utc)

import './index.less'

const abi = JSON.parse(process.env.REACT_APP_CREATICLES_NFT_ABI)
const contract_address = process.env.REACT_APP_CREATICLES_NFT_ADDRESS

const BOUNTY_ABI = JSON.parse(process.env.REACT_APP_BOUNTY_ABI)
const BOUNTY_ADDRESS = process.env.REACT_APP_BOUNTY_ADDRESS

const env = (process.env.NODE_ENV === 'development' || window.location.origin === 'https://www.creatordao.dev') ? 'development' : 'main'

const BountyDetail = () => {
  const toast = useToast();
  const params = useParams();
  const navigate = useNavigate();
  const toAuth = useSignWallet();
  const { state, dispatch } = useContext(AppContext)

  const [bountyDetail, setBountyDetail] = useState({})
  const [workList, setWorkList] = useState([]);
  const [isOwner, setIsOwner] = useState(false)
  const [chooseWinner, setChooseWinner] = useState(false)
  const [winnerList, setWinnerList] = useState([])
  const [loading, setLoading] = useState(false)

  const [couldClaim, setCouldClaim] = useState(false)
  const [showMintSuccess, setShowMintSuccess] = useState(false)
  const [mintHash, setMintHash] = useState('')

  useEffect(() => {
    getBountyDetail()
  }, [])

  const getBountyDetail = async () => {
    const { data } = await getBountyDetailRequest({
      id: params.id
    })
    const dateArr = moment(data.data.deadline).utc().format('YYYY-MM-DD HH:mm').split(' ')
    const datePart = dateArr[0].split('-');
    const timePart = dateArr[1]
    const newDatePart = [datePart[1], datePart[2], datePart[0]].join('/')
    data.data.deadline = newDatePart + ' ' + timePart
    setBountyDetail(data.data)
    setWorkList(data.data.work_list || []);
    setCouldClaim(moment().utc() - moment(data.data.deadline).utc() > 24 * 60 * 60 * 1000)
  }

  useEffect(() => {
    if (state.profile.address && bountyDetail.address && state.profile.address.toUpperCase() == bountyDetail.address.toUpperCase()) {
      setIsOwner(true)
    } else {
      setIsOwner(false)
    }
  }, [bountyDetail, state.profile.address])

  const goToSubmit = () => {
    const { id } = bountyDetail
    navigate(`/upload/${id}`)
  }

  const goToChooseWinner = () => {
    setChooseWinner(true)
  }

  useEffect(() => {
    let newWorkList = [...workList]
    newWorkList.map(item => {
      item.disabled = false
      if (winnerList.length >= bountyDetail.winner_number) {
        if (winnerList.indexOf(item.id) === -1) {
          item.disabled = true
        }
      }
    })
    setWorkList(newWorkList)
  }, [winnerList])


  const confirmWinner = async () => {
    setLoading(true)

    const token = await toAuth();
    if (!token) {
      return;
    }

    bountySelectedRequest({
      bounty_id: Number(params.id),
      ids: winnerList
    }, token).then(res => {
      if (res.data.code === 0) {
        const { json_ids } = res.data.data
        triggerSelected(json_ids)
      } else {
        setLoading(false)
        toast({
          position: 'top',
          title: 'Something went wrong.',
          description: res.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    }).catch(error => {
      setLoading(false)
      // console.log('error', error.message);
      toast({
        position: 'top',
        title: 'Something went wrong.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    })
  }

  const triggerSelected = async (json_ids) => {
    const winnerWorkList = workList.filter(item => winnerList.indexOf(item.id) > -1).map(item => item.address)
    let provider = state.profile.provider
    if (!provider) {
      await window.web3.currentProvider.enable();
      provider = window.web3.currentProvider
    }
    const web3 = new Web3(provider);
    const contract = new web3.eth.Contract(abi, contract_address)
    contract.methods.mintBundle(
      state.profile.address,
      bountyDetail.request_id,
      winnerList,
      [bountyDetail.hash],
      json_ids,
      winnerWorkList,
      bountyDetail.token_mint_number
    ).send({
      from: state.profile.address,
      value: 0
    }).on('transactionHash', function (hash) {
      // console.log('hash', hash);
    }).on("receipt", async (receipt) => {
      toast({
        position: 'top',
        title: 'Pick winners success',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setChooseWinner(false)
      setLoading(false)
      const { transactionHash } = receipt
      handleCheckMinting(transactionHash)
      setMintHash(transactionHash)
      setShowMintSuccess(true)
      getBountyDetail()
    }).on("error", function (error, receipt) {
      setLoading(false)
      if (error.code != 4001) {
        toast({
          position: 'top',
          title: 'Pick winners error',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    });
  }

  const handleCheckMinting = (transactionHash) => {
    checkMintingRequest({
      transaction_id: transactionHash
    }).then(res => {
      setLoading(false)
      if (res.data.code === 0) {
        getBountyDetail()
      }
    }).catch(error => {
      setLoading(false)
    })
  }

  const claim = async () => {
    setLoading(true)
    let provider = state.profile.provider
    if (!provider) {
      await window.web3.currentProvider.enable();
      provider = window.web3.currentProvider
    }
    const web3 = new Web3(provider);
    const contract = new web3.eth.Contract(BOUNTY_ABI, BOUNTY_ADDRESS)
    contract.methods.reclaimFunds(bountyDetail.request_id).send({
      from: state.profile.address,
      value: 0
    }).on('transactionHash', function (hash) {
      // console.log('hash', hash);
    }).on("receipt", async (receipt) => {
      setLoading(false)
      toast({
        position: 'top',
        title: 'Withdraw success!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      const { transactionHash } = receipt
      handleCheckClaim(transactionHash)
    }).on("error", function (error, receipt) {
      setLoading(false)
      if (error.code != 4001) {
        toast({
          position: 'top',
          title: 'Create bounty error',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        // setErrMsg(error.message)
      }
      // setProcess(PROCESS_RESET)
    });
  }

  const handleCheckClaim = async (transactionHash) => {
    checkReclaimRequest({
      transaction_id: transactionHash
    }).then(res => {
      setLoading(false)
      if (res.data.code === 0) {
        getBountyDetail()
      }
    }).catch(error => {
      setLoading(false)
    })
  }

  return (
    <div className="min-h-3/4 bounty-detail-wrapper">
      <Helmet>
        <title>CreatorDAO-Bounty Detail</title>
      </Helmet>
      <div className="bounty-detail-content max-w-3xl mx-auto py-6 px-4 lg:max-w-7xl lg:px-8">
        <div className='title-wrap'>
          <div className='title'>{bountyDetail.title}</div>
          <p className='tips'>{bountyDetail.description}</p>
        </div>
        <div className="info-wrap text-16 xs:text-12">
          <div className="info-item">
            <div className="info-item__title">Winner</div>
            <div className="info-item__value">{bountyDetail.winner_number <= 1 ? 'Single winner contest' : `${bountyDetail.winner_number} Possible winners`}</div>
          </div>
          <div className="info-item">
            <div className="info-item__title">Offer</div>
            <div className="info-item__value">{bountyDetail.offer || 0} {bountyDetail.currency || 'ETH'}</div>
          </div>
          <div className="info-item">
            <div className="info-item__title">Deadline</div>
            <div className="info-item__value">{bountyDetail.deadline} GMT</div>
          </div>
        </div>
        <div className="button-wrap text-18 xs:text-14">
          <div>{bountyDetail.work_number} Submissions Received</div>
          {
            bountyDetail.state === 10 && !isOwner && <div className="primary-button" onClick={() => goToSubmit()}>Submit a work</div>
          }
          {
            bountyDetail.state === 20 && isOwner && !chooseWinner && bountyDetail.work_number > 0 && (<>
              <div className="primary-button" onClick={() => goToChooseWinner()}>Pick the winner</div>
            </>)
          }
          {
            bountyDetail.state === 20 && isOwner && !chooseWinner && !bountyDetail.work_number && couldClaim && (<>
              <Button
                className="primary-button"
                onClick={() => claim()}
                fontWeight={700}
                color='#222'
                isLoading={loading}
                loadingText="Withdraw"
                variant='solid'
                colorScheme='customPrimary'
              >Withdraw</Button>
            </>)
          }
          {
            chooseWinner && <div className='flex'>
              <Button
                className='mr-6 secondary-button'
                style={{ fontWeight: 400 }}
                isDisabled={loading}
                _hover={{ bg: 'transparent' }}
                variant='outline'
                onClick={() => setChooseWinner(false)}
              >
                <font>Cancel</font>
              </Button>
              <Button
                className="primary-button"
                onClick={() => confirmWinner()}
                fontWeight={700}
                color='#222'
                isLoading={loading}
                loadingText="Done"
                variant='solid'
                colorScheme='customPrimary'
                isDisabled={winnerList.length < bountyDetail.winner_number}
              >Done</Button>
            </div>
          }
        </div>

        <div className="request-wrap">
          <Checkbox.Group value={winnerList} onChange={(e) => setWinnerList([...e])}>
            <div className="request-wrap-content gap-7 grid-cols-4 xs:grid-cols-1">
              {
                workList && workList.map((item, index) => {
                  return (
                    <a
                      key={`item_${index}`}
                      className={`w-full md:pb-0 md:px-0 no-pointer m-auto content-item ${item.disabled && chooseWinner ? 'content-item-disabled' : ''}`}
                      onClick={() => {
                        if (!item.disabled && !chooseWinner) {
                          navigate(`/bounty/${params.id}/proposals/${item.id}`)
                        }
                      }}
                    >
                      {
                        item.thumb_url ? <ImageViewer
                          className='content-item__img'
                          src={item.thumb_url}
                          realsrc={item.file_url}
                        ></ImageViewer> : (
                          <div className="content-item__video">
                            <video>
                              <source src={item.file_url} type="video/mp4" />
                              Your browser does not support video tags.
                            </video>
                            <div className="iconfont icon-play" onClick={() => handlePlayVideo(item.file_url)}></div>
                          </div>
                        )
                      }

                      {
                        chooseWinner && <div className='choose-cover'>
                          <Checkbox
                            value={item.id}
                            checked={winnerList.indexOf(item.id) > -1}
                            disabled={item.disabled}
                          ></Checkbox>
                        </div>
                      }

                      {
                        bountyDetail.state === 30 && item.is_winner && <div className='winner-tag'>Winner</div>
                      }
                    </a>
                  )
                })
              }
            </div>
          </Checkbox.Group>
        </div>
      </div>
      <Modal
        size="md"
        isOpen={showMintSuccess}
        closeOnOverlayClick={true}
        onClose={() => { setShowMintSuccess(false) }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Mint success</ModalHeader>
          <ModalCloseButton className="remove-focus" />
          <ModalBody className="text-16">
            <div>
              You have minted {bountyDetail.token_mint_number * bountyDetail.winner_number} Nfts. Check
              <a style={{color: '#ffc400'}} rel="noreferrer" target='_blank' href={`${env === 'development' ? 'https://rinkeby.etherscan.io' : 'https://etherscan.io'}/tx/${mintHash}`}> etherscan</a>.
            </div>
          </ModalBody>
          <ModalFooter>
            <button onClick={() => { setShowMintSuccess(false) }} className="twitter-share-button flex justify-center btn-middle bg-color-base max-w-2xl font-bold text-16 lg:text-20 px-4 h-12 lg:h-14 w-full text-color-222">
              Ok
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}
export default BountyDetail;