import {React,useEffect, useState,useContext} from "react";
import Web3 from 'web3';
import { MIN_BID,PROCESS_RESET,PROCESS_WAITING,PROCESS_DONE } from '../../config/params';
import { SIGN_KEY } from "../../config/params";
import cat1 from '../../static/image/cart3.png'
import eth from "../../static/image/eth.jpg"
import { Stepper } from 'react-vant';
import { Helmet } from "react-helmet";
import  useMexaSign  from "../../wallet/mexa/mexa";
import { ethers } from "ethers";

import { useDisclosure, useToast } from '@chakra-ui/react'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
  } from '@chakra-ui/react'
import "./queue.css"
import { accountOrderList,checkOrderCanceled,checkOrderBidUpdated,checkOrderSettled } from "../../request/order";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
  } from '@chakra-ui/react'
  import { AppContext } from "../../AppContext";

 
function Queue(){
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {state,dispatch} = useContext(AppContext)
  const toast = useToast()
  const [queueList,setQueuedList] = useState([])
  const [bid,setBid] = useState(0.1)
  const [commissionId,setCommissionId] = useState(0)
  const [currentKey,setCurrentKey] = useState(null)

  const {toSettleCommission} = useMexaSign()

  useEffect(()=>{
    getMyOrderList()
  },[state.profile.address])

  const getMyOrderList = ()=>{
    accountOrderList(state.profile.address).then(res=>{
      setQueuedList(res.data.data)
    })
  }

  async function toCancel(commissionId,key){
    if (!commissionId){
      toast({
        position: 'top',
        title: 'Cancel commission error.',
        description: "we missing some params,please reload page.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      return
    }
    let provider = state.profile.provider
    if (!provider){
      await window.web3.currentProvider.enable();
      provider = window.web3.currentProvider
    }
    
    const abi = JSON.parse(process.env.REACT_APP_ABI)
    const contract_address = process.env.REACT_APP_ADDRESS    
    const  web3 = new Web3(provider);
    const contract =  new web3.eth.Contract(abi, contract_address)
    contract.methods
    .rescindCommission(commissionId).send({
      from: state.profile.address,
      // value:parseFloat(bid)*10**18
    })
    .on('transactionHash', function(hash){
    })
      .on("receipt", function (receipt) {
        toast({
          position: 'top',
          title: 'Cancel commission success',
          // description: error.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        let list = [...queueList]
        list[key].state = 50
        setQueuedList(list)
        checkOrderCanceled({transaction_id:receipt.transactionHash})
        // getMyOrderList()
      })
      .on("error", function (error, receipt) {
        if (error.code != 4001) {
          toast({
            position: 'top',
            title: 'Cancel commission error.',
            description: error.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        }
  
      });
    }

    const toOpenModal =async (commissio_id,key)=>{
      setCurrentKey(key)
      setCommissionId(commissio_id)
      onOpen()
    }

    const submitBid = async()=>{
      if (!commissionId){
        toast({
          position: 'top',
          title: 'Increase commission bid error.',
          description: "we missing some params,please reload page.",
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        return
      }

      let provider = state.profile.provider
      if (!provider){
        await window.web3.currentProvider.enable();
        provider = window.web3.currentProvider
      }
      
      const abi = JSON.parse(process.env.REACT_APP_ABI)
      const contract_address = process.env.REACT_APP_ADDRESS
      const  web3 = new Web3(provider);
      const contract =  new web3.eth.Contract(abi, contract_address)
      contract.methods
      .increaseCommissionBid(commissionId).send({
        from: state.profile.address,
        value:ethers.utils.parseEther(bid.toString())
      })
      .on('transactionHash', function(hash){
        onClose()
    })
      .on("receipt", function (receipt) {
        toast({
          position: 'top',
          title: 'Increase commission bid success',
          // description: error.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        
        let list = [...queueList]
        list[currentKey].price = parseFloat(list[currentKey].price)+bid
        setQueuedList(list)

        checkOrderBidUpdated({transaction_id:receipt.transactionHash})
        // getMyOrderList()
      })
      .on("error", function (error, receipt) {
        if (error.code != 4001) {
          toast({
            position: 'top',
            title: 'Increase commission bid error.',
            description: error.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        }
  
      });
    }

    const toFinishCommission = async (commissionId) => {
      const res = await toSettleCommission(commissionId)
      if (res == "error" || res == ""){
        return;
      }
    
      const ethersProvider = state.profile.ethersProvider
      try{
       ethersProvider.once(res, (transaction) => {
           // Emitted when the transaction has been mined
           toast({
            position: 'top',
            title: 'Transaction confirmed on chain success',
            // description: error.message,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
    
          let list = [...queueList]
          list = list.map((v,k)=>{
            if (v.commission_id ==commissionId){
              v.state = 30
            }
            return v
          })
    
          setQueuedList(list)
          checkOrderSettled({transaction_id:res})
         })
        
       }catch(error) {
         console.log(error);
         toast({
           position: 'top',
           title: 'Transaction error',
           description: error.message,
           status: 'error',
           duration: 3000,
           isClosable: true,
         })
    
       };
      return;
    };

    return (
        <div className="min-h-3/4 mx-auto py-10 px-4 md:py-18 md:px-12 ">
            <Helmet>
                <title>CreatorDAO-My Commissions</title>
            </Helmet>
        <h1 className=" m-auto mb-8 ml-4 font-sans text-size-32 font-color-222 font-extrabold ">
            Your commissions
            </h1>

            <TableContainer>
  <Table variant='simple'>
    {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
    <Thead>
      <Tr>
        <Th>Reference Picture</Th>
        <Th>Current Picture</Th>
        <Th>Notes</Th>
        <Th>Token Id</Th>
        <Th>Date</Th>
        <Th >Status</Th>
        <Th >Payment amount</Th>
        <Th ></Th>
      </Tr>
    </Thead>
    <Tbody>
      {
        queueList.map((item,key)=>(
          <Tr key={key}>
            <Td>
              {
                item.pre_picture?
                <div className="w-120">
                    <img src={item.pre_picture} className="w-full" />
                </div>
                :""
              }
            </Td>
            <Td>
              {
                item.current_picture?
                <div className="w-120">
                  <img src={item.current_picture} className="w-full" />
                </div>
                :""
              }
            </Td>
            <Td>{item.notes}</Td>
            <Td>#{item.token_id}</Td>
            <Td>{item.create_at}</Td>
            <Td >
                <div className="flex flex-col justify-center ">
                  {
                    item.state===0?
                    <button className="w-28 mr-2 bg-yellow-100 text-yellow-700 py-0.5 px-3 rounded-3xl">Waiting</button>
                    :item.state===10?
                    <button className="w-28 mr-2 bg-yellow-100 text-yellow-700 py-0.5 px-3 rounded-3xl">Queueing</button>
                    :item.state===20?
                    <button className="w-28 mr-2 bg-green-100 text-green-700 py-0.5 px-3 rounded-3xl">Processing</button>
                    :item.state===30?
                    <button className="w-28 mr-2 bg-blue-100 text-blue-700 py-0.5 px-3 rounded-3xl">Finished</button>
                    :item.state===40?
                    <button className="w-28 mr-2 bg-red-200 text-red-700 py-0.5 px-3 rounded-3xl">Question</button>
                    :item.state===50?
                    <button className="w-28 mr-2 bg-red-200 text-red-700 py-0.5 px-3 rounded-3xl">Canceled</button>
                    :
                    ""
                  }
                </div>
            </Td>
            <Td ><div className="flex items-center">{item.price} <img src={eth} className="w-3 ml-2" /></div> </Td>
            <Td >
                <Menu>
                    <MenuButton>
                        <div className="text-size-32 font-bold">
                            ···
                        </div>
                    </MenuButton>
                    <MenuList>
                    <MenuItem onClick={toOpenModal.bind(this,item.commission_id,key)}><span className="text-size-16  font-color-000">Change commission</span> </MenuItem>
                    {
                      item.state===10?
                        <MenuItem onClick={toCancel.bind(this,item.commission_id,key)}> <span className="text-size-16  font-color-000">Cancel commission</span></MenuItem>
                      :""
                    }
                     {/* {
                        item.state===20?
                        <MenuItem onClick={toFinishCommission.bind(this,item.commission_id)}><span className="text-size-16  font-color-000">Finish commission</span> </MenuItem>
                        :""
                      } */}
                    </MenuList>
                </Menu>
            </Td>
          </Tr>
        ))
      }
      
      
    </Tbody>
    {/* <Tfoot>
      <Tr>
        <Th>To convert</Th>
        <Th>into</Th>
        <Th >multiply by</Th>
        <Th >multiply by</Th>
        <Th >multiply by</Th>
      </Tr>
    </Tfoot> */}
  </Table>
</TableContainer>
<Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Increase Commission Bid</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          Increase Bid (ETH):&nbsp;
            <Stepper className='my-3 ml-3' min={0.1} step="0.1" onChange={(v)=>setBid(v)} value={bid}  disableInput />
            
          </ModalBody>

          <ModalFooter>
            <Button  mr={3} onClick={onClose}>
              Close
            </Button>
            {

            }
            <Button onClick={submitBid} colorScheme='blue'>Submit</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
        </div>
        // <div className="Queue min-h-1/2 xl:min-h-3/4  my-10">
        //      <h1 className=" text-center m-auto h-12 text-gray-700  md:mt-6 font-sans text-xl lg:text-2xl font-mono font-extrabold ">
        //     queue
        //     </h1>
        //     <OrderList list={queueList} />
        // </div>
    )
}

export default Queue;