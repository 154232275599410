import makeBlockie from 'ethereum-blockies-base64';


export const truncateAddress = (address) => {
    if (!address) return "No Account";
    const match = address.match(
      /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/
    );
    if (!match) return address;
    return `${match[1]}…${match[2]}`;
  };
  
  export const toHex = (num) => {
    const val = Number(num);
    return "0x" + val.toString(16);
  };

  export const getAvatar = (avatar,address) => {
    if (avatar){
      return avatar
    }

    return address?makeBlockie(address):""
  }
  
  export const getUserName = (username,address) =>{
    if (username){
      return username
    }

    return address?truncateAddress(address):""
  }