import {React,useEffect, useState,useContext} from "react";
import { PROCESS_RESET,PROCESS_WAITING } from '../../config/params';
import { Uploader } from 'react-vant';
import { useSignWallet } from "../../wallet/sign";
import  useMexaSign  from "../../wallet/mexa/mexa";

import eth from "../../static/image/eth.jpg"
import { Helmet } from "react-helmet";
import Web3 from 'web3';

import { useDisclosure, useToast } from '@chakra-ui/react'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton
} from '@chakra-ui/react'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import "./myWork.css"
import { postPic,myWorkOrderList,checkOrderAccepted } from "../../request/order";
import { acceptOrder } from "../../request/order";
import { AppContext } from "../../AppContext";

const abi = JSON.parse(process.env.REACT_APP_ABI)
const contract_address = process.env.REACT_APP_ADDRESS  

function MyWork(){
  const {state,dispatch} = useContext(AppContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isOpenDialog,setIsOpenDialog] = useState(false)
  const toast = useToast()
  const [queueList,setQueuedList] = useState([])
  const [beforeRead, setBeforeRead] = useState([])
  const [id,setId] = useState(null)
  const [process,setProcess] = useState(PROCESS_RESET)
  const {toProcessCommission} = useMexaSign()

  useEffect(()=>{
    getMyOrderList()
  },[state.profile.address])

  const getMyOrderList = ()=>{
    myWorkOrderList(state.profile.address).then(res=>{
      setQueuedList(res.data.data)
  })
  }

  const toAuth = useSignWallet()
  async function toSubmit(){
    const token = await toAuth()
    if (!token){
      return;
    }

    if (!id){
      toast({
        position: 'top',
        title: 'Upload picture error.',
        description: "miss some params,please reload page",
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      // connectWallet()
      return;
    }
    setProcess(PROCESS_WAITING)
    
    if (beforeRead.length<=0){
      toast({
        position: 'top',
        title: 'Upload picture error.',
        description: "please upload picture",
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      setProcess(PROCESS_RESET)
      return;
    }

   let avatarFile = beforeRead[0].file
   
    var data = new FormData()
    data.append("picture_file",avatarFile)
    data.append("id",id)
    
    postPic(data,token).then(res=>{
      if (res.data.code != 0) {
        toast({
          position: 'top',
          title: 'Upload picture error.',
          description: res.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }else{
        toast({
          position: 'top',
          title: 'Upload picture success',
          // description: error.message,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        onClose()
        getMyOrderList()
      }
    }).catch(error=>{
      toast({
        position: 'top',
        title: 'Upload picture error.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }).finally(()=>{
      setProcess(PROCESS_RESET)
    })
  }

  const asyncBeforeRead = async (file)=>{
    const files = Array.isArray(file)?file:[file]
    return new Promise((resolve)=>{
        const passFiles = files.filter((f)=>{
            if (f.type !== 'image/png' && f.type !== 'image/jpeg'){
              toast({
                position: 'top',
                title: 'Upload picture error.',
                description: `${f.name}error,please upload png/jpg image`,
                status: 'error',
                duration: 3000,
                isClosable: true,
              })

                return false;
            }
            return true;
        })
        resolve(passFiles)
    })
}


const toOpenModel = (id)=>{
  setId(id)
  onOpen()
}
const toOpenDialogModel = (id)=>{
  setId(id)
  setIsOpenDialog(true)
}
const toCloseDialogModel = ()=>{
  setId(null)
  setIsOpenDialog(false)
}

//采用元交易确认，此方法暂时废弃
const toAcceptCommission1 = async (commissionId)=>{
  if (!commissionId){
    toast({
      position: 'top',
      title: 'Accept commission error.',
      description: "we missing some params,please reload page.",
      status: 'error',
      duration: 3000,
      isClosable: true,
    })
    return
  }

  let provider = state.profile.provider
  if (!provider){
    await window.web3.currentProvider.enable();
    provider = window.web3.currentProvider
  }
    
  const  web3 = new Web3(provider);
  const contract =  new web3.eth.Contract(abi, contract_address)
  contract.methods
  .processCommissions([commissionId]).send({
    from: state.profile.address,
    // value:parseFloat(bid)*10**18
  })
  .on('transactionHash', function(hash){
  })
    .on("receipt", function (receipt) {
      toast({
        position: 'top',
        title: 'Accept commission success',
        // description: error.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
      })

      let list = [...queueList]
      list = list.map((v,k)=>{
        if (v.commission_id ==commissionId){
          v.state = 20
        }
        return v
      })

      setQueuedList(list)
      checkOrderAccepted({transaction_id:receipt.transactionHash})
      // getMyOrderList()
    })
    .on("error", function (error, receipt) {
      console.log(error)
      if (error.code != 4001) {
        toast({
          position: 'top',
          title: 'Accept commission error.',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }

    });
}

const toAcceptOrder = async ()=>{
  const token = await toAuth()
  if (!token){
    return;
  }

  acceptOrder({id: id.toString()},token).then(res=>{
    if (res.data.code != 0) {
      toast({
        position: 'top',
        title: 'Accept commission error.',
        description: res.data.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }else{
      toast({
        position: 'top',
        title: 'Accept commission success',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      toCloseDialogModel()
      getMyOrderList()
    }
  }).catch(error=>{
    toast({
      position: 'top',
      title: 'Accept commission error.',
      description: error.message,
      status: 'error',
      duration: 3000,
      isClosable: true,
    })
  })
}

const toAcceptCommission = async (commissionId) => {
  const res = await toProcessCommission(commissionId)
  if (res == "error" || res == ""){
    return;
  }

  const ethersProvider = state.profile.ethersProvider
  try{
   ethersProvider.once(res, (transaction) => {
       // Emitted when the transaction has been mined
       toast({
        position: 'top',
        title: 'Transaction confirmed on chain success',
        // description: error.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
      })

      let list = [...queueList]
      list = list.map((v,k)=>{
        if (v.commission_id ==commissionId){
          v.state = 20
        }
        return v
      })

      setQueuedList(list)
      checkOrderAccepted({transaction_id:res})
     })
    
   }catch(error) {
     console.log(error);
     toast({
       position: 'top',
       title: 'Transaction error',
       description: error.message,
       status: 'error',
       duration: 3000,
       isClosable: true,
     })

   };
  return;
};

  return (
        <div className="min-h-3/4 mx-auto py-10 px-4 md:py-18 md:px-12 ">
           <Helmet>
                <title>CreatorDAO-My Work</title>
            </Helmet>
        <h1 className=" m-auto mb-8 ml-4 font-sans text-size-32 font-color-222 font-extrabold ">
            My Work
            </h1>

            <TableContainer>
  <Table size='sm' variant='striped'>
    {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
    <Thead>
      <Tr>
        <Th>Reference Picture</Th>
        <Th>Current Picture</Th>
        <Th>Notes</Th>
        <Th>Token Id</Th>
        <Th>Twitter</Th>
        <Th>Email</Th>
        <Th>Date</Th>
        <Th >Status</Th>
        <Th >Shop - id</Th>
        <Th >Payment amount</Th>
        <Th ></Th>
      </Tr>
    </Thead>
    <Tbody>
      {
        queueList.map((item,key)=>(
          <Tr key={key}>
            <Td>
              {
                item.pre_picture?
                <div className="w-120">
                    <img src={item.pre_picture} className="w-full" />
                </div>
                :""
              }
               
            </Td>
            <Td>
              {
                item.current_picture?
                <div className="w-120">
                  <img src={item.current_picture} className="w-full" />
                </div>
                :""
              }
               
            </Td>
            <Td>{item.notes}</Td>
            <Td>#{item.token_id}</Td>
            <Td>{item.twitter}</Td>
            <Td>{item.email}</Td>
            <Td width={100}>{item.create_at}</Td>
            <Td >
                <div className="flex flex-col justify-center ">
                  {
                    item.state===0?
                    <button className="w-28 mr-2 bg-yellow-100 text-yellow-700 py-0.5 px-3 rounded-3xl">Waiting</button>
                    :item.state===10?
                    <button className="w-28 mr-2 bg-yellow-100 text-yellow-700 py-0.5 px-3 rounded-3xl">Queueing</button>
                    :item.state===20?
                    <button className="w-28 mr-2 bg-green-100 text-green-700 py-0.5 px-3 rounded-3xl">Processing</button>
                    :item.state===30?
                    <button className="w-28 mr-2 bg-blue-100 text-blue-700 py-0.5 px-3 rounded-3xl">Finished</button>
                    :item.state===40?
                    <button className="w-28 mr-2 bg-red-200 text-red-700 py-0.5 px-3 rounded-3xl">Question</button>
                    :item.state===50?
                    <button className="w-28 mr-2 bg-red-200 text-red-700 py-0.5 px-3 rounded-3xl">Canceled</button>
                    :
                    ""
                  }
                </div>
            </Td>
            <Td >{item.shop_name} - {item.shop_id}</Td>
            <Td ><div className="flex items-center">{item.price} <img src={eth} className="w-3 ml-2" /></div> </Td>
            <Td >
                <Menu>
                    <MenuButton>
                        <div className="text-size-32 font-bold">
                            ···
                        </div>
                    </MenuButton>
                    <MenuList>
                      {
                        item.state===10?
                          <MenuItem onClick={toAcceptCommission.bind(this,item.commission_id)}><span className="text-size-16  font-color-000">Accept commission</span> </MenuItem>
                        :""
                      }
                      {
                        item.state===20?
                    <MenuItem onClick={toOpenModel.bind(this,item.id)}><span className="text-size-16  font-color-000">Upload picture</span> </MenuItem>
                        :""
                      }
                    {/* <MenuItem onClick={toCancel.bind(this,item.commission_id)}> <span className="text-size-16  text-red-600">Cancel commission</span></MenuItem> */}
                    </MenuList>
                </Menu>
            </Td>
          </Tr>
        ))
      }
      
      
    </Tbody>
    {/* <Tfoot>
      <Tr>
        <Th>To convert</Th>
        <Th>into</Th>
        <Th >multiply by</Th>
        <Th >multiply by</Th>
        <Th >multiply by</Th>
      </Tr>
    </Tfoot> */}
  </Table>
</TableContainer>
<Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload completed picture</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="m-auto display-table">
            <Uploader
              maxCount={1}
             
              value={beforeRead}
              onChange={setBeforeRead}
              beforeRead={asyncBeforeRead}
            />
            </div>
           
                           
          </ModalBody>

          <ModalFooter>
            <Button  mr={3} onClick={onClose}>
              Close
            </Button>
            {
              process == PROCESS_RESET?
              <Button onClick={toSubmit} colorScheme='blue'>Submit</Button>
              :
              <Button  colorScheme='gray'>uploading...</Button>
            }
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        motionPreset='slideInBottom'
      
        onClose={toCloseDialogModel}
        isOpen={isOpenDialog}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Accept commission?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to accept this commission? 
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={toCloseDialogModel}>
              No
            </Button>
            <Button onClick={toAcceptOrder} colorScheme='red' ml={3}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
        </div>
        // <div className="Queue min-h-1/2 xl:min-h-3/4  my-10">
        //      <h1 className=" text-center m-auto h-12 text-gray-700  md:mt-6 font-sans text-xl lg:text-2xl font-mono font-extrabold ">
        //     queue
        //     </h1>
        //     <OrderList list={queueList} />
        // </div>
    )
}

export default MyWork;