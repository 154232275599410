import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Textarea,
  Button,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import Web3 from 'web3';
import moment from 'moment';
import { ethers } from "ethers";
import BigNumber from "bignumber.js";
import { Helmet } from "react-helmet";
import { DatePicker, Select } from 'antd';
import { AppContext } from "@/AppContext";
import { useNavigate } from 'react-router-dom';
import { getSpaceTagsResuest } from '@/request/data';
import { useNewConnectWallet } from "@/wallet/newConnect"
import React, { useState, useContext, useCallback, useEffect } from 'react';
import { bountyCreateRequest, bountyCheckCreating } from "@/request/bounty";
import IconCalendar from '@/static/image/icon-calendar.png';

import "./index.less"

const { Option } = Select;

const BOUNTY_ABI = JSON.parse(process.env.REACT_APP_BOUNTY_ABI)
const BOUNTY_ADDRESS = process.env.REACT_APP_BOUNTY_ADDRESS

const DAI_ABI = JSON.parse(process.env.REACT_APP_DAI_ABI)
const DAI_ADDRESS = process.env.REACT_APP_DAI_ADDRESS

const USDT_ABI = JSON.parse(process.env.REACT_APP_USDT_ABI)
const USDT_ADDRESS = process.env.REACT_APP_USDT_ADDRESS

const USDC_ABI = JSON.parse(process.env.REACT_APP_USDC_ABI)
const USDC_ADDRESS = process.env.REACT_APP_USDC_ADDRESS

const tokenAddress = {
  ETH: {
    address: '0x0000000000000000000000000000000000000000',
    abi: '',
    decimals: 18
  },
  DAI: {
    address: DAI_ADDRESS,
    abi: DAI_ABI,
    decimals: 18
  },
  USDT: {
    address: USDT_ADDRESS,
    abi: USDT_ABI,
    decimals: 6
  },
  USDC: {
    address: USDC_ADDRESS,
    abi: USDC_ABI,
    decimals: 6
  }
}

const env = (process.env.NODE_ENV === 'development' || window.location.origin === 'https://www.creatordao.dev') ? 'development' : 'main'

let web3 = null

const BountyCreate = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [connectWallet, newDisconnect] = useNewConnectWallet()
  const { state, dispatch } = useContext(AppContext)
  const [isConnect, setIsConnect] = useState(false);
  const [loading, setLoading] = useState(false)
  const [approveLoading, setApproveLoading] = useState(false);
  const [requestInfo, setRequestInfo] = useState({
    winner_number: 1,
    token_mint_number: 1
  })
  const [endMomentTime, setEndMomentTime] = useState(moment().add(7, 'days'))
  const [titleError, setTitleError] = useState(false)
  const [descriptionError, setDescriptionError] = useState(false)
  const [winnerError, setWinnerError] = useState(false)
  const [mintNumberError, setMintNumberError] = useState(false)
  const [rewardError, setRewardError] = useState(false)
  const [currentToken, setCurrentToken] = useState('ETH')

  const [showApprove, setShowApprove] = useState(false)
  const [approveDone, setApproveDone] = useState(false)

  const [pay, setPay] = useState('')
  const [payToWinner, setPayToWinner] = useState('')
  const [winnerList, setWinnerList] = useState([])
  const [chainId, setChainId] = useState(null)
  const [showWrongChain, setShowWrongChain] = useState(false)

  const [tagList, setTagList] = useState([])
  const [tag, setTag] = useState([])

  useEffect(() => {
    getTagList()
  }, [])

  useEffect(() => {
    let provider = state.profile.provider
    const initWeb3 = async () => {
      if (!provider) {
        await window.web3.currentProvider.enable();
        provider = window.web3.currentProvider
      }
      web3 = new Web3(provider);
      provider.on("chainChanged", handleChainChanged);
      const currentChainId = await web3.eth.getChainId()
      setChainId(currentChainId)
    }

    const handleChainChanged = (_hexChainId) => {
      setChainId(web3.utils.hexToNumber(_hexChainId))
    }

    initWeb3()

    return () => {
      if (provider.removeListener) {
        provider.removeListener("chainChanged", handleChainChanged);
      }
    }
  }, [state.profile.provider])

  useEffect(() => {
    if (state.profile.address && chainId) {
      if ((env === 'development' && chainId !== 4) || (env !== 'development' && chainId !== 1)) {
        setShowWrongChain(true)
      } else {
        setShowWrongChain(false)
      }
    }

  }, [chainId, state.profile.address])

  useEffect(() => {
    if (state.profile.address) {
      setIsConnect(true)
    } else {
      setIsConnect(false)
    }
  }, [state.profile.address])

  useEffect(() => {
    let { offer, winner_number } = requestInfo
    if (offer && currentToken && winner_number) {
      offer = BigNumber(offer)
      const newOffer = offer.multipliedBy(1.025);
      const payToWinner = offer.dividedBy(winner_number)
      setPay(newOffer)
      setPayToWinner(Number(payToWinner.toNumber().toFixed(5)))
      setWinnerList(new Array(winner_number).fill(1))
    } else {
      setPay('')
      setPayToWinner(0)
    }
  }, [requestInfo, currentToken])

  const handleChangeInfo = useCallback((value, type) => {
    let newInfo = { ...requestInfo };
    newInfo[type] = value;
    setRequestInfo(newInfo)
    console.log('newInfo', newInfo);
    if (type === 'title') {
      setTitleError(!newInfo[type].trim())
    }
    if (type === 'description') {
      setDescriptionError(!newInfo[type].trim())
    }
    if (type === 'winner_number') {
      setWinnerError(!newInfo[type])
    }
    if (type === 'token_mint_number') {
      setMintNumberError(!newInfo[type])
    }
    if (type === 'offer') {
      setRewardError(!newInfo[type])
    }
  }, [requestInfo])

  const getTagList = async () => {
    const { data } = await getSpaceTagsResuest({
      search: '',
      order: 'asc'
    })
    if (data && data.code === 0) {
      setTagList(data.data || [])
      setTag(data.data.length > 0 ? [data.data[0].symbol] : [])
    }
  }

  const handleChangeDeadline = (dateMoment, dateString) => {
    setEndMomentTime(dateMoment)
  }

  const handleSubmit = async () => {
    const { title, description, offer, winner_number, token_mint_number } = requestInfo
    const startTimeStamp = moment();
    const endTimeStamp = endMomentTime;
    const contractDuration = endTimeStamp.diff(startTimeStamp, 'seconds')
    if (contractDuration < 300) {
      toast({
        position: 'top',
        title: '',
        description: 'Please choose the appropriate deadline. You can choose at least five minutes from now.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      return
    }

    setLoading(true)

    let json = {
      title: title,
      description: description,
      offer: offer,
      winner_number: winner_number,
      token_mint_number: Number(token_mint_number),
      deadline: endTimeStamp.unix(),
      address: state.profile.address,
      currency: currentToken,
      space_tag: tag.length > 0 ? tag.join(',') : ''
    }

    bountyCreateRequest(json).then(res => {
      if (res.data.code === 0) {
        triggerCreateContract(res.data.data.hash, contractDuration)
      } else {
        setLoading(false)
        toast({
          position: 'top',
          title: 'Something went wrong.',
          description: res.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    }).catch(error => {
      setLoading(false)
      toast({
        position: 'top',
        title: 'Something went wrong.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })

    })
  }

  const triggerCreateContract = async (hash, duration) => {
    const { winner_number, token_mint_number } = requestInfo
    const contract = new web3.eth.Contract(BOUNTY_ABI, BOUNTY_ADDRESS)

    const number = ethers.utils.parseEther(pay.toString())

    contract.methods.createRequest(
      hash,
      Number(winner_number),
      Number(duration),
      token_mint_number,
      tokenAddress[currentToken]['address'],
      number
    ).send({
      from: state.profile.address,
      value: currentToken === 'ETH' ? number : 0
    }).on('transactionHash', function (hash) {
      // console.log('hash', hash);
    }).on("receipt", async (receipt) => {
      const { transactionHash } = receipt
      handleCheckCreating(transactionHash)
    }).on("error", function (error, receipt) {
      setLoading(false)
      if (error.code != 4001) {
        toast({
          position: 'top',
          title: 'Create bounty error',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        // setErrMsg(error.message)
      }
      // setProcess(PROCESS_RESET)
    });
  }

  const handleCheckCreating = (transactionHash) => {
    bountyCheckCreating({
      transaction_id: transactionHash
    }).then(res => {
      setLoading(false)
      if (res.data.code === 0) {
        toast({
          position: 'top',
          title: 'Create bounty success',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        navigate('/bounty')
      } else {
        toast({
          position: 'top',
          title: 'Something went wrong.',
          description: res.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    }).catch(error => {
      setLoading(false)
      toast({
        position: 'top',
        title: 'Something went wrong.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })

    })

  }

  const approveToken = async () => {
    setApproveLoading(true)
    const { offer, winner_number } = requestInfo
    const newOffer = Number(offer) * winner_number;
    const contract = new web3.eth.Contract(tokenAddress[currentToken]['abi'], tokenAddress[currentToken]['address'])
    contract.methods.approve(
      BOUNTY_ADDRESS,
      BigInt(parseFloat(newOffer > 999999 ? newOffer : 999999) * 10 ** tokenAddress[currentToken]['decimals'])
    ).send({
      from: state.profile.address,
      value: 0
    }).on('transactionHash', function (hash) {
      // console.log('dai hash', hash);
    }).on("receipt", async (receipt) => {
      const { transactionHash } = receipt
      if (transactionHash) {
        setApproveLoading(false)
        setApproveDone(true)
      }
    }).on("error", function (error, receipt) {
      setApproveLoading(false)
      if (error.code != 4001) {
        toast({
          position: 'top',
          title: 'Approve error',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
      return false
    });
  }

  useEffect(() => {
    const checkAllowance = async () => {
      const { offer } = requestInfo

      const contract = new web3.eth.Contract(tokenAddress[currentToken]['abi'], tokenAddress[currentToken]['address'])
      try {
        const res = await contract.methods.allowance(state.profile.address, BOUNTY_ADDRESS).call()
        if (res < parseFloat(offer) * 10 ** tokenAddress[currentToken]['decimals']) {
          setShowApprove(true)
        } else {
          setShowApprove(false)
        }
      } catch (e) {
        setShowApprove(true)
      }
    }
    if (currentToken !== 'ETH') {
      checkAllowance()
    } else {
      setShowApprove(false)
    }
  }, [currentToken, requestInfo, state.profile.address])

  return (
    <div className="min-h-3/4 bounty-create-wrapper">
      <Helmet>
        <title>CreatorDAO-Bounty Create</title>
      </Helmet>
      <div className='container'>
        <div className='title-wrap'>
          <span className='title'>Create a request</span>
        </div>
        {
          !isConnect && <div className='default'>
            <div className='default-button' onClick={connectWallet}>CONNECT WALLET</div>
          </div>
        }
        {
          isConnect && <>
            {/* Name */}
            <FormControl isRequired isInvalid={titleError}>
              <FormLabel htmlFor='title'><font>Name</font></FormLabel>
              <Input id='title' type='text' placeholder='' value={requestInfo.title || ''} onChange={(e) => handleChangeInfo(e.target.value, 'title')} />
              {titleError && <FormErrorMessage><font>Name is required.</font></FormErrorMessage>}
            </FormControl>

            {/* Description */}
            <FormControl isRequired isInvalid={descriptionError}>
              <FormLabel htmlFor='description'><font>Description</font></FormLabel>
              <Textarea id='description' type='text' placeholder='Please describe your commission' value={requestInfo.description || ''} onChange={(e) => handleChangeInfo(e.target.value, 'description')} />
              {descriptionError && <FormErrorMessage><font>Description is required.</font></FormErrorMessage>}
            </FormControl>

            {/* Tag */}
            {
              tagList.length > 0 && (
                <FormControl isRequired isInvalid={winnerError}>
                  <FormLabel htmlFor='winner'><font>Tag</font></FormLabel>
                  <Select
                    mode="tags"
                    className='tag-select'
                    placeholder='Please select tags'
                    size='large'
                    defaultValue={[]}
                    style={{ width: '100%' }}
                    onChange={(e) => {
                      setTag(e)
                    }}
                  >
                    {
                      tagList.map((item, index) => {
                        return <Option key={`item_${index}`} value={item.symbol}>{item.name}</Option>
                      })
                    }
                  </Select>
                </FormControl>
              )
            }


            {/* Winners */}
            <FormControl isRequired isInvalid={winnerError}>
              <FormLabel htmlFor='winner'><font>Winners</font></FormLabel>
              <NumberInput defaultValue={1} min={1} max={10} step={1} onChange={(valueString) => handleChangeInfo(Number(valueString), 'winner_number')}>
                <NumberInputField placeholder='Number of the winners' />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              {winnerError && <FormErrorMessage><font>This field is required.</font></FormErrorMessage>}
            </FormControl>

            {/* Deadline */}
            <FormControl>
              <FormLabel htmlFor=''><font>Deadline</font></FormLabel>
              <DatePicker
                allowClear={false}
                showToday={false}
                className='deadline-input'
                size="large"
                onChange={handleChangeDeadline}
                suffixIcon={<img className='icon' src={IconCalendar}></img>}
                showTime={{ format: 'HH:mm' }}
                defaultValue={moment().add(7, 'days')}
                format="YYYY-MM-DD HH:mm"
                disabledDate={(current) => {
                  return current && current < moment().startOf('day');
                }}
              />
            </FormControl>

            {/* number of NFTs */}
            <FormControl isRequired isInvalid={mintNumberError}>
              <FormLabel htmlFor=''><font>Number Of NFTs To Mint</font></FormLabel>
              <NumberInput
                defaultValue={1}
                className='token-num-wrap'
                min={1}
                step={1}
                onChange={(valueString) => handleChangeInfo(valueString, 'token_mint_number')}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              {mintNumberError && <FormErrorMessage><font>This field is required.</font></FormErrorMessage>}
              {/* <FormHelperText>You can choose to mint *fewer* NFTs when your contest is over but you cannot mint more.</FormHelperText> */}
            </FormControl>

            {/* Select token */}
            <FormControl>
              <FormLabel htmlFor=''><font>Select token</font></FormLabel>
              <Select
                className='token-select'
                placeholder=''
                size='large'
                defaultValue='ETH'
                onChange={(e) => {
                  setCurrentToken(e)
                }}
              >
                <Option value='ETH'>ETH</Option>
                <Option value='DAI'>DAI</Option>
                <Option value='USDC'>USDC</Option>
                <Option value='USDT'>USDT</Option>
              </Select>
            </FormControl>

            {/* Reward */}
            <FormControl isRequired isInvalid={rewardError}>
              <FormLabel htmlFor=''><font>Reward</font></FormLabel>
              <NumberInput
                className='reward-input-wrap'
                min={0.0001}
                step={0.0001}
                onChange={(valueString) => handleChangeInfo(valueString, 'offer')}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              {rewardError && <FormErrorMessage><font>This field is required.</font></FormErrorMessage>}
              <FormHelperText>
                <span style={{ color: '#222', fontWeight: 600 }}>
                  {
                    pay && `You will pay ${pay} ${currentToken} due to the 2.5% commission fee. `
                  }
                </span>
              </FormHelperText>
              {
                !!payToWinner && <div className='table-wrap'>
                  <TableContainer>
                    <Table size='sm'>
                      <Thead>
                        <Tr>
                          <Th style={{ color: '#222' }}>Winner No.</Th>
                          <Th style={{ color: '#222' }}>Number Of NFTs To Mint</Th>
                          <Th isNumeric style={{ color: '#222' }}>Pay To Winner</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {
                          winnerList.map((item, index) => {
                            return (
                              <Tr key={`tr_${index}`}>
                                <Td>{index + 1}</Td>
                                <Td>{requestInfo.token_mint_number}</Td>
                                <Td isNumeric>{payToWinner} {currentToken}</Td>
                              </Tr>
                            )
                          })
                        }
                      </Tbody>
                    </Table>
                  </TableContainer>
                </div>
              }


            </FormControl>

            <div className='button-group'>
              {
                showApprove && !approveDone && <Button
                  className='button-item primary-button'
                  fontWeight={700}
                  color='#222'
                  marginRight='24px'
                  isLoading={approveLoading}
                  loadingText="APPROVE"
                  variant='solid'
                  colorScheme='customPrimary'
                  isDisabled={approveDone}
                  onClick={() => approveToken()}
                >
                  <font>APPROVE</font>
                </Button>
              }

              <Button
                className='button-item primary-button'
                fontWeight={700}
                color='#222'
                marginRight='24px'
                isLoading={loading}
                loadingText="CONTINUE"
                variant='solid'
                colorScheme='customPrimary'
                isDisabled={!requestInfo.title || !requestInfo.description || !requestInfo.winner_number || !requestInfo.offer || (showApprove && !approveDone)}
                onClick={() => handleSubmit()}
              >
                <font>CONTINUE</font>
              </Button>
              <Button
                className='button-item secondary-button'
                style={{ fontWeight: 400 }}
                isDisabled={loading}
                _hover={{ bg: 'transparent' }}
                variant='outline'
                onClick={() => {
                  if (state.profile.address) {
                    navigate(`/bounty`);
                  } else {
                    navigate(-1)
                  }
                }}
              >
                <font>Cancel</font></Button>
            </div>
          </>
        }

      </div>
      <Modal
        size="md"
        isOpen={showWrongChain}
        closeOnOverlayClick={true}
        onClose={() => { setShowWrongChain(false) }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Wrong Network</ModalHeader>
          <ModalCloseButton className="remove-focus" />
          <ModalBody className="text-16">
            <div>
              Current network is not supported. Please connect to {env === 'development' ? 'Rinkeby' : 'Main network'}.
            </div>
          </ModalBody>
          <ModalFooter>
            <button onClick={() => { setShowWrongChain(false) }} className="twitter-share-button flex justify-center btn-middle bg-color-base max-w-2xl font-bold text-16 lg:text-20 px-4 h-12 lg:h-14 w-full text-color-222">
              Ok
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}
export default BountyCreate;