import { Image } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Content } from 'src/components/Layout/content'
import { getAvatar, getUserName } from 'src/utils/utils'
import { useNavigate } from 'react-router-dom' // Pages

import '../index.less'
import { recentOrderList } from 'src/request/order.js'

export const RecentRequest = () => {
  const [orderList, setOrderList] = useState([])

  useEffect(() => {
    recentOrderList().then(res => {
      console.log(res)
      setOrderList(res.data.data)
    })
  }, [])

  const navigate = useNavigate()
  const goToShop = (shopId) => {
    navigate('/shop/' + shopId)
  }

  return (
    <div className="bg-black py-12 px-5 md:px-0">
      <Content className="flex align-center flex-col">
        <h2 className="text-xl md:text-3xl text-white text-center my-6 line mx-auto inline-block">
          Recent Requests
        </h2>
        <div className="grid-css">
          {
            orderList.map((item, key) => (
              <div className="cursor-pointer" onClick={goToShop.bind(this, item.shop_id)} key={key}>
                <div className="height-236 rounded overflow-hidden">
                  <Image className="" src={item.current_picture} fallbackSrc="/images/holder.png" />
                </div>
                {/* <div className="text-gray-400 mt-2 font-bold">Recent NFT</div> */}
                <div className="h-20 bg-gray-900 rounded  grid grid-cols-2 px-4">
                  <div className="">
                    <div className="text-gray-400 mt-2 font-bold">Creator</div>

                    <div className=" flex items-center mt-3  ">
                      <div className=" rounded-full avatar bg-gray-200 over-hidden">
                        <Image src={item.shop_avatar} className="w-full" />
                      </div>
                      <div className="text-gray-300 over-css text-overflow-ellipsis  w-14 ml-2">{item.shop_name}</div>
                    </div>
                  </div>
                  <div>
                    <div className="text-gray-400 mt-2 font-bold">Requester</div>

                    <div className="flex items-center mt-3">
                      <div className=" rounded-full  avatar bg-gray-200   over-hidden">
                        <Image src={getAvatar(item.requester_avatar, item.requester_address)} className="w-full" />
                      </div>
                      <div className="text-gray-300 w-14 over-css ml-2"><code>{getUserName(item.requester_name, item.requester_address)}</code></div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </Content>
    </div>
  )
}
