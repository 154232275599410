import React, { Component } from 'react';
import PackageViewer from './packageViewer';
import holder_png from "../../static/image/holder.png"
import { Image } from '@chakra-ui/react'

class ImageViewer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      imageStatus: false,
    };
  }

  handleImageFailed() {
    this.setState({ imageStatus: false });
  }

  handleImageLoaded() {
    this.setState({ imageStatus: true });
  }

  render() {
    const options = {
      title: false, //图片名称
      navbar: false, //缩略图
      fullscreen: false, //全屏播放
      loop: false, //是否循环 上一个 下一个
      loading: false,
      button: true, //
      tooltip: true, //缩放百分比
      movable: false, //图片移动
      toolbar: false,
      loading: true,
      zoomable: false,
      transition: false,
      backdrop: true
    };
    let imgObj = { ...this.props };
    delete imgObj.triggerview
    if (this.state.imageStatus) {
      return (
        <PackageViewer
          options={options}
          triggerview={this.props.triggerview}
        >
          <img
            {...imgObj}
            title={''}
            style={{
              cursor: 'pointer',
            }} />
        </PackageViewer>
      );
    }
    return (
      <Image
        {...imgObj}
        className='m-auto'
        fallbackSrc={holder_png}
        onError={() => this.handleImageFailed()}
        onLoad={() => this.handleImageLoaded()}
      />
      // <img {...imgObj}
      //   onError={() => this.handleImageFailed()}
      //   onLoad={() => this.handleImageLoaded()} />
    );
  }
}

export default ImageViewer;