import { useState } from 'react';
import { viewAdd } from '@/request/user';
import { Image } from '@chakra-ui/react';
import Lightbox from 'react-image-lightbox';
import { PhotoAlbum } from "react-photo-album";
import holder_png from "@/static/image/holder.png"
import DefaultAvatar from '@/static/image/default-avatar.png';

import 'react-image-lightbox/style.css';
import './index.less'

const ArtsList = ({ list, setList }) => {

  const [index, setIndex] = useState(-1)

  const renderPhoto = ({ layout, layoutOptions, photo, imageProps: { alt, style, ...restImageProps } }) => {
    return (
      <div
        className={`gallery-item ${photo.hover ? 'hover' : ''}`}
        style={{
          borderRadius: "4px",
          boxSizing: "content-box",
          alignItems: "center",
          width: style?.width,
          height: style?.height,
          display: 'flex',
          flexDirection: 'column'
        }}
        onMouseEnter={() => handleSetHover(true, photo.index)}
        onMouseLeave={() => handleSetHover(false, photo.index)}
      >
        <Image alt={alt} style={{ ...style, width: "100%", padding: 0 }} {...restImageProps} fallbackSrc={holder_png} />
        {
          photo.hover && <div className="gallery-item-info" onClick={() => {
            handleClickPhotoAlbum(null, null, photo.index);
            setIndex(photo.index);
          }}>
            <div className='gallery-item-info__top'>
              <span className="iconfont icon-view"></span>
              <span>{photo.views || 0}</span>
            </div>
            <div className='gallery-item-info__bottom'>
              <div className="artist-info" onClick={() => {
                navigate(`/my-gallery/${photo.address}`)
              }}>
                <img src={photo.avatar || DefaultAvatar}></img>
                <span>{photo.artist_name}</span>
              </div>
              <div className='art-title'>{photo.title}</div>
            </div>
          </div>
        }
      </div>
    );
  }

  const handleClickPhotoAlbum = (event, photo, index) => {
    handleView(index)
    setIndex(index)
  }

  const handleSetHover = (isHover, index) => {
    let listCopy = [...list];
    listCopy[index].hover = isHover;
    setList(listCopy)
  }

  const handleView = (index) => {
    let newList = [...list]
    viewAdd({
      id: newList[index].id
    }).then(res => {
      if (res.data.code === 0) {
        newList[index].views = newList[index].views + 1
        setList(newList)
      }
    }).catch(error => {
      console.log(error);
    })
  }

  return (
    <div className='arts-list-wrap'>
      <PhotoAlbum
        layout="rows"
        photos={list}
        renderPhoto={renderPhoto}
        onClick={(event, photo, index) => handleClickPhotoAlbum(event, photo, index)}
      ></PhotoAlbum>
      {
        index >= 0 && <Lightbox
          mainSrc={list[index].file}
          onCloseRequest={() => setIndex(-1)}
        />
      }
    </div>
  )
}
export default ArtsList;