import { Content } from 'src/components/Layout/content'

const HighlightCard: React.FC<{
  icon: string
  title: string
  content: string
  feature?: boolean
}> = ({ icon, title, content, feature }) => {
  return (
    <div className="p-10 bg-black rounded-xl">
      <img
        className="rounded-full h-8 w-8 p-1.5 bg-white bg-opacity-10"
        src={icon}
        alt="Highlight"
      />
      <div className="mt-4">
        <h3 className="text-white text-lg">
          {title}
          {feature ? <sup> soon</sup> : null}
        </h3>
        <p className="mt-2 text-gray-500">{content}</p>
      </div>
    </div>
  )
}

export const Highlight = () => {
  return (
    <div className="bg-dark-2 pt-6 md:pt-12 px-5 md:px-0">
      <Content className="flex align-center flex-col">
        <h2 className="text-xl md:text-3xl text-white text-center mt-6 line mx-auto inline-block">
          CreatorDAO
        </h2>
        <div className="grid grid-cols-1 gap-x-6 gap-y-5 md:grid-cols-2 lg:mt-16 lg:grid-cols-3 lg:gap-x-8">
          <HighlightCard
            icon="/images/eth.svg"
            title="Rich types of creation"
            content="50+ indie artists with diversified genres & styles"
          />

          <HighlightCard
            icon="/images/eth.svg"
            title="Standardized Delivery"
            content="CreatorDAO curated booths organized by top artists team for a more standardized delivery"
          />

          <HighlightCard
            icon="/images/eth.svg"
            feature
            title="Creatorverse"
            content="All NFT piece born within CD are promised a place in the future creatorverse.And NFT-themed metaverses voted by DAO."
          />

          <HighlightCard
            icon="/images/eth.svg"
            title="Training"
            content="CreatorDAO provide Bi-week training session to help artists dive into Web3"
          />

          <HighlightCard
            icon="/images/eth.svg"
            feature
            title="Social Platform for Creators"
            content="Decentralized collector/artist social platform built on Lens Protocol"
          />

          <HighlightCard
            icon="/images/eth.svg"
            feature
            title="DAO treasury"
            content="DAO treasury for blue-chip NFT investments & incubation"
          />
        </div>
        <div className="px-4 py-16 mx-auto sm:max-w-none sm:flex sm:justify-center">
          <div className="space-y-4 sm:space-y-0 sm:mx-auto">
            <a
              href="https://forms.gle/KiFdSmLvTmZ6XZvE8"
              className="flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white no-underline bg-black border border-transparent rounded-md  betterhover:dark:hover:bg-gray-300 betterhover:hover:bg-gray-700 md:py-3 md:text-lg md:px-10 md:leading-6 hover:text-primary"
            >
              Join now →
            </a>
          </div>
        </div>
      </Content>
    </div>
  )
}
