import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  className?: string
}

export const Content: React.FC<Props> = ({ children, className = '' }) => {
  return (
    <div className={`mx-auto max-w-screen-xl ${className}`}>{children}</div>
  )
}
