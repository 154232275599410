import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Textarea,
  Button,
  useToast,
  Switch
} from '@chakra-ui/react'
import { Upload } from 'antd';
import { Helmet } from "react-helmet";
import { AppContext } from "@/AppContext";
import { useSignWallet } from "@/wallet/sign";
import { useNavigate } from 'react-router-dom';
import makeBlockie from 'ethereum-blockies-base64';
import { getUserInfo, updateUserInfo } from "@/request/user";
import { useState, useContext, useEffect, useCallback } from 'react';
import hello from 'hellojs/dist/hello.all.js'

import DefaultAvatar from '@/static/image/default-avatar.png';

import './profile.less'

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const defaultInfo = {
  avatar: '',
  name: '',
  twitter: '',
  email: '',
  instagram: '',
  description: '',
  discord: '',
  show_twitter: false
}

const Profile = () => {
  const toast = useToast();
  const toAuth = useSignWallet();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext)

  const [loading, setLoading] = useState(false)
  const [avatarFile, setAvatarFile] = useState(null)
  const [userInfo, setUserInfo] = useState({ ...defaultInfo })
  const [nameError, setNameError] = useState(false)
  const [twitterError, setTwitterError] = useState(false)
  const [defaultAvatar, setDefaultAvatar] = useState(DefaultAvatar)
  const [address, setAddress] = useState("")

  const getUserInfoRequest = async (addr) => {
    getUserInfo(addr).then(res => {
      if (res.data.code != 0) {
        if (res.data.code === 3999) {
          setUserInfo(defaultInfo)
          // navigate('/check-code');
        } else {
          toast({
            position: 'top',
            title: 'Get User Info error.',
            description: res.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        }
      } else {
        setUserInfo(res.data.data || defaultInfo)
      }
    }).catch(error => {
      toast({
        position: 'top',
        title: 'Get User Info error.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    })
  }

  useEffect(() => {
    hello.init(
      {
        'twitter': 'pfr7BCS4C2NrYcFia9lO5QwOE'
      },    //App_key
      {
        oauth_proxy: 'https://auth-server.herokuapp.com/proxy'
      }
    );
  }, [])

  useEffect(() => {
    if (state.profile.address) {
      getUserInfoRequest(state.profile.address)
      setDefaultAvatar(makeBlockie(state.profile.address))
      setAddress(state.profile.address)
    }
  }, [state.profile.address])


  const handleTwitterLogin = () => {
    const log = console.log;
    const twitter = hello('twitter');
    // Login
    twitter.login().then((r) => {
      // Get Profile
      return twitter.api('/me');
    }, log).then((p) => {
      let newInfo = { ...userInfo };
      newInfo.twitter = p.screen_name
      setUserInfo(newInfo)
    }, log);
  }

  const handleBeforeUpload = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      toast({
        position: 'top',
        title: 'Error',
        description: "The avatar size is limited to less than 5M.",
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
    return isLt5M;
  }


  const handleChangeAvatar = info => {
    if (info.file.size / 1024 / 1024 > 5) {
      return
    }
    let newInfo = { ...userInfo };
    setAvatarFile(info.file)
    getBase64(info.file.originFileObj, imageUrl =>
      newInfo.avatar = imageUrl
    )
    setUserInfo(newInfo)
  };

  const handleChangeInfo = useCallback((value, type) => {
    let newInfo = { ...userInfo };
    newInfo[type] = value;
    setUserInfo(newInfo)
    if (type === 'name') {
      setNameError(!newInfo['name'].trim() ? true : false)
    }
    // if (type === 'twitter') {
    //   setTwitterError(!newInfo['twitter'].trim() ? true : false)
    // }
  }, [userInfo])

  const handleSubmit = async () => {
    const token = await toAuth();
    if (!token) {
      return;
    }
    const {
      name,
      twitter,
      email,
      instagram,
      description,
      show_twitter,
      discord
    } = userInfo;

    console.log(userInfo);

    var data = new FormData()
    data.append("address", state.profile.address)
    data.append("avatar", avatarFile ? avatarFile.originFileObj : "")
    data.append("name", name)
    data.append("twitter", twitter)
    data.append("email", email)
    data.append("instagram", instagram)
    data.append("description", description)
    data.append("show_twitter", show_twitter)
    data.append("discord", discord)
    setLoading(true)
    updateUserInfo(data, token).then(res => {
      if (res.data.code != 0) {
        toast({
          position: 'top',
          title: 'Update profile error.',
          description: res.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
      } else {
        toast({
          position: 'top',
          title: 'Update profile success',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        navigate(`/my-gallery/${state.profile.address}`)
      }
    }).catch(error => {
      toast({
        position: 'top',
        title: 'Update profile error.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
    })
  }

  return <div className='edit-profile-wrapper min-h-3/4'>
    <Helmet>
      <title>CreatorDAO-Edit Profile</title>
    </Helmet>
    <div className='container'>
      <div className='title-wrap'>
        <span className='title'>Profile /</span> {address}
      </div>
      <div className='upload-content'>
        <img src={userInfo.avatar || defaultAvatar} alt="avatar" className='avatar' />
        <Upload
          name="avatar"
          listType="picture-card"
          accept="image/png, image/jpg, image/jpeg"
          maxCount={1}
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={(e) => handleBeforeUpload(e)}
          onChange={(e) => handleChangeAvatar(e)}
        >
          <div className='upload-button'>
            Upload new picture
          </div>
        </Upload>
      </div>

      <FormControl isRequired isInvalid={nameError}>
        <FormLabel htmlFor='name'><font>Name</font></FormLabel>
        <Input id='name' type='text' placeholder='Feel free to fill your web3 name' value={userInfo.name || ''} onChange={(e) => handleChangeInfo(e.target.value, 'name')} />
        <FormHelperText><font>We are big on the names that can let every one know who you are.</font></FormHelperText>
        {nameError && <FormErrorMessage><font>Name is required.</font></FormErrorMessage>}
      </FormControl>
      <FormControl isInvalid={twitterError}>
        <FormLabel htmlFor='twitter'><font>Twitter</font></FormLabel>
        {
          userInfo.twitter ? (
            <div className='twitter-content'>
              <div className='account'>
                <span className='iconfont icon-close' onClick={() => handleChangeInfo("", 'twitter')}></span>
                {`@${userInfo.twitter}`}
              </div>
              <FormControl display='flex' alignItems='center' className='twitter-control'>
                <Switch isChecked={userInfo.show_twitter} onChange={(e) => handleChangeInfo(e.target.checked, 'show_twitter')} size='sm' id="shown-on-profile" />
                <FormLabel htmlFor='shown-on-profile' mb='0'>
                  Shown on profile
                </FormLabel>
              </FormControl>
            </div>

          ) : (
            <div className='main-button' onClick={() => {
              handleTwitterLogin()
            }}>
              <span className='iconfont icon-twitter'></span>
              Connect to Twitter
            </div>
          )
        }
        <FormHelperText><font>One-click sign in</font></FormHelperText>

        {twitterError && <FormErrorMessage><font>Twitter is required.</font></FormErrorMessage>}

      </FormControl>
      <FormControl>
        <FormLabel htmlFor='twitter'><font>E-mail</font></FormLabel>
        <Input id='email' type='email' placeholder='youradress@example.com' value={userInfo.email || ''} onChange={(e) => handleChangeInfo(e.target.value, 'email')} />
        <FormHelperText><font>So we can contact with you.</font></FormHelperText>
      </FormControl>
      <FormControl>
        <FormLabel htmlFor='twitter'><font>Instagram</font></FormLabel>
        <Input id='instagram' type='text' placeholder='@youraccounts' value={userInfo.instagram || ''} onChange={(e) => handleChangeInfo(e.target.value, 'instagram')} />
      </FormControl>

      <FormControl>
        <FormLabel htmlFor='twitter'><font>Discord</font></FormLabel>
        <Input id='discord' type='text' placeholder='youraccount#0000' value={userInfo.discord || ''} onChange={(e) => handleChangeInfo(e.target.value, 'discord')} />
      </FormControl>

      <FormControl>
        <FormLabel htmlFor='twitter'><font>Bio</font></FormLabel>
        <Textarea id='bio' type='text' placeholder='Describe yourself' maxLength={100} value={userInfo.description || ''} onChange={(e) => handleChangeInfo(e.target.value, 'description')} />
        <FormHelperText><font>Brief description for your profile.</font></FormHelperText>
      </FormControl>
      <div className='button-group'>
        <Button
          fontWeight={700}
          color='#222'
          width='128px'
          height='48px'
          marginRight='16px'
          isLoading={loading}
          loadingText="Save"
          borderRadius='1px'
          variant='solid'
          colorScheme='customPrimary'
          isDisabled={!userInfo.name}
          onClick={() => handleSubmit()}
        >
          <font>Save</font>
        </Button>
        <Button
          style={{ fontWeight: 400 }}
          width='128px'
          height='48px'
          isDisabled={loading}
          borderRadius='1px'
          _hover={{ bg: 'transparent' }}
          borderColor="#D9D9D9"
          variant='outline'
          onClick={() => {
            if (state.profile.address) {
              navigate(`/my-gallery/${state.profile.address}`);
            } else {
              navigate(-1)
            }
          }}
        >
          <font>Cancel</font></Button>

      </div>
    </div>

  </div>
}
export default Profile;