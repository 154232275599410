import {
  Button,
  Input,
  Textarea,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react';
import Cropper from 'react-easy-crop';
import { Upload, Select } from 'antd';
import { AppContext } from "@/AppContext";
import { uploadWork } from "@/request/user";
import { useSignWallet } from "@/wallet/sign";
import { getSpaceTagsResuest } from '@/request/data';
import { useNavigate, useParams } from 'react-router-dom';
import { bountyWorkUploadRequest } from '@/request/bounty';
import React, { useCallback, useState, useContext, useEffect } from 'react';

import getCroppedImg from './cropImage';
import './index.less';

const { Option } = Select;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const UploadWorkPage = () => {
  const toast = useToast();
  const params = useParams();
  const toAuth = useSignWallet();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext)

  const [loading, setLoading] = useState(false);
  const [workName, setWordName] = useState('')
  const [description, setDescription] = useState('');
  const [fileList, setFileList] = useState([]);
  const [oriImage, setOriImage] = useState('');

  const [mediaType, setMediaType] = useState('image');
  const [mediaUrl, setMediaUrl] = useState('');

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  const [tagModalShow, setTagModalShow] = useState(false)
  const [tagList, setTagList] = useState([])
  const [tag, setTag] = useState([])

  useEffect(() => {
    if (!params.id) {
      getTagList()
    }
  }, [])

  const getTagList = async () => {
    const { data } = await getSpaceTagsResuest({
      search: '',
      order: 'asc'
    })
    if (data && data.code === 0) {
      console.log('3231231', data.data);
      setTagList(data.data || [])
      setTag(data.data.length > 0 ? [data.data[0].symbol] : [])
    }
  }

  // blob to file
  const Blob2ImageFile = (data) => new File([data], `${(new Date).getTime()}.jpeg`, { type: 'image/jpeg', lastModified: Date.now() });

  const handleChangeName = (e) => {
    setWordName(e.target.value)
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const onChange = async ({ fileList: newFileList }) => {
    if (newFileList[0].type.indexOf('image') > -1) {
      if (newFileList[0].size > 10 * 1024 * 1024) {
        toast({
          position: 'top',
          title: 'Error',
          description: "The image size is limited to less than 10M",
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        return;
      } else {
        const image = await getBase64(newFileList[0].originFileObj)
        setOriImage(image)
        setMediaType('image')
        setFileList(newFileList);
      }
    } else {
      if (newFileList[0].size > 20 * 1024 * 1024) {
        toast({
          position: 'top',
          title: 'Error',
          description: "The video size is limited to less than 20M",
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        return;
      } else {
        const url = URL.createObjectURL(newFileList[0].originFileObj);
        setMediaUrl(url)
        setMediaType('media')
        setFileList(newFileList);
      }
    }
  };

  const handleChangeZoom = useCallback((flag) => {
    if (flag === 'plus' && zoom < 3) {
      setZoom(zoom + 0.1)
    } else if (flag === 'minus' && zoom > 1) {
      setZoom(zoom - 0.1)
    }
  }, [zoom])

  const handleDelete = () => {
    setFileList([])
    setOriImage('')
  }

  const handleBeforeUpload = (file, fileList) => {
    return false;
  }

  const handleSubmit = () => {
    if (!params.id) {
      setTagModalShow(true)
    } else {
      triggerSubmit()
    }
  }

  const triggerSubmit = async () => {
    const token = await toAuth();
    if (!token) {
      return;
    }

    const file = await showCroppedImage()
    var data = new FormData()
    data.append("picture_file", fileList[0].originFileObj);
    data.append("thumb_picture", file);
    data.append("title", workName);
    data.append("address", state.profile.address);
    setLoading(true)
    if (params.id) {
      data.append("bounty_id", params.id)
      data.append("description", description)
      bountyWorkUpload(data, token)
    } else {
      const space_tag = tag.length > 0 ? tag.join(',') : '';
      data.append("space_tag", space_tag);
      workUpload(data, token)
    }
  }

  const bountyWorkUpload = async (data, token) => {
    bountyWorkUploadRequest(data, token).then(res => {
      if (res.data.code != 0) {
        toast({
          position: 'top',
          title: 'Upload work error.',
          description: res.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
      } else {
        toast({
          position: 'top',
          title: 'Upload work success',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        navigate(`/bounty/${params.id}`)
      }
    }).catch(error => {
      toast({
        position: 'top',
        title: 'Upload work error.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
    })
  }

  const workUpload = async (data, token) => {
    uploadWork(data, token).then(res => {
      if (res.data.code != 0) {
        toast({
          position: 'top',
          title: 'Upload work error.',
          description: res.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
      } else {
        toast({
          position: 'top',
          title: 'Upload work success',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        setLoading(false)
        navigate(`/my-gallery/${state.profile.address}`)
      }
    }).catch(error => {
      toast({
        position: 'top',
        title: 'Upload work error.',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      setLoading(false)
    })
  }

  // get cropped image file
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        oriImage,
        croppedAreaPixels,
        0
      )
      let reader = new FileReader()
      reader.readAsDataURL(croppedImage)
      reader.onload = function (e) {
        setCroppedImage(reader.result)
      }
      const file = Blob2ImageFile(croppedImage)
      return file
    } catch (e) {
      // console.error(e)
    }
  }, [croppedAreaPixels, oriImage])

  return (
    <div className="min-h-3/4 upload-work-wrapper">
      <div className="max-w-7xl mx-auto px-4 md:px-0">
        <section className='button-group mb-8'>
          <Button
            isDisabled={loading}
            borderRadius='1px'
            _hover={{ bg: 'transparent' }}
            borderColor="#D9D9D9"
            variant='outline'
            onClick={() => {
              if (state.profile.address && !params.id) {
                navigate(`/my-gallery/${state.profile.address}`);
              } else {
                navigate(-1)
              }
            }}
          >CANCEL</Button>
          <Button
            className='main-btn'
            isLoading={loading}
            loadingText="CONTINUE"
            borderRadius='1px'
            variant='solid'
            colorScheme='customPrimary'
            isDisabled={!workName || !fileList.length || (params.id && !description)}
            onClick={() => handleSubmit()}
          >CONTINUE</Button>
        </section>

        <section className='upload-content'>
          <div className='title'>
            <Input
              disabled={loading}
              value={workName}
              variant='unstyled'
              placeholder='Give me a name'
              _placeholder={{ color: '#CCCCCC' }}
              fontSize='32'
              fontWeight={700}
              onChange={(e) => handleChangeName(e)}
            />
            {
              !params.id && <div className='title-tips'>Upload your work here.</div>
            }
          </div>
          <div className={`content h-mfull md:w-630 md:h-630 ${params.id ? 'proposal-content' : ''}`}>
            {
              !fileList.length ? <Upload
                listType="picture"
                fileList={fileList}
                onChange={onChange}
                accept="image/*, video/mp4"
                maxCount={1}
                showUploadList={false}
                beforeUpload={(file, fileList) => handleBeforeUpload(file, fileList)}
              >
                <span className='iconfont icon-upload-default'></span>
                <div className='tips'>
                  Drag and drop an image, or <span className='link'>Browse</span>
                </div>
                <div className='tips'>1500x1500 or higher recommended. Max 10MB (20MB for videos)</div>
              </Upload> : <>
                <div className='delete' onClick={() => handleDelete()}>
                  <span className='iconfont icon-delete'></span>
                </div>
                {
                  mediaType === 'image' ? <>
                    <div className='cropper-wrap'>
                      <Cropper
                        image={oriImage}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        showGrid={false}
                        objectFit='contain'
                      />
                    </div>
                    <div className='controls-wrap'>
                      <div className="controls">
                        <span className='iconfont icon-minus' onClick={() => handleChangeZoom('minus')}></span>
                        <input
                          type="range"
                          value={zoom}
                          min={1}
                          max={3}
                          step={0.1}
                          aria-labelledby="Zoom"
                          onChange={(e) => {
                            setZoom(e.target.value)
                          }}
                          className="zoom-range"
                        />
                        <span className='iconfont icon-add' onClick={() => handleChangeZoom('plus')}></span>
                      </div>
                    </div>
                  </> : <video controls>
                    <source src={mediaUrl} type="video/mp4" />
                    Your browser does not support video tags.
                  </video>
                }
              </>
            }
          </div>
          {
            params.id && <div>
              <Textarea
                disabled={loading}
                value={description}
                variant='unstyled'
                resize={"none"}
                className='description-textarea'
                _placeholder={{ color: '#CCCCCC' }}
                placeholder='Describe your work here.'
                onChange={(e) => {
                  setDescription(e.target.value)
                }}
              ></Textarea>
            </div>
          }
        </section>
      </div>

      {
        tagList.length > 0 && (
          <Modal
            size="md"
            isOpen={tagModalShow}
            closeOnOverlayClick={true}
            onClose={() => { setTagModalShow(false) }}
          >
            <ModalOverlay />
            <ModalContent>
              <div className='tag-custom-modal'>
                <ModalHeader>Upload your work</ModalHeader>
                <ModalCloseButton className="remove-focus" />
                <ModalBody className="text-16">
                  <Select
                    mode="tags"
                    className='tag-select'
                    placeholder='Please select tags'
                    size='large'
                    defaultValue={[]}
                    style={{ width: '100%' }}
                    onChange={(e) => {
                      setTag(e)
                    }}
                  >
                    {
                      tagList.map((item, index) => {
                        return <Option className='select-item-custom' key={`item_${index}`} value={item.symbol}>{item.name}</Option>
                      })
                    }
                  </Select>
                </ModalBody>
                <ModalFooter>
                  <div className='button-group'>
                    <Button
                      className='sub-btn'
                      borderRadius='2px'
                      _hover={{ bg: 'transparent' }}
                      borderColor="#888888"
                      variant='outline'
                      onClick={() => setTagModalShow(false)}
                    >Cancel</Button>
                    <Button
                      className='main-btn'
                      loadingText="Upload!"
                      borderRadius='2px'
                      variant='solid'
                      colorScheme='customPrimary'
                      isDisabled={!tag.length}
                      onClick={() => {
                        setTagModalShow(false)
                        triggerSubmit()
                      }}
                    >Upload!</Button>
                  </div>
                </ModalFooter>
              </div>

            </ModalContent>
          </Modal>
        )
      }

    </div>
  )
}
export default UploadWorkPage;