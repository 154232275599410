import  React,{useReducer,useCallback,useMemo, useContext,useEffect} from "react";
import * as types from '../reducer/types'
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import { AppContext } from "../AppContext";
import { SIGN_KEY } from "../config/params";
import { providerOptions } from "./provider/providerOptions";
import { Biconomy } from "@biconomy/mexa";
import detectEthereumProvider from '@metamask/detect-provider';
const current_api_key = process.env.REACT_APP_META_API_KEY  
const provider_rpc = process.env.REACT_APP_PROVIDER_API
const web3Modal = new Web3Modal({
    cacheProvider: true, // optional
    providerOptions // required
  });

export const useNewConnectWallet = ()=>{
  const {state, dispatch} = useContext(AppContext);
  const provider = state.profile.provider
  const biconomy = state.profile.biconomy
  const disconnect = async () => {
    await web3Modal.clearCachedProvider();
    dispatch({type:types.LOGOUT})
    localStorage.removeItem(SIGN_KEY);
    // refreshState();
  };

    const connectWallet = async () => {
        try {
            const provider = await web3Modal.connect();
            const library = new ethers.providers.Web3Provider(provider);
            const accounts = await library.listAccounts();
            const network = await library.getNetwork();

            let address = ""
            if (accounts) address = accounts[0]

            let jsonRpcProvider = new ethers.providers.JsonRpcProvider(
              provider_rpc,
            );
            const ethProvider = await detectEthereumProvider();
            const biconomy = new Biconomy(jsonRpcProvider, {
              walletProvider: ethProvider,
              apiKey: current_api_key,
            });
            // setBiconomy(biconomy);
      
            // await biconomy.init();
            const ethersProvider = new ethers.providers.Web3Provider(biconomy);
            // setMetaLibrary(metaLibrary);

            dispatch({
              type:types.LOGIN,
              address:address,
              provider:provider,
              library:library,
              // metaLibrary:metaLibrary,
              biconomy:biconomy,
              ethersProvider:ethersProvider,
            })
            // setProvider(provider);
            // setLibrary(library);
            // if (accounts) setAccount(accounts[0]);
            // setChainId(network.chainId);
        } catch (error) {
            console.log(error);
        }
    };

    const newConnectWallet = useCallback(() => {
            return connectWallet()
        })
    // if (web3Modal.cachedProvider) {
        // connectWallet();
    //   }
    const newDisconnect = useCallback(()=>{
        return disconnect()
    })
    
      useEffect(() => {
        if (provider && provider.on) {
          const handleAccountsChanged = (accounts) => {
            console.log("accountsChanged", accounts);
            if (accounts)  dispatch({type:types.SETADDRESS,address:accounts[0]});
          };
    
          const handleChainChanged = (_hexChainId) => {
            console.log("changeChain", _hexChainId);
            // setChainId(_hexChainId);
          };
    
          const handleDisconnect = (error) => {
            console.log("disconnect", error);
            disconnect();
          };
    
          provider.on("accountsChanged", handleAccountsChanged);
          provider.on("chainChanged", handleChainChanged);
          provider.on("disconnect", handleDisconnect);
    
          return () => {
            if (provider.removeListener) {
              provider.removeListener("accountsChanged", handleAccountsChanged);
              provider.removeListener("chainChanged", handleChainChanged);
              provider.removeListener("disconnect", handleDisconnect);
            }
          };
        }

        if(biconomy && biconomy.on){
          biconomy.on("txMined", (data) => {
            // Event emitter to monitor when a transaction is mined
            console.log("transaction data", data);
          });
          biconomy.on("txHashGenerated", (data) => {
              // Event emitter to monitor when a transaction hash is generated
              console.log("transaction data", data);
          });
          biconomy.on("txHashChanged", (data) => {
              // Event emitter to monitor when a transaction hash is changed in case of gas price bump
              console.log("transaction data", data);
          });
          biconomy.on("error", (data) => {
              // Event emitter to monitor when an error occurs
              console.log("transaction data", data);
          });
        }

      
      }, [provider]);

    useMemo(() => {
        // console.log('reload connect',provider)
        // first connect
        if (web3Modal.cachedProvider) {
            newConnectWallet();
          }
        // web3Modal.cachedProvider && newConnectWallet()

        }, [])
  
      return [newConnectWallet,newDisconnect]
}
