import React, { useState, useEffect } from "react";
import makeBlockie from 'ethereum-blockies-base64';
import 'url-search-params-polyfill';
import ImageViewer from '@/components/ImageViewer';

import { ShopDetailData } from "../../request/data";
import { useLocation, useParams } from "react-router-dom";
import eth from "../../static/image/eth.jpg"
import Commisson from "../../components/modals/commission";
import NormalCommission from "../../components/modals/normalCommission";

import {getAvatar,getUserName } from "../../utils/utils";
import { useDisclosure, Image, Alert, AlertIcon, CloseButton } from '@chakra-ui/react'
import discord from '../../static/image/discord.svg'
import twitter from '../../static/image/twitter.svg'
import opensea from '../../static/image/os.svg'
import { Helmet } from "react-helmet";
import holder_png from "../../static/image/holder.png"


import "./shop.css"

function Shop(props) {
    // const location = useLocation();
    const params = useParams();
    // var search = new URLSearchParams(location.search);
    const shopId = params.shopId || ""
    // const {shopId} = location.state || ""
    const [showModal, setShowModal] = useState(false)
    const [shopDetail, setShopDetail] = useState(null)
    const [listQueued, setListQueued] = useState([])

    const toggleShow = () => setShowModal(p => !p)
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        shopId ? ShopDetailData(shopId).then(res => {
            const resData = res.data
            if (resData.code == 0) {
                setShopDetail(resData.data)
                setListQueued(resData.data.list_queued || [])
            }
        }) : ''

        document.getElementById("click_commission").addEventListener("click", function () {
            gtag("event", "click");
        });
    }, [])

    const {
        isOpen: isVisible,
        onClose: onCloseTips,
    } = useDisclosure({ defaultIsOpen: true })

    const setNewRecord = (record) => {
        var list_queued = listQueued
        list_queued.push(record)
        setListQueued(list_queued)
    }
    return (
        <div>
            <Helmet>
                <title>CreatorDAO-Shop Detail</title>
                {shopDetail && <meta property="og:title" content={shopDetail.shop_name} />}
                {shopDetail && <meta property="og:image" content={shopDetail.banner_image} />}
                {shopDetail && <meta property="og:description" content={shopDetail.description} />}
            </Helmet>
            {
                isVisible && shopId == 1 && process.env.REACT_APP_ENV != "prod" ?
                    <div className={`${location.pathname == "/" ? "absolute " : ""}` + "w-full top-100"}>
                        <Alert
                            alignItems='center'
                            justifyContent='center'
                            textAlign='center'
                            status='warning'
                            height='53px'
                        >
                            <AlertIcon />
                            You{"'"}re viewing data from the test network.
                            <CloseButton
                                alignSelf='flex-start'
                                position='relative'
                                left={3}
                                onClick={onCloseTips}
                            />
                        </Alert>
                    </div>
                    : ""
            }
            <div className="max-h-80 min-h-24 lg:h-80 overflow-hidden">
                <img src={shopDetail?.banner_image} className="w-full" />
            </div>
            {
                shopDetail && shopDetail.shop_type == 10 ?
                    <Commisson isOpen={isOpen} onOpen={onOpen} setNewRecord={setNewRecord} onClose={onClose} shopId={shopId} minBid={shopDetail?.min_price} shopType={shopDetail?.shop_type} tokenAddress={shopDetail?.token_address} tokenName={shopDetail?.token_name} showModal={showModal} />
                    :
                    <NormalCommission isOpen={isOpen} onOpen={onOpen} setNewRecord={setNewRecord} onClose={onClose} shopId={shopId} minBid={shopDetail?.min_price} shopType={shopDetail?.shop_type} showModal={showModal} />
            }
            <div className=" mx-auto  px-4  mb-10 lg:px-16  grid grid-cols-1 lg:grid-cols-10">
                <div className="rounded-full w-20 h-20 lg:w-40 lg:h-40 bg-gray-200 col-span-1 mt-fu-14 lg:mt-fu-20  over-hidden">
                    {/* <img src={shopDetail?.avatar} className="w-full" /> */}
                    <Image src={shopDetail?.avatar} className="w-full" fallbackSrc={holder_png} />

                </div>
                <div className="lg:col-span-7 lg:ml-11 lg:mr-12 mt-10">
                    <div className="md:ml-8">
                        <div className="overflow-scoll space-x-4 white-space-nowrap"  >
                            {
                                shopDetail && shopDetail.pictures_with_size.length > 0 ?
                                    shopDetail.pictures_with_size.map((info, key) => (
                                        <div key={key} data-value={key + 1} className="item-h-170  inline-block">
                                            <ImageViewer
                                                className='item-h-170'
                                                src={info.little_picture}
                                                realsrc={info.picture}
                                                fit='cover'
                                            ></ImageViewer>
                                            {/* <Image src={info} className="item-h-170" fit='cover' /> */}
                                            {/* <img  alt="" src={info} /> */}
                                        </div>
                                    ))
                                    :
                                    ''
                            }

                            {/* <CarouselShop pictures = {shopDetail?.pictures}  /> */}

                        </div>
                        <div className="font-color-222 text-size-32 font-bold mt-4 m-2  items-center">
                            {shopDetail?.shop_name}
                            {
                                shopDetail && shopDetail.shop_witter ?
                                    <a href={shopDetail.shop_witter} rel="noopener noreferrer" target="_blank" className=""> <img src={twitter} className="ml-1 w-6 display-inherit" /></a>
                                    : ""
                            }
                            {
                                shopDetail && shopDetail.shop_discord ?
                                    <a href={shopDetail.shop_discord} rel="noopener noreferrer" target="_blank"> <img src={discord} className="w-6 ml-1 display-inherit" /></a>
                                    : ""
                            }
                            {
                                shopDetail && shopDetail.shop_opensea ?
                                    <a href={shopDetail.shop_opensea} rel="noopener noreferrer" target="_blank"> <img src={opensea} className="w-7 ml-1 display-inherit" /></a>
                                    : ""
                            }

                            {/* <img src={twitter} className="w-6 ml-2" /> */}
                        </div>
                        <div className="mt-4 m-2">
                            <span className="font-bold font-color-222">{shopDetail?.sold_number}</span> <span className="font-color-4e ml-1">SOLD</span>
                        </div>
                        <div className="mt-4 m-2 text-size-20 font-color-4e">
                            {shopDetail?.description}
                        </div>
                        <div className="mt-4 m-2">
                            <span className="font-bold font-color-222 text-size-20">Content</span>
                        </div>
                        <div className="mt-4 m-2 text-size-16 font-color-4e" dangerouslySetInnerHTML={{ __html: shopDetail?.story }}>

                        </div>
                        <div className="mt-4 m-2">
                            <span className="font-bold font-color-222 text-size-20">Team</span>
                        </div>
                        <div className="grid grid-cols-4 lg:grid-cols-7 ml-3 " >
                            {
                                shopDetail?.team.map((item, key) => (

                                    <div key={key} className="mr-8 w-20 my-3">
                                        <div className="rounded-full w-20 h-20 bg-gray-200 over-hidden">
                                            <Image src={item.avatar} className="w-full" fallbackSrc={holder_png} />
                                        </div>
                                        <div className="text-color-222 text-center font-size-14 mt-2">{item.artist_name}</div>
                                    </div>

                                ))
                            }

                        </div>
                    </div>
                </div>
                <div className="mt-10 lg:col-span-2">
                    <div className="hidden lg:block border-style lg:w-72-e height-170">
                        <div className="text-size-32 font-bold mt-4 ml-6 flex items-center">
                            {shopDetail?.min_price}
                            <img src={eth} className="h-8  ml-3" />
                            <div className="text-color-888 text-size-16 h-8 items-center leading-8 ml-4">or more?</div>
                        </div>
                        <button id="click_commission" onClick={onOpen} className="ml-6 font-bold text-size-20 text-sm bg-color-black hover:text-gray-200 py-2 w-125 px-4 h-14 text-white my-4">COMMISSION </button>
                    </div>


                    <div className="lg:w-72-e mt-8 lg:h-520 overflow-scroll">
                        <div className="text-size-20 font-color-222 mb-2">Queue</div>
                        {
                            listQueued.map((item, key) => (
                                <div key={key} className={`grid grid-cols-12 text-size-18 ${item.state>=30 ? 'text-gray-400' : 'font-color-000'}  my-4` }>
                                    <div className="col-span-2 rounded-full w-10 h-10 bg-gray-200 over-hidden">
                                        <img src={item.address ?getAvatar(item.avatar,item.address) : ""} className="w-full" />
                                    </div>
                                    <div className="col-span-7 leading-10 ml-3">{getUserName(item.user_name,item.address)}</div>
                                    <div className="col-span-3 flex leading-10 items-center justify-end">
                                        {item.price} <img src={eth} className="h-4  ml-1" />
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                </div>
            </div>
            <div className="lg:hidden height-76  bottom-0 px-4 fixed bg-white border-t border-gray-200 w-full grid grid-cols-2 ">
                <div className="text-size-20 font-bold   flex items-center">
                    {shopDetail?.min_price}
                    <img src={eth} className="h-4  ml-3" />
                    <div className="text-color-888 text-size-16 h-8 items-center leading-8 ml-4">or more?</div>
                </div>
                <div className="w-full">
                    <button onClick={onOpen} className="float-right font-bold bg-color-black hover:text-gray-200 py-1 w-140 px-4 height-40 text-white my-4">COMMISSION </button>
                </div>
            </div>
        </div>
    )
}

export default Shop;