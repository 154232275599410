import React, { useState, useContext, useRef, useEffect } from "react";
// import makeBlockie from 'ethereum-blockies-base64';
import { getUserInfo, hasShop } from "@/request/user";
import * as types from '../../reducer/types'
// import { H } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom'; // Pages
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  useDisclosure,
  useToast,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import { CloseCircleFilled } from '@ant-design/icons';
// import {useConnectWallet} from '../../wallet/walletConnect'
import { useNewConnectWallet } from "../../wallet/newConnect"
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import logo from '../../static/image/logo-white.png'
import logo_black from '../../static/image/logo-black.png'


import packageJpg from '../../static/image/package.jpg'
import logoutJpg from '../../static/image/logout.jpg'
import orderSvg from '../../static/image/order.svg'
import { getAvatar,getUserName } from "../../utils/utils";
import { AppContext } from "../../AppContext";

import './header.css';
import { useSignWallet } from "../../wallet/sign";
import SkeletonAvatar from "antd/lib/skeleton/Avatar";

const navigation = [
  // { name: 'Home', href: '/', current: true },
  // { name: 'Space', href: '/space', current: false },
  { name: 'Booth', href: '/booth', current: false },
  { name: 'Bounty', href: '/bounty', current: false },
  { name: 'Gallery', href: '/gallery', current: false },
  { name: 'Creators', href: '/creators', current: false },
  // { name: 'About Us', href: '/about', current: false },
  // { name: 'Terms', href: '/terms', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Header(props) {
  const location = useLocation();
  // const [wallet, setWallet] = useLocalStorage('activeConnector', '');
  const { state, dispatch } = useContext(AppContext)
  const [checkShopNums, setCheckShopNums] = useState(0)
  const [avatar,setAvatar] = useState("")
  // const { library, activate,deactivate } = useWeb3React()
  const [connectWallet, newDisconnect] = useNewConnectWallet()
  const [links, setLinks] = useState(navigation)
  const navigate = useNavigate();
  const toMyCommission = () => {
    // toAuth();return
    // 页面跳转方法
    navigate('/my-commission');
  }
  const toMyWork = () => {
    navigate('/my-work');
  }

  const toEditProfile = () => {
    navigate(`/profile`)
  }

  const toMyGallery = () => {
    navigate(`/my-gallery/${state.profile.address}`)
  }

  useEffect(() => {
    if(!state.profile.address)return;
    hasShop({ address: state.profile.address }).then(res => {
      const resData = res.data.data
      setCheckShopNums(resData.shop_nums)
      // setAvatar(res.data.avatar)
      // console.log(res.data,"4444")
      dispatch({
        type:types.SetUserInfo,
        user_name:resData.user_name,
        avatar:resData.avatar,
        shop_nums:resData.shop_nums
      })
    })
  }, [state.profile.address])

  useEffect(() => {
    let tmpLink = links.map((item) => {
      item.current = false
      if (item.href == location.pathname) {
        item.current = true
      }
      return item
    })
    setLinks(tmpLink)
  }, [location.pathname])

  const {
    isOpen,
    onOpen,
    onClose,
  } = useDisclosure()
  const btnRef = React.useRef()

  //bg-indigo-600
  return (
    <>
      <Disclosure as="nav">
        {({ open }) => (
          <>
            <div 
            className={`${location.pathname == "/" ? "absolute " : ""}` + "w-full"} 
            style={{
              backgroundImage: location.pathname == "/"?"":'linear-gradient(72.44deg, #051937 0%, #5F2F63 21.2%, #BC4267 43.06%, #F97964 66.65%, rgba(250, 204, 21, 0.8) 100%)'
            }}>
              <div className="relative flex items-center justify-between max-w-7xl mx-auto h-20 px-4 md:px-0">
                <div className="absolute inset-y-0 right-4 flex items-center md:hidden">
                  {/* Mobile menu button*/}
                  <MenuIcon ref={btnRef} onClick={onOpen} className={`${location.pathname == "/" ? "text-white " : ""}` + "block h-6 w-6 "} aria-hidden="true" />

                  {/* <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6 " aria-hidden="true" />
                    ) : (
                      <MenuIcon className={`${location.pathname=="/"?"text-white ":""}`+ "block h-6 w-6 "} aria-hidden="true" />
                    )}
                  </Disclosure.Button> */}
                </div>
                <div className="flex-1 flex items-center  md:items-stretch justify-start">
                  <a href="/" className="flex">
                    {/* <div className="w-10 md:w-16"><img src={logo} /></div> */}
                    <div className="flex items-center font-black  md:text-32">
                      {/* CreatorDAO */}
                      <img
                        className="block lg:hidden w-40"
                        src={ "/images/cdlogo.png"}
                        alt="creatorDAO"
                      />
                      <img
                        className="hidden lg:block w-44 "
                        src={"/images/cdlogo.png"}
                        alt="creatorDAO"
                      />
                    </div>
                  </a>

                  <div className="hidden md:block md:ml-12">
                    <div className="flex space-x-8" style={{alignItems:'center',height:'100%'}}>
                      {links.map((item, key) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current ? 'text-nav-focus-color text-white hover:text-primary  ' : "text-white hover:text-primary" ,
                            ' px-1 height-24  font-medium font-bold  '
                          )}

                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="hidden sm:flex absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {
                    state.profile.address ?
                      <>
                        <Menu>
                          <MenuButton>
                            <div className={ "header-person-menu ml-10 bg-color-fff text-sm  text-color-222 hover:text-gray-200 py-2 px-6 h-48 text-white flex items-center"}>
                              <img src={getAvatar(state.profile.avatar,state.profile.address)} className="image-36 mr-2" /> {getUserName(state.profile.user_name,state.profile.address)}
                            </div>
                          </MenuButton>
                          <MenuList>
                            <MenuItem onClick={toEditProfile}>
                              <span className="text-size-16 font-bold font-color-000 ml-2">Profile</span>
                            </MenuItem>
                            <MenuItem onClick={toMyGallery}>
                              <span className="text-size-16 font-bold font-color-000 ml-2">My gallery</span>
                            </MenuItem>
                            <MenuItem onClick={toMyCommission}><span className="text-size-16 font-bold font-color-000 ml-2">My Commission</span> </MenuItem>

                            {
                              checkShopNums > 0 ?
                                <MenuItem onClick={toMyWork}> <span className="text-size-16 font-bold font-color-000 ml-2"> My Work</span></MenuItem>
                                :
                                ""
                            }
                            <MenuItem onClick={newDisconnect}> <span className="text-size-16 font-bold font-color-000 ml-2"> Log Out</span></MenuItem>
                          </MenuList>
                        </Menu>
                      </>
                      :
                      <button onClick={connectWallet} className="text-base font-bold rounded bg-white pl-4 pr-8 py-2 flex">
                        <img src="/images/eth.svg" className="w-5 h-5 mr-2" />
                        Login
                      </button>
                  }
                </div>
              </div>
            </div>
            <Drawer
              isOpen={isOpen}
              placement='right'
              onClose={onClose}
              finalFocusRef={btnRef}
              autoFocus={false}
            >
              <DrawerOverlay />
              <DrawerContent>
              
                <CloseCircleFilled onClick={onClose} className="mt-3 absolute top-3.5 right-6 text-xl" />
                <DrawerHeader>
                  <img
                    className="block lg:hidden mt-2 w-40"
                    src={logo_black}
                    alt="creatorDAO"
                  />
                </DrawerHeader>
                <DrawerBody>
                  <div className="mt-4">
                    {navigation.map((item) => (
                      <Disclosure.Button
                        onClick={onClose}
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          'block px-3 py-2 border-drawer-css font-bold font-size-16  font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                        {/* <span className="iconfont icon-jt_r float-right"></span> */}
                      </Disclosure.Button>
                    ))}
                  </div>

                </DrawerBody>

                <DrawerFooter>
                  {
                    state.profile.address ?
                      <Menu>
                        <MenuButton className="w-full">
                          <div className="w-full h-48 font-size-16 font-bold justify-center bg-color-black hover:text-gray-200 py-2 px-4 h-11 text-white flex items-center my-8">
                            <img src={getAvatar(state.profile.avatar,state.profile.address)} className="w-6 h-6 rounded-full mr-2" /> {getUserName(state.profile.user_name,state.profile.address)}
                          </div>
                        </MenuButton>
                        <MenuList>
                          <MenuItem onClick={toEditProfile}>
                            <span className="text-size-16 font-bold font-color-000">Profile</span>
                          </MenuItem>
                          <MenuItem onClick={toMyGallery}>
                            <span className="text-size-16 font-bold font-color-000">My gallery</span>
                          </MenuItem>
                          <MenuItem onClick={toMyCommission}><span className="text-size-16 font-bold font-color-000">My Commission</span> </MenuItem>
                          {
                            checkShopNums > 0 ?
                              <MenuItem onClick={toMyWork}><span className="text-size-16 font-bold font-color-000"> My Work</span></MenuItem>
                              :
                              ""
                          }
                          <MenuItem onClick={newDisconnect}><span className="text-size-16 font-bold font-color-000"> Log Out</span></MenuItem>
                        </MenuList>
                      </Menu>
                      :
                      <button onClick={connectWallet} className="w-full h-48 font-bold rounded-lg font-size-16 bg-color-black hover:text-gray-200 py-2 px-4 h-11 text-white my-8 ">CONNECT WALLET</button>
                  }
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
          </>
        )}
      </Disclosure>


    </>
  )
}

export default Header